import { Component, Input } from '@angular/core';
import { List } from '../interfaces/block';

@Component({
    selector: 'app-block-list',
    templateUrl: './block-list.component.html'
})
export class BlockListComponent{

    @Input() lists:List;

}