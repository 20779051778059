<section class="blockList">
    <div class="blockList__box pad bg_blue_bright">
        <div class="inner">
            <div class="sup_ttl2_white" i18n>{{lists.sup_title}}</div>
            <h2 class="ttl2_white" i18n>{{lists.title1}} <br> {{lists.title2}}</h2>
            <ul class="listCheck">
                <li *ngFor="let item of lists.list1" [innerHTML]="item" i18n></li>
            </ul>
        </div>
    </div>

    <div class="blockList__box bg_blue_dark pad">
        <div class="inner">
            <div class="sup_ttl2_white" i18n>Lo nuevo de BlueHosting</div>
            <h2 class="ttl2_white" i18n>Ahora te <br> entregamos más</h2>
            <ul class="listCheck">
                <li *ngFor="let item of lists.list2" [innerHTML]="item" i18n></li>
            </ul>
        </div>
    </div>
</section>