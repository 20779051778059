import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { CONTACTS } from '../../../../../common/contacts.constants';
import { LocaleService } from '../../services/locale.service';
import { Contact } from '../../../../../common/Contact';
import { URL } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { urlMapping } from '../route_ids';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
    isShowItemMenuFooter = false;

    contactList: Contact[] = CONTACTS;
    contact: Contact;
    URL = URL;
    public url: string;

    constructor(private localeService: LocaleService,
        @Inject(PLATFORM_ID) private platformId: any,
        private _router: Router
    ) { 
        const urlTree = this._router.parseUrl(this._router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';;
        this.url = urlMapping[urlSegment] || urlSegment;
    }

    ngOnInit() {
        this.localeService.getMessage().subscribe(c => {
            this.contact = c;
        });

        if (window.innerWidth < 992) {
            this.isShowItemMenuFooter = true;
        }
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }
    
    public track(event: Event, type:string='navigate'){
        traceButton2(event, 'navigate');
    }
}
