import { Component, OnInit, Inject } from '@angular/core';
import { planProps } from '../../content.class';
import { DOCUMENT } from "@angular/common";
import { LocaleService } from '../../services/locale.service';
import { plansInclude } from '../../content.class';
import { Router } from '@angular/router';
import { urlMapping } from '../route_ids';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-menu-plans',
    templateUrl: './menu-plans.component.html'
})
export class MenuPlansComponent implements OnInit {
    // for plans
    hosting;
    links;
    planProps;
    categoriesToShow;
    plansInclude;
    isAllShowed = true;
    activeCategory = 1;
    isActiveTablePlanes = false;
    isShowClassWIdth = false;
    isShowTableCol4 = false;

    symbol = '';

    plans_short = {
        emprendedores:'emprende',
        negocios:'pymes',
        empresas:'empresa'
    }

    public url:string;

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private localeService: LocaleService,
        private _router: Router
    ) {
        this.links = this.localeService.getContent().links;
        this.hosting = this.localeService.getContent().hosting;
        this.planProps = planProps;
        this.categoriesToShow = this.hosting;
        this.symbol = this.localeService.symbol;
        this.plansInclude = plansInclude;
        const urlTree = this._router.parseUrl(this._router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';;
        this.url = urlMapping[urlSegment] || urlSegment;
    }

    ngOnInit() {
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    toggleOnePlan(categoryID: string) {
        if (categoryID == null) {
            this.isAllShowed = true;
            this.categoriesToShow = this.hosting.filter(category => category.show === true);
            this.isShowClassWIdth = false;
            this.isShowTableCol4 = false;
        } else {
            this.activeCategory = parseInt(categoryID, 10);
            this.categoriesToShow = this.hosting.filter(category => category.id === this.activeCategory);
            this.isAllShowed = false;
            this.isShowClassWIdth = true;
        }

        if (categoryID === '1' || categoryID === '2') {
            this.isShowTableCol4 = false;
        }

        if (categoryID === '3') {
            // this.isShowClassWIdth = false;
            this.isShowTableCol4 = true;
        }
    }

    getTitle() {
        if (this.isAllShowed) {
            return 'Plan Todos';
        } else {
            return 'Plan ' + this.categoriesToShow[0].category;
        }
    }

    addClassTable() {
        if (this.isAllShowed) {
            return 'table_col-9';
        } else {
            return 'table_col-2';
        }
    }

    openTablePlanes(el) {
        const container = this._document.getElementsByClassName('menuPlans')[0] as HTMLElement;
        const btn = this._findAncestorFromEl(el.target, 'btn');
        const btnActive = btn.classList.contains('active');

        if (!!btn) {
            const btns = container.getElementsByClassName('btn') as unknown as NodeListOf<HTMLElement>;
            if (btnActive) {
                btn.classList.remove('active');
                container.classList.remove('showTable');
                this.isActiveTablePlanes = false;
            } else {
                [].forEach.call(btns, (el) => {
                    el.classList.remove('active');
                });
                btn.classList.add('active');
                container.classList.add('showTable');
                this.isActiveTablePlanes = true;
            }
        }
    }

    private _findAncestorFromEl(el, cls) {
        if (el.classList.contains(cls)) {
            return el;
        }
        return this._findAncestor(el, cls);
    }

    private _findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls)) { }
        return el;
    }

    public track(event:Event){
        traceButton2(event, 'navigate');
    }
}
