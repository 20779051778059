<section class="hostingManagement">
  <h2 class="hostingManagement__title" i18n>Gestiona tu hosting por ti mismo, de manera sencilla y rápida.</h2>

  <div class="hostingManagement__cards">
    <button
      matRipple
      [id]="item.id"
      class="hostingManagement__card"
      *ngFor="let item of cards"
      (click)="openModal(item.type); track($event)">
      <svg-icon-sprite [src]="item.icon" width="40px"></svg-icon-sprite>
      <span i18n>{{item.text}}</span>
    </button>
  </div>
</section>
