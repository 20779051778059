import { Component, OnInit } from '@angular/core';
import { LocaleService } from '../../../services/locale.service';
import { Router } from '@angular/router';
import { urlMapping } from '../../route_ids';
import { traceButton2 } from '../../../../../../common/services/button-trace';

@Component({
  selector: 'app-dialog-syte-html',
  templateUrl: './dialog-syte-html.component.html'
})
export class DialogSyteHtmlComponent implements OnInit {

  url;
  public landing:string;

  constructor(
    private localeService: LocaleService,
    private _router: Router
  ) { 
    this.url = this.localeService.getContent().links.personas.dialogs.html;
    const urlTree = this._router.parseUrl(this._router.url);
    const primarySegment = urlTree.root.children['primary'];
    const urlSegment = primarySegment?.segments[0]?.path || 'home';;
    this.landing = urlMapping[urlSegment] || urlSegment;
  }

  ngOnInit() {
  }

  goToUrl(): void {
    window.open(this.url, '_blank');
  }

  public track(event:Event){
    traceButton2(event, 'navigate');
}
}
