import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-block-person',
    templateUrl: './block-person.component.html'
})
export class BlockPersonComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }
}
