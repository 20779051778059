import { Component, OnInit, Inject, HostListener, Output, PLATFORM_ID, ElementRef } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { LocaleService } from '../services/locale.service';
import { MetaService } from '@ngx-meta/core';
import { URL } from '../../environments/environment';
import { ScrollToClass } from '../../../../common/scrollTo.class';
import { Info, List } from '../shared/interfaces/block';

@Component({
    selector: 'app-emprendedor',
    templateUrl: './emprendedor.component.html'
})
export class EmprendedorComponent extends ScrollToClass implements OnInit {
    // lazy loading images
    showSectionSlider: boolean = false;
    showFooter: boolean = false;
    showImage1: boolean = true;
    showImage2: boolean = false;
    showImage3: boolean = false;

    public URL = URL;

    isShowPlans = true;

    @Output() hosting;
    links;
    symbol = '';
    locale;

    public info:Info = {
        title:'¿Quieres más potencial? Conoce nuestro plan Pyme',
        symbol: '',
        price: 0,
        url: '/web-hosting-negocio/.'
    }

    public lists:List = {
        sup_title:'¿Pensaste que era todo?',
        title1:'Todos los',
        title2:'planes emprendedores incluyen',
        list1:[
            'Sub dominios ilimitados',
            'Instalador de Apps',
            'Anti-Spam Premium 95% de efectividad',
            'Web Application Firewall Premium',
            'Migración gratuita',
            'Creador de sitios web',
            'cPanel'
        ],
        list2:[
            "SSL Gratuito Let's Encrypt",
            'Dominio.cl gratis por 1 año',
            'Migración gratuita',
            'Uptime 99.9%'
        ]
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isShowPlans = event.target.innerWidth < 768 ? false : true;
    }

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        public dialog: MatDialog,
        private localeService: LocaleService,
        private readonly _meta: MetaService,
        private _el: ElementRef,
    ) {
        super(_el);
        this.hosting = this.localeService.getContent().hosting.filter(category => category.show === true);
        this.info.price = this.hosting[1].plans[0].periods[0].price;
        this.links = this.localeService.getContent().links;
        this.info.symbol = this.localeService.symbol;
        this.symbol = this.localeService.symbol;
        this.locale = this.localeService.locale;
    }

    ngOnInit() {
        this.scrollTop();

        this.removePlansMobyle();
        this.addVideoControlsForMobyle();

        //Set Meta Tags
        if (this.locale == "es-CO")
            this._setMetaCO();
        else if (this.locale == "es-MX")
            this._setMetaMX();
        else if (this.locale == "es-PE")
            this._setMetaPE();
        else if (this.locale == "es-AR")
            this._setMetaAR();
        else
            this._setMetaCL();
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    removePlansMobyle() {
        if (window.innerWidth < 768) {
            this.isShowPlans = false;
        }
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    addVideoControlsForMobyle() {
        setTimeout(_ => {
            const video1 = this._document.getElementsByClassName('video1')[0] as HTMLVideoElement;
            if (window.innerWidth < 670) {
                if (video1) video1.setAttribute('controls', '');
            }
        }, 1500); //Lucky number
    }

    toggleVideoCompany(el) {
        const element = this._findAncestorFromEl(el.target, 'videoCompany__video');
        const playEl = element.classList.contains('play');
        const video = element.getElementsByClassName('video')[0] as HTMLVideoElement;

        if (window.innerWidth > 670) {
            if (playEl) {
                element.classList.remove('play');
                video.pause();
            } else {
                element.classList.add('play');
                video.play();
            }
        }
    }

    navigateToSection(className) {
        this.scrollToSection(className);
    }

    private _findAncestorFromEl(el, cls) {
        if (el.classList.contains(cls)) {
            return el;
        }
        return this._findAncestor(el, cls);
    }

    private _findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls)) { }
        return el;
    }

    private _setMetaCO() {
        this._meta.setTitle('Web hosting: Web hosting para emprendedores más rápido en Chile');
        this._meta.setTag('description', 'Web Hosting, La más Alta Calidad en Planes Web Hosting al Mejor Precio, Hospedaje Web Confiable.');
        this._meta.setTag('keywords', 'hosting, hosting para emprendedores, web hosting, web hosting colombia, web hosting precios, servicio de web hosting, alojamiento web, alojamiento web colombia, hospedaje web');
        this._meta.setTag('author', 'Bluehosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/emprendedores.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Colombia');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
    }

    private _setMetaCL() {
        this._meta.setTitle('Web hosting: Web hosting para emprendedores más rápido en Chile');
        this._meta.setTag('description', 'Web Hosting, La más Alta Calidad en Planes Web Hosting al Mejor Precio, Hospedaje Web Confiable.');
        this._meta.setTag('keywords', 'hosting, hosting para emprendedores, web hosting, web hosting chile, web hosting precios, servicio de web hosting, alojamiento web, alojamiento web chile, hospedaje web');
        this._meta.setTag('author', 'Bluehosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/emprendedores.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Chile');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaPE() {
        this._meta.setTitle('Web hosting: Web hosting para emprendedores más rápido en Chile');
        this._meta.setTag('description', 'Web Hosting, La más Alta Calidad en Planes Web Hosting al Mejor Precio, Hospedaje Web Confiable');
        this._meta.setTag('keywords', 'hosting, hosting para emprendedores, web hosting, web hosting perú, web hosting precios, servicio de web hosting, alojamiento web, alojamiento web perú, hospedaje web');
        this._meta.setTag('author', 'Bluehosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/emprendedores.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Perú');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaMX() {
        this._meta.setTitle('Web hosting: Web hosting para emprendedores más rápido en Chile');
        this._meta.setTag('description', 'Web Hosting, La más Alta Calidad en Planes Web Hosting al Mejor Precio, Hospedaje Web Confiable');
        this._meta.setTag('keywords', 'hosting, hosting para emprendedores, web hosting, web hosting méxico, web hosting precios, servicio de web hosting, alojamiento web, alojamiento web méxico, hospedaje web');
        this._meta.setTag('author', 'Bluehosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/emprendedores.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en México');
        this._meta.setTag('og:locale', 'es_ME');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaAR() {
        this._meta.setTitle('Web hosting: Web hosting para emprendedores más rápido en Chile');
        this._meta.setTag('description', 'Web Hosting, La más Alta Calidad en Planes Web Hosting al Mejor Precio, Hospedaje Web Confiable');
        this._meta.setTag('keywords', 'hosting, hosting para emprendedores, web hosting, web hosting argentina, web hosting precios, servicio de web hosting, alojamiento web, alojamiento web argentina, hospedaje web');
        this._meta.setTag('author', 'Bluehosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/OG_emprendedores_ARG.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Argentina');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
}
