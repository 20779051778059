import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-block-domain',
  templateUrl: './block-domain.component.html'
})
export class BlockDomainComponent implements OnInit {

    @Input() showImage1? = true;
    @Input() locale ;

  constructor() { }

  ngOnInit() {}

}
