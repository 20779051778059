declare var dataLayer;

export function traceButton(event, type: string): void {
    var target = event.target.id || event.target.parentNode.id;
    dataLayer.push({
        'event': 'traceButton', 'element': {
            'idButton': target,
            'typeButton': type
        }
    },);
}

export function traceButton2(event: Event, type: string = 'navigate'): void {
    const buttonId = (event.currentTarget as HTMLElement).id;
    dataLayer.push({
        'event': 'traceButton', 'element': {
            'idButton': buttonId,
            'typeButton': type
        }
    },);
}