<!-- START blockPerson -->
<section class="blockPerson customer">
  <div class="container">
    <div class="blockPerson__inner">
      <div class="blockPerson__contacts">
        <div class="name">Gonzalo Medina</div>
        <div class="position">Gerente de Servicio al Cliente</div>
      </div>
    </div>
    <div class="blockPerson__inner">
      <div class="sup_title color-white upper">haulmer customer</div>
      <h2 class="title2 color-white">Estamos de tu lado<br><b>Queremos ayudar</b></h2>
      <p>Contamos con un equipo de soporte multicanal altamente capacitado, dedicado a ayudar a nuestros clientes todos
        los días del año a obtener el máximo potencial de HAULMER Punto de Venta.</p>
    </div>
  </div>
</section>
<!-- END blockPerson -->
