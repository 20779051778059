import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IInvoice {

}

@Injectable({
    providedIn: 'root',
})
export class ApiService {

    constructor(
        private readonly http: HttpClient,
    ) { }

    public findInvoice(formData) {
        const url = URL.FIND_INVOICE;
        return this.http.post(url, formData);
    }

    public recoveryAccess(body: URLSearchParams): Observable<any> {
        const url = URL.SELFCARE_API;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        };
        return this.http.post(url, body.toString(), { headers: headers })
            .pipe(
                map((x: any) => {
                    if (x?.result === 'success' || x?.result?.result === 'success') {
                        if (x?.message === 'no tiene servicios getdns') {
                            throw new Error('Sin servicios asociados.');
                        }
                        return x['data'] ?? null;
                    }
                    else {
                        if (x?.result?.message === 'A related ID is required') {
                            throw new Error('Intente nuevamente con un correo válido.');
                        }
                        if (x?.response === 'too many retries login') {
                            throw new Error('Has realizado demasiadas peticiones. Intentalo más tarde.')
                        }
                        throw new Error(x?.response || x?.message);
                    }
                }),
            );
    }

  public sendContactFormBH(body): Observable<any> {
    const url = URL.SEND_CONTACT_BH;
    const headers = new HttpHeaders({
        "Content-Type": "text/plain;charset=utf-8"
      });
    return this.http.post(url, JSON.stringify(body), { headers });
  }
}
