import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { INoResult } from './no-search-result/no-search-result.component';
import { LocaleService } from '../services/locale.service';
import { Router } from '@angular/router';
import { UrlService } from '../services/url.service';
import { MetaService } from '@ngx-meta/core';
@Component({
    selector: 'app-direct-payment',
    templateUrl: './direct-payment.component.html'
})
export class DirectPaymentComponent implements OnInit {
    public notRegister: INoResult = {
        icon: 'icon-sprite-close-circle',
        title: 'No se encontraron resultados',
        text: '<b>Lo sentimos!</b> no se encontraron resultados, por favor vuelve a intentarlo más tarde.',
        state: 'error',
        type: 'notRegister'
    }

    public noResult: INoResult = {
        icon: 'icon-sprite-check-circle',
        title: 'Cliente no registra deuda',
        text: 'Si necesitas más información o requieres resolver dudas técnicas visita:',
        type: 'noResult'
    }

    public dataNoResult: INoResult;
    public dataResult;
    public isLoading: boolean;
    public isNoResult: boolean;
    public isComplete: boolean;
    locale;
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private readonly _meta: MetaService,
        private localeService: LocaleService,
        private router: Router,
        private urlService: UrlService
    ) { 
        this.locale = this.localeService.locale;
    }
    ngOnInit(): void {
        this._scrollTop();
        switch (this.locale) {
            case "es-CL":
                this._setMetaCL();
                break;
            case "es-CO":
                this._setMetaCO();
                break;
            case "es-PE":
                this._setMetaPE();
                break;
            case "es-MX":
                this._setMetaMX();
                break;
            case "es-AR":
                this._setMetaAR();
                break;
            default:
                this._setMetaCL();
                break;
        }
    }

    public getLoadingStatus(ev): void {
        this.isLoading = ev;
    }

    public getNoResultVisibility(ev): void {
        this.isNoResult = ev.show;
        if (ev.status === 'No found') {
            this.dataNoResult = this.notRegister;
        } else if (ev.status === 'error') {
            this.notRegister.text = '<b>Lo sentimos!</b> no se encontraron resultados, por favor vuelve a intentarlo más tarde.';
            this.dataNoResult = this.notRegister;
        } else if (ev.status === 'badInput') {
            this.notRegister.text = 'Los datos ingresados no corresponden a un Dominio, un Correo electrónico o un N° de Orden. Por favor verifica la información ingresada.';
            this.dataNoResult = this.notRegister;
        }
    }

    public getCompleteStatus(ev): void {
        this.isComplete = ev.show;
        this.dataResult = ev;
    }

    private _scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }
    private _setMetaCL() {
        this._meta.setTitle('Pago de cuentas Bluehosting: Pago directo Hosting');
        this._meta.setTag('description', 'Paga tu deuda al instante con Pago Directo Bluehosting. Pago de cuentas de Hosting Chile.');
        this._meta.setTag('keywords', 'Hosting, Bluehosting, hosting Chile, pago de cuentas, pago directo Bluehosting, VPS, certificado SSL, dominios');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }
    private _setMetaCO() {
        this._meta.setTitle('Pago de cuentas Bluehosting: Pago directo Hosting');
        this._meta.setTag('description', 'Paga tu deuda al instante con Pago Directo Bluehosting. Pago de cuentas de Hosting Colombia.');
        this._meta.setTag('keywords', 'Hosting, Bluehosting, hosting Colombia, pago de cuentas, pago directo Bluehosting, VPS, certificado SSL, dominios');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }
    private _setMetaPE() {
        this._meta.setTitle('Pago de cuentas Bluehosting: Pago directo Hosting');
        this._meta.setTag('description', 'Paga tu deuda al instante con Pago Directo Bluehosting. Pago de cuentas de Hosting Perú.');
        this._meta.setTag('keywords', 'Hosting, Bluehosting, hosting Perú, pago de cuentas, pago directo Bluehosting, VPS, certificado SSL, dominios');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }
    private _setMetaMX() {
        this._meta.setTitle('Pago de cuentas Bluehosting: Pago directo Hosting');
        this._meta.setTag('description', 'Paga tu deuda al instante con Pago Directo Bluehosting. Pago de cuentas de Hosting México.');
        this._meta.setTag('keywords', 'Hosting, Bluehosting, hosting México, pago de cuentas, pago directo Bluehosting, VPS, certificado SSL, dominios');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:locale', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }
    private _setMetaAR() {
        this._meta.setTitle('Pago de cuentas Bluehosting: Pago directo Hosting');
        this._meta.setTag('description', 'Paga tu deuda al instante con Pago Directo Bluehosting. Pago de cuentas de Hosting Argentina.');
        this._meta.setTag('keywords', 'Hosting, Bluehosting, hosting Argentina, pago de cuentas, pago directo Bluehosting, VPS, certificado SSL, dominios');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }
}
