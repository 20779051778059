<ul class="customDropdownMenu">
  <li>
    <button id="bh-{{url}}-support" class="customDropdownMenu__item" mat-button (click)="goToUrl('contactar-con-soporte'); track($event)" tabindex="-1">
      <i class="icon icon_support"></i>Contactar Soporte
    </button>
  </li>
  <li>
    <button id="bh-{{url}}-support-doc" class="customDropdownMenu__item" mat-button tabindex="-1" (click)="goToUrl('https://help.haulmer.com/hc/web-hosting'); track($event)">
      <i class="icon icon_doc"></i> Documentación
    </button>
  </li>
  <li>
    <button id="bh-{{url}}-support-status" class="customDropdownMenu__item" mat-button tabindex="-1" (click)="goToUrl('https://status.bluehosting.host/'); track($event)">
      <i class="icon icon_status_service"></i>Status de Servicios
    </button>
  </li>
  <li>
    <button id="bh-{{url}}-support-reclamos" class="customDropdownMenu__item" mat-button tabindex="-1" (click)="goToUrl('https://survey.typeform.com/to/zQ7I32J9#empresa=Bluehosting&issuetype=11208'); track($event)">
      <i class="icon icon_suggestions"></i>Sugerencias o reclamos
    </button>
  </li>
</ul>
