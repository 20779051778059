import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { SecurityService } from '../../../services/security.service';
import { interval, throwError, Subscription } from 'rxjs';
import { retryWhen, delay, take, concat, startWith, switchMap, flatMap, tap } from 'rxjs/operators';


@Component({
    selector: 'app-chart-security',
    templateUrl: './chart-security.component.html'
})
export class ChartSecurityComponent implements OnInit, OnDestroy {
    outerValue = 2135794;
    innerValue = 230732;
    maxValue = 2366526;

    pseudoMaxValue: number = 2366526;
    pseudoInnerValue: number = 230732;
    pseudoOuterValue: number = 2135794;

    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;
    size = 360;

    center = this.size / 2;
    startAngle = this._degToRad(-90);
    rotateAngle;
    speed = 1;

    outerRadius = this.center - 10;
    innerRadius = this.center - 30;

    outerCurrentAngle = 0;
    outerTargetAngle;

    innerCurrentAngle = 0;
    innerTargetAngle;

    valueTag;

    interval$: Subscription;

    outerValue_percent = 90.25;
    innerValue_percent = 9.75;

    constructor(
        private securityService: SecurityService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
    ) { }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.interval$ = interval(1000 * 60).pipe(
                startWith(0),
                tap(() => {
                    this.securityService.fetchData().pipe(
                        retryWhen(err => err.pipe(
                            delay(3000),
                            take(5),
                            concat(throwError('An error occur when trying to fetch the data'))
                        ))
                    )
                    .subscribe(item => {
                        this.innerValue = item.counters.blockages;
                        this.outerValue = item.counters.disinfections;
                        this.maxValue = item.counters.total;

                        this.innerValue_percent = (this.innerValue / this.maxValue) * 100;
                        this.outerValue_percent = (this.outerValue / this.maxValue) * 100;

                        this.setupPseudoValues();
                    }, err => {
                        console.error(err);
                    });
                })
            ).subscribe();

            this.setupCanvasAnimation();
        }
    }
    setupCanvasAnimation() {
        this.canvas = this.document.getElementsByClassName('canvasSecurity')[0] as HTMLCanvasElement;
        this.ctx = this.canvas.getContext('2d');
        this.canvas.height = this.size;
        this.canvas.width = this.size;

        this.valueTag = <HTMLDivElement>this.document.getElementsByClassName('chartSecurity__info-value')[0];

        requestAnimationFrame(this.loop);
    }

    loop = () => {
        this.ctx.clearRect(0, 0, this.size, this.size);

        // // draw outer background circle
        this.ctx.beginPath();
        this.ctx.lineWidth = 4;
        this.ctx.strokeStyle = 'rgba(3, 27, 78, 0.24)';
        this.ctx.arc(this.center, this.center, this.outerRadius, 0, Math.PI * 2, false);
        this.ctx.stroke();

        this.drawOuterChart();
        this.drawInnerChart();
        this.valueTxt();

        requestAnimationFrame(() => {
            this.loop();
        });
    }

    // draw outer circle "archivos limpiados"
    drawOuterChart() {
        this.outerTargetAngle = this._getOuterTargetAngle();
        let outerRatio = 0;
        if (this.outerCurrentAngle < this.outerTargetAngle) {
            outerRatio = 1;
        } else if (this.outerCurrentAngle > this.outerTargetAngle) {
            outerRatio = -1;
        }

        this.rotateAngle = this.speed / 100 + this.startAngle;
        this.speed++;

        const currentOuterAngle = this.rotateAngle + this._degToRad(this.outerCurrentAngle);
        this.ctx.beginPath();
        this.ctx.strokeStyle = '#031b4e';
        this.ctx.lineWidth = 10;
        this.ctx.arc(this.center, this.center, this.outerRadius, this.rotateAngle, currentOuterAngle);
        this.ctx.stroke();

        // draw outer small circle
        this.ctx.beginPath();
        this.ctx.fillStyle = '#031b4e';
        const arrowX = this.center + this.outerRadius * Math.cos(currentOuterAngle);
        const arrowY = this.center + this.outerRadius * Math.sin(currentOuterAngle);
        this.ctx.arc(arrowX, arrowY, 8, this.startAngle, Math.PI * 2, false);
        this.ctx.fill();

        this.outerCurrentAngle += outerRatio;
    }

    valueTxt() {
        // draw inner value
        const textValue = this.pseudoMaxValue;

        this.valueTag.innerHTML = this._numberFormatter(textValue);
    }

    // draw inner circle "ataques bloqueados"
    drawInnerChart() {
        this.innerTargetAngle = this._getInnerTargetAngle();
        let innerRatio = 0;
        if (this.innerCurrentAngle < this.innerTargetAngle) {
            innerRatio = 1;
        } else if (this.innerCurrentAngle > this.innerTargetAngle) {
            innerRatio = -1;
        }

        this.rotateAngle = - this.speed / 100 + this.startAngle;
        this.speed++;

        const currentInnerAngle = this.rotateAngle + Math.PI / 180 * this.innerCurrentAngle;
        this.ctx.beginPath();
        this.ctx.strokeStyle = '#1984f6';
        this.ctx.lineWidth = 10;
        this.ctx.arc(this.center, this.center, this.innerRadius, this.rotateAngle, currentInnerAngle);
        this.ctx.stroke();

        // draw inner small circle
        this.ctx.beginPath();
        this.ctx.fillStyle = '#1984f6';
        const arrowX = this.center + this.innerRadius * Math.cos(currentInnerAngle);
        const arrowY = this.center + this.innerRadius * Math.sin(currentInnerAngle);
        this.ctx.arc(arrowX, arrowY, 8, this.startAngle, Math.PI * 2, false);
        this.ctx.fill();

        this.innerCurrentAngle += innerRatio;
    }

    private _getOuterTargetAngle(): number {
        return Math.floor(360 * this.outerValue / this.maxValue);
    }

    private _getInnerTargetAngle(): number {
        return Math.floor(360 * this.innerValue / this.maxValue);
    }

    private _degToRad(deg): number {
        return Math.PI / 180 * deg;
    }

    private _numberFormatter(value: number): string {
        return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
    }

    ngOnDestroy(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.interval$.unsubscribe();
        }
    }

    async delay(ms: number) {
        await new Promise(resolve => setTimeout(()=>resolve(), ms));
    }

    setupPseudoValues(){
        this.pseudoInnerValue = this.innerValue - 1000;
        this.pseudoOuterValue = this.outerValue - 100;
        this.pseudoMaxValue = this.maxValue - 1100;

        interval(2000).subscribe(time => {
            this.delay(Math.random()*4000).then(any=>{
                if (this.pseudoInnerValue < this.innerValue){
                    this.pseudoInnerValue += Math.floor(Math.random()*11);
                };
                if (this.pseudoOuterValue < this.outerValue){
                    this.pseudoOuterValue += Math.floor(Math.random()*2);
                };
                this.pseudoMaxValue = this.pseudoInnerValue + this.pseudoOuterValue;
            });
        })
    }
}
