import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { LocaleService } from './locale.service';
import { Observable } from 'rxjs';
import { URL } from '../../environments/environment';

@Injectable()
export class DomainService {
    apiURLPrices: string;
    apiURLPDF = 'https://api.haulmer.com/v1/domain/getdomainpdf.php';
    domain = '';
    currencyCode = 'CLP';
    domains: {}[];

    constructor(
        private http: HttpClient,
        private localeService: LocaleService
    ) {
        this.domains = this.localeService.getContent().domains;
        this.currencyCode = this.localeService.currentContact.currency_code;
        this.apiURLPrices = URL.API_DOMAIN;
    }

    getDomains(domain: string, tlds: string[]): Observable<any> {
        const body = new HttpParams()
            .set('domain', domain)
            .set('currency', this.currencyCode)
            .set('tlds', tlds.toString());

        return this.http.post(this.apiURLPrices, body);
    }

    getPDF(data: {}): Observable<any> {
        return this.http.post(this.apiURLPDF, data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            responseType: 'blob'
        });
    }
}
