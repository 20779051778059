<!-- La compra total fue por <b id ="priceGTM">${{totalPrice}}</b> -->
<div class="succsess_wrapper">
  <div class="messageBlock">
    <div class="messageBlock__preheadin">Genial!</div>
    <h1 class="messageBlock__heading" [innerHtml]="titleCard"></h1>
    <ng-container *ngIf='flag'>
      <ul class="infoList">

        <li class="infoList__item">
          <span class="text">N° de pedido</span>
          <span class="number" id="transactionGTM">{{saleTicket}}</span>
        </li>
        <li class="infoList__item">
          <span class="text">Monto Pagado</span>
          <span class="number huge" id="priceGTM">$ {{totalPrice}} </span>
        </li>
        <li class="infoList__item" *ngIf="showInfo">
          <span class="text text-info"><b>Info:</b> existe un tiempo aproximado de 5 minutos para que se vea reflejado tu pago automático!</span>
        </li>
      </ul>
    </ng-container>


    <div class="text-center">
      <button *ngIf="buttonCalendly"
        onclick="Calendly.showPopupWidget('https://calendly.com/agenda-haulmer/web-hosting?back=1&month={date.Year}-{date.Month}&back=1')"
        mat-button class="btn btnRed3">Agendar capacitación</button>
      <button mat-button class="btn btnRedTransp" [routerLink]="['/']">Finalizar</button>
    </div>
  </div>

  <picture class="dude" *ngIf="!phone">
    <source srcset="../../assets/images/success/dude_loudspeaker@1.webp,
                    ../../assets/images/success/dude_loudspeaker@2x.webp 2x" type="image/webp">

    <img src="../../assets/images/success/dude_loudspeaker@1.png"
      srcset="../../assets/images/success/dude_loudspeaker@2x.png 2x" alt="loudspeaker">
  </picture>

  <div class="circles">
    <div class="circles__item"></div>
    <div class="circles__item"></div>
    <div class="circles__item"></div>
    <div class="circles__item"></div>
  </div>

</div>