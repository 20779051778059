declare var dataLayer;

export function trackPurchase(transactionId: string, value: number, currency: string, items: { item_id: string; price: number; quantity: number }[]) {
  dataLayer.push({'ecommerce':null});
  //TODO: agregar decimal a los valores
  dataLayer.push({
    'event': 'purchase',
      'affiliation': 'BLUEHOSTING',
      'ecommerce': {
        'currency': currency,
        'value': value,
        'transaction_id': transactionId,
        'items': items
      }
  });
}