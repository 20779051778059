<section class="block_logo">
  <div class="container">
    <div class="container_link">
      <button id="bh-{{url}}-conoce-marcas" class="btn btn_blue" mat-button (click)="goToUrl( URL.TUU_WEBSITE ); track($event)" i18n>Conoce nuestras marcas</button>
        <picture>
          <source srcset="../../../assets/images/logos/barra-desktop.svg" media="(min-width: 992px)">
          <source srcset="../../../assets/images/logos/barra-tablet.svg" media="(min-width: 670px)">
          <img  src="../../../assets/images/logos/barra-mobile.svg" 
                width="100%"
                height="100%"
                alt="Marcas by Haulmer">
        </picture>
    </div>
  </div>
</section>