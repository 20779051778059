import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MetaService } from '@ngx-meta/core';

import { URL } from '../../environments/environment';
import { LocaleService } from '../services/locale.service';

@Component({
    selector: 'app-autoatencion',
    templateUrl: './autoatencion.component.html'
})
export class AutoatencionComponent implements OnInit {

    URL = URL;
    locale;

    constructor(
        @Inject(PLATFORM_ID) private _platformId: any,
        private readonly _meta: MetaService,
        private _localeService: LocaleService
    ) {
        this.locale = this._localeService.locale;
    }

    ngOnInit(): void {
        this._scrollTop();

        switch (this.locale) {
            case 'es-CL':
                this._setMetaCL();
                break;
            case 'es-CO':
                this._setMetaCO();
                break;
            case 'es-PE':
                this._setMetaPE();
                break;
            case 'es-MX':
                this._setMetaMX();
                break;
            case 'es-AR':
                this._setMetaAR();
                break;
            default:
                this._setMetaCL();
                break;
        }
    }

    private _scrollTop(): void {
        if (isPlatformBrowser(this._platformId)) {
            window.scrollTo(0, 0);
        }
    }

    private _setMetaCL() {
        this._meta.setTitle('Autoatención Bluehosting - Gestiona tu Hosting');
        this._meta.setTag('description', 'Gestiona tu Hosting con Autoatención Bluehosting, hosting chileno y dominio');
        this._meta.setTag('keywords', 'Autoatención Hosting, Hosting, Bluehosting, hosting Chile, VPS, certificado SSL, dominios');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/autoatencion.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Autoatención Bluehosting - Gestiona tu Hosting');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }
    private _setMetaCO() {
        this._meta.setTitle('Autoatención Bluehosting - Gestiona tu Hosting');
        this._meta.setTag('description', 'Gestiona tu Hosting con Autoatención Bluehosting, hosting chileno y dominio.');
        this._meta.setTag('keywords', 'Autoatención Hosting, Hosting, Bluehosting, hosting Chile, VPS, certificado SSL, dominios');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/autoatencion.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Autoatención Bluehosting - Gestiona tu Hosting');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }
    private _setMetaPE() {
        this._meta.setTitle('Autoatención Bluehosting - Gestiona tu Hosting');
        this._meta.setTag('description', 'Gestiona tu Hosting con Autoatención Bluehosting, hosting chileno y dominio');
        this._meta.setTag('keywords', 'Autoatención Hosting, Hosting, Bluehosting, hosting Chile, VPS, certificado SSL, dominios');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/autoatencion.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Autoatención Bluehosting - Gestiona tu Hosting');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }
    private _setMetaMX() {
        this._meta.setTitle('Autoatención Bluehosting - Gestiona tu Hosting');
        this._meta.setTag('description', 'Gestiona tu Hosting con Autoatención Bluehosting, hosting chileno y dominio');
        this._meta.setTag('keywords', 'Autoatención Hosting, Hosting, Bluehosting, hosting Chile, VPS, certificado SSL, dominios');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/autoatencion.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Autoatención Bluehosting - Gestiona tu Hosting');
        this._meta.setTag('og:locale', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }
    private _setMetaAR() {
        this._meta.setTitle('Autoatención Bluehosting - Gestiona tu Hosting');
        this._meta.setTag('description', 'Gestiona tu Hosting con Autoatención Bluehosting, hosting chileno y dominio');
        this._meta.setTag('keywords', 'Autoatención Hosting, Hosting, Bluehosting, hosting Chile, VPS, certificado SSL, dominios');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/autoatencion.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Autoatención Bluehosting - Gestiona tu Hosting');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_AR');
    }
}
