export const productivityContent_cl = {
    chart4: {
        maxValue: 200,
        endValue: 4,
        serviceName: "cPanelHost",
        loadTime: 4.73,
        visits: 200
    },
    chart3: {
        maxValue: 200,
        endValue: 75,
        serviceName: "INC",
        loadTime: 3.6,
        visits: 200
    },
    chart2: {
        maxValue: 200,
        endValue: 112.5,
        serviceName: "Hostingnet.cl",
        loadTime: 3,
        visits: 200
    },
    chart1: {
        endValue: 100,
        serviceName: "Bluehosting",
        loadTime: 1.6,
        visits: -1
    }
}

export const productivityContent_co = {
    chart4: {
        endValue: -1,
        maxValue: 200,
        serviceName: "Conexcol",
        loadTime: 2.1,
        visits: -1
    },
    chart3: {
        endValue: 14,
        serviceName: "Colombia Hosting",
        loadTime: 1.3,
        visits: -1
    },
    chart2: {
        endValue: 57,
        serviceName: "WebEmpresa",
        loadTime: 1,
        visits: -1
    },
    chart1: {
        endValue: 100,
        serviceName: "Bluehosting",
        loadTime: 0.7,
        visits: -1
    }
}

export const productivityContent_pe = {
    chart5: {
        maxValue: 200,
        endValue: 2,
        serviceName: "Hostingperu",
        loadTime: 4.77,
        visits: 200
    },
    chart4: {
        maxValue: 200,
        endValue: 70,
        serviceName: "HostingLabs",
        loadTime: 3.68,
        visits: 200
    },
    chart3: {
        maxValue: 200,
        endValue: 114,
        serviceName: "Hostname",
        loadTime: 2.98,
        visits: 200
    },
    chart2: {
        maxValue: 200,
        endValue: 142,
        serviceName: "Banahost",
        loadTime: 2.52,
        visits: 200
    },
    chart1: {
        endValue: 100,
        serviceName: "Bluehosting",
        loadTime: 1.6,
        visits: -1
    }
}

export const productivityContent_ar = {
    chart5: {
        maxValue: 200,
        endValue: 19,
        serviceName: "Towebs",
        loadTime: 4.77,
        visits: 200
    },
    chart4: {
        maxValue: 200,
        endValue: 57,
        serviceName: "WNPOWER",
        loadTime: 3.68,
        visits: 200
    },
    chart3: {
        maxValue: 200,
        endValue: 132,
        serviceName: "Neolo",
        loadTime: 2.98,
        visits: 200
    },
    chart2: {
        maxValue: 200,
        endValue: 163,
        serviceName: "Wiroos",
        loadTime: 2.52,
        visits: 200
    },
    chart1: {
        endValue: 100,
        serviceName: "Bluehosting",
        loadTime: 1.6,
        visits: -1
    }
}
export const productivityContent_mx = {
    chart4: {
        maxValue: 200,
        endValue: 19,
        serviceName: "Hosting Mexico",
        loadTime: 4.5,
        visits: 200
    },
    chart3: {
        maxValue: 200,
        endValue: 94,
        serviceName: "Expresshosting",
        loadTime: 3.3,
        visits: 200
    },
    chart2: {
        maxValue: 200,
        endValue: 150,
        serviceName: "Hostinger",
        loadTime: 2.4,
        visits: 200
    },
    chart1: {
        endValue: 100,
        serviceName: "Bluehosting",
        loadTime: 1.6,
        visits: -1
    }
}

export const planProps = [
    'Bluehosting v/s competencia',
    'Espacio de almacenamiento SSD',
    'Memoria RAM dedicada',
    'CPU Intel© Xeon© Dedicada',
    'Recursos dedicados',
    'Sitios web',
    'Inodos',
    'Cuentas de correo',
    'Base de datos',
    'Transferencia de datos',
    'SSL Gratuito Let’s Encrypt'
];

export const plansInclude = [
    {
        ttl: 'SEGURIDAD',
        info: 'Respaldo Semanal  -  Antivirus  -  Firewall'
    }, {
        ttl: 'SMTP, IMAP Y POP3',
        info: 'Webmail  -  AntiSpam'
    }, {
        ttl: 'SOPORTE',
        info: 'Soporte Básico (Ticket E-mail)'
    }, {
        ttl: 'BASE DE DATOS',
        info: 'Base de datos  -  MySQL, PostgreSQL'
    }, {
        ttl: 'SERVICIOS E-MAIL',
        info: 'Cuentas E-mail'
    }, {
        ttl: 'EXTRAS',
        info: 'Softaculous  -  Site Builder'
    }, {
        ttl: 'PROGRAMACIÓN',
        info: 'PHP 5 o posterior - CRON JOB - Alta en buscadores'
    }
];
