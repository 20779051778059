import { Unsubscriber } from './../../../../common/unsuscriber';
import { Domain } from './../services/domain';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { FormBuilder, Validators } from '@angular/forms';
import { DomainService } from '../services/domain.service';
import { LocaleService } from '../services/locale.service';
import { Observable } from 'rxjs';
import { CurrencyLocalePipe } from '../currency.pipe';
import { saveAs } from 'file-saver';
import { URL } from '../../environments/environment';
import { MetaService } from '@ngx-meta/core';
import { retryWhen, delay, take, concat, takeUntil } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { SnackBarService } from '../services/snack.service';

@Component({
    selector: 'app-dominio',
    templateUrl: './dominio.component.html'
})
export class DominioComponent extends Unsubscriber implements OnInit {
    // lazy loading images
    showImage1 = true;
    showImage2 = false;
    //
    URL = URL;
    isShowResultDomainName = true;
    isLoading = false;
    isShowResultDomain = false;
    isResultSearchContent = true;
    showSearchResult = false; // whois
    symbol: string = '$';
    captcha: any;
    isLoadingBtnPDF = false;
    form;
    isComplete = false;
    forceStopLoading = false;
    BHCL: boolean;

    @ViewChild('domainCardSection', { static: true }) public domainCardsElem: ElementRef;

    response: Observable<any>;
    activeCaptcha = true;
    domains = [
        {
            domain_name: '.com',
            icon_url: '/assets/images/icons/domains/icon_com.svg',
        },
        {
            domain_name: '.cl',
            icon_url: '/assets/images/icons/domains/icon_cl.svg',
        },
        {
            domain_name: '.co',
            icon_url: '/assets/images/icons/domains/icon_co.svg',
        },
        {
            domain_name: '.pe',
            icon_url: '/assets/images/icons/domains/icon_pe.svg',
        },
        {
            domain_name: '.com.mx',
            icon_url: '/assets/images/icons/domains/icon_mx.svg',
        },
        {
            domain_name: '.com.ar',
            icon_url: '/assets/images/icons/domains/icon_ar.svg',
        },
        {
            domain_name: '.us',
            icon_url: '/assets/images/icons/domains/icon_us.svg',
        }
    ];
    contact;
    locale;
    domainCards;
    tldNumber: number = 0;

    searchedDomain = '';
    searchedName = '';
    relatedDomains: Domain[] = [];
    chileanDomain: Domain;
    expression: RegExp;

    constructor(
        private domainService: DomainService,
        private localeService: LocaleService,
        private readonly _meta: MetaService,
        private currencyLocalePipe: CurrencyLocalePipe,
        private snackBarService: SnackBarService,
        private fb: FormBuilder
    ) {
        super();
        // tslint:disable-next-line: max-line-length
        this.expression = new RegExp('([0-9a-zÀ-ÿnÑ]{1})(?![0-9a-zÀ-ÿnÑ]{0,1}[-]{2})([0-9a-zÀ-ÿnÑ]\\S|[-]{1})*([0-9a-zÀ-ÿñÑ])+(\\.{1}[a-zA-Z]{2,6}){1,2}');
        this.domainCards = this.localeService.getContent().domains;
        this.form = this.fb.group({
            search: ['', [
                Validators.required,
                Validators.pattern(this.expression)
            ]],
        });

        this.symbol = this.localeService.symbol;
        this.contact = this.localeService.currentContact;
        this.locale = this.localeService.locale;
    }

    ngOnInit() {
        this.chileanDomain = { tld: '', price: '', is_available: false, loaded: false };
        // Set Meta Tags
        if (this.locale === 'es-CO') {
            this.tldNumber = 5;
            this._setMetaCO();
        } else if (this.locale == "es-MX") {
            this.tldNumber = 8;
            this._setMetaMX();
        } else if (this.locale == "es-PE") {
            this.tldNumber = 1;
            this._setMetaPE();
        } else if (this.locale == "es-AR") {
            this.tldNumber = 7;
            this._setMetaAR();
        } else {
            this.tldNumber = 0;
            this._setMetaCL();
        }
    }

    goToUrl(domain: string): void {
        window.open('https://www.' + domain, '_blank');
    }

    goToPlan(domain: string): void {
        const aux = domain.split(/\.(.+)/);
        const sld = aux[0];
        const tld = aux[1];
        let currency = 1;
        currency = this.localeService.currentContact.currency_number;

        window.open('https://panel.bluehosting.host/cart.php?a=add&domain=register&sld='
            + sld + '&tld=.' + tld + '&currency=' + currency, '_blank');
    }
    onSearch(input: string): string {
        const output = this.expression.exec(input);
        return output[0];
    }

    onSubmit() {
        if (this.form.invalid) {
            return;
        }
        this.response = null;
        this.forceStopLoading = false;
        this.fetchDomains();
        this.isLoading = true;
        this.isShowResultDomainName = false;
        this.searchedDomain = this.onSearch(this.form.value.search);

        const aux = this.searchedDomain.split(/\.(.+)/);
        this.searchedName = aux[0];
        const excludeTLD = aux[1];
        this.BHCL = this.isCL(excludeTLD);

        const excludeIndex = this.relatedDomains.findIndex(d => d.tld === excludeTLD);
        if (excludeIndex >= 0) {
            this.relatedDomains.splice(excludeIndex, 1);

            let tlds_temp = [];
            this.relatedDomains.forEach((el, index) => {
                if ((index + 1) % 5 < 5) {
                    tlds_temp.push(el.tld);
                }

                if (((index + 1) % 5 === 0 ||
                    (this.relatedDomains.length % 5 > 0 && this.relatedDomains.length === (index + 1)) &&
                    index > 0)) {
                    this.domainService.getDomains(this.searchedDomain, tlds_temp)
                        .pipe(
                            takeUntil(this.ngUnsubscribe$),
                            retryWhen(err => err.pipe(
                                delay(3000),
                                take(3),
                                concat(throwError('An error occur when trying to fetch domains'))
                            )
                            )
                        )
                        .subscribe(resp => {
                            this.response = resp['response'];
                            this.isLoading = false;
                            if (this.response != null) {
                                this.response['related_domains'].forEach(rel_dom => {
                                    this.relatedDomains.forEach(d => {
                                        this.setChileanDomain(d);
                                        if (rel_dom['tld'] === '.' + d.tld) {
                                            d.is_available = rel_dom['is_available'];
                                            d.price = rel_dom['prices'] != null ? rel_dom['prices'][0]['price'] : '';
                                            d.loaded = true;
                                        }
                                    });
                                });
                            }
                        }, error => {
                            this.snackBarService.showMessage('¡Ups! Ocurrió un error al obtener datos del dominio.', true);
                        });
                    tlds_temp = [];
                }
            });
            this.isComplete = true;

        } else {
            // We don't sell this domain yet
            this.forceStopLoading = true;
            this.isLoading = false;
            this.response = null;
            this.relatedDomains = [];
            this.isShowResultDomainName = true;
            this.isComplete = false;
            this.snackBarService.showMessage('¡Ups! Aún no vendemos ese dominio.', false, 'ACEPTAR', 5000);
        }
    }
    setChileanDomain(dominio: Domain) {
        if (this.isCL(dominio.tld)) {
            this.chileanDomain = dominio;
        }
    }

    isCL(TDL: string): boolean {
        return TDL === 'cl';
    }

    PDF() {
        this.isLoadingBtnPDF = true;

        const data = this.prepareData(this.response, this.contact);
        const obs = this.domainService.getPDF(data);
        obs.subscribe(response => {
            saveAs(response, 'Resultado búsqueda.pdf');
            this.isLoadingBtnPDF = false;
        });
    }

    fetchDomains() {
        this.relatedDomains = [];
        this.localeService.getContent().domains.forEach(el => {
            this.relatedDomains.push({
                tld: el['tld'].slice(1),
                price: '',
                is_available: false,
                loaded: false
            });
        });
    }

    scrollToCards() {
        this.domainCardsElem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }

    handleCorrectCaptcha(ev) {
        this.activeCaptcha = true;
        this.captcha = ev;
    }

    captchaExpired() {
        this.activeCaptcha = false;
    }

    whois() {
        this.isShowResultDomain = true;
        this.showSearchResult = true;
        this.activeCaptcha = false;
    }

    backFromWhois() {
        this.isShowResultDomain = false;
        this.activeCaptcha = true;
    }

    prepareData(resp, contact) {
        const data = {
            'data': {
                'domain': resp['domain'],
                'is_available': resp['is_available'],
                'phone': contact.main_phone,
                'website': window.location.hostname,
                'price': (resp['prices'] == null ? null : this.symbol
                    + ' ' + this.currencyLocalePipe.transform(resp['prices'][0]['price'], null)),
                'related_domains': []
            }
        };

        this.relatedDomains.forEach(RD => {
            data['data']['related_domains'].push({
                'domain': this.searchedName + '.' + RD['tld'],
                'is_available': RD['is_available'],
                'tld': RD['tld'],
                'price': (RD['price'] === '' ? null : this.symbol
                    + ' ' + this.currencyLocalePipe.transform(RD['price'], null))
            });
        });

        return data;
    }

    private _setMetaCO() {
        this._meta.setTitle('DOMINIOS | Registro de Dominios .CO en Pocos Minutos');
        this._meta.setTag('description', 'Registre su Dominio .CO con BlueHosting, por tan solo $140.000 obtenga su dominio en la empresa Nº 1 de Colombia.');
        this._meta.setTag('keywords', 'dominios, dominios colombia, dominios web, dominios co, dominios baratos colombia, dominios baratos, dominios inmediatos, comprar dominios, subdominios, transferir dominios, punto co, registro dominios  .co .com, .net, .org, .net, .biz, .info, .us.');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/dominio.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Colombia');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
    }

    private _setMetaCL() {
        this._meta.setTitle('DOMINIOS | Registro de Dominio .CL en Pocos Minutos');
        this._meta.setTag('description', 'Registra tu Dominio .CL con BlueHosting, por tan solo $8.900 anual + IVA obtén tu dominio en la empresa Nº 1 de Chile');
        this._meta.setTag('keywords', 'dominios, dominios chile, dominios web, dominios cl, dominios baratos chile, dominios baratos, dominios inmediatos, comprar dominios, subdominios, transferir dominios, punto cl, registro dominios  .cl .com, .net, .org, .net, .biz, .info, .us');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/dominio.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Chile');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaPE() {
        this._meta.setTitle('DOMINIOS | Registro de Dominios .PE en Pocos Minutos');
        this._meta.setTag('description', 'Registra tu Dominio .PE con BlueHosting, por tan solo S/ 200 + IGV obtén tu dominio en la empresa Nº 1 de Perú.');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/dominio.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Perú');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaMX() {
        this._meta.setTitle('DOMINIOS | Registro de Dominios COM.MX en Pocos Minutos');
        this._meta.setTag('description', 'Registra tu Dominio .COM.MX con BlueHosting, por tan solo $ 380 obtén tu dominio en la empresa Nº 1 de México.');
        this._meta.setTag('keywords', 'dominios, dominios méxico, dominios web, dominios mx, dominios baratos méxico, dominios baratos, dominios inmediatos, comprar dominios, subdominios, transferir dominios, registro dominios  .com, .net, .org, .net, .biz, .info, .us.');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/dominio.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en México');
        this._meta.setTag('og:locale', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaAR() {
        this._meta.setTitle('DOMINIOS | Registro de Dominios .COM.AR en Pocos Minutos.');
        this._meta.setTag('description', 'Registrá tu Dominio .COM.AR con BlueHosting, por tan solo $1250 obtén tu dominio en la empresa Nº 1 de Argentina.');
        this._meta.setTag('keywords', 'dominios, dominios com.ar, dominios web, dominios .com.ar, dominios baratos .com.ar, dominios baratos, dominios inmediatos, comprar dominios, subdominios, transferir dominios, punto .com.ar, registro dominios  .com.ar .com, .net, .org, .net, .biz, .info, .us.');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/OG_dominio_ARG.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Argentina');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
}
