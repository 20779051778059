<section class="blockList type2">
    <div class="blockList__box pad bg_blue_dark_extra">
      <div class="inner">
        <div class="sup_ttl2_white" i18n>Todos nuestros planes incluyen</div>
        <h2 class="ttl2_white" i18n>¿Pensaste que <br> era todo?</h2>
        <ul class="listCheck">
          <li *ngFor="let item of lists.list1" i18n><b>{{ item.part1 }} </b>{{ item.part2 }}</li>
        </ul>
      </div>
    </div>
  
    <div class="blockList__box bg_blue_dark pad">
      <div class="inner">
        <div class="sup_ttl2_white" i18n>Nuestro Cloud Server</div>
        <h2 class="ttl2_white" i18n>¿Hay algo más veloz que la luz?</h2>
        <h3 class="ttl3" i18n>300% más rápidos</h3>
        <p i18n>mayor velocidad de lectura, escritura y transferencia de archivos.</p>
        <h3 class="ttl3" i18n>4 veces más rápido booting</h3>
        <p i18n> Promedio de tiempo de arranque de un disco SSD es de 10 a 13 segundos versus los 30 a 40 segundos de un disco HDD.</p>
      </div>
    </div>
  </section>