<button mat-icon-button mat-dialog-close tabindex="-1">
  <svg-icon-sprite [src]="'icon-sprite-close'" width="24px"></svg-icon-sprite>
</button>

<iframe
  #iframe
  height="100%"
  width="100%"
  frameborder="0"
  style="display:block;">
</iframe>
