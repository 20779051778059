import { Component, Inject, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { ProjectsUrl } from '../../../../common/projects-url';
import { Meta } from '../../../../common/Meta';
import { URL } from '../../environments/environment';

@Component({
  selector: 'app-contact-support-wrapper',
  templateUrl: './contact-support-wrapper.component.html'
})
export class ContactSupportWrapperComponent extends ProjectsUrl implements OnInit {
    @Output() Meta: Meta;

    public URL = URL;
    listUrl;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        super();
    }

    ngOnInit() {
        this.Meta = new Meta(
            'Contactar con Soporte | Bluehosting',
            'Envíanos tu ticket al área de soporte. Para resolver tus dudas e inconvenientes acude al centro de soporte técnico de Bluehosting.',
            'Bluehosting',
            this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og_haulmer_support.png',
            'png',
            644,
            374,
            'Contactar con Soporte, Bluehosting'
        );

        this.Meta.addKeyword('contactar con soporte');
        this.Meta.addKeyword('servicio técnico');
        this.Meta.addKeyword('soporte');
        this.Meta.addKeyword('servicios de soporte');
        this.Meta.addKeyword('soporte técnico');

        this.listUrl = this.getProjectList('bluehosting');

        this._scrollTop();
    }

    private _scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

}
