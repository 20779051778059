<section class="videoCompany">
    <div class="videoCompany__info bg_grey">
      <div class="wrapper">
        <h2 class="ttl2_black" i18n>Nuestro Data Center</h2>
        <p i18n>{{text}}</p>
        <button id="bh-{{url}}-data-center-ayudamos" (click)="goToUrl( URL.CONTACT ); track($event)" class="btn btnRedTransp" mat-button i18n>NOSOTROS TE AYUDAMOS</button>
      </div>
    </div>
  
    <div class="videoCompany__video" (click)="toggleVideoCompany($event)">
      <div class="videoCompany__video_wrapper">
        <ng-container *ngIf="showImage">
          <video preload="none" class="video video1" poster="{{urlFoto}}">
            <source src="{{urlVideo}}" type="video/mp4">
            <source src="{{urlFoto}}">
          </video>
          <img src="{{urlFoto}}" alt="poster about company">
        </ng-container>
        <button aria-label="Reproducir Video" class="btnPlay" mat-icon-button></button>
      </div>
    </div>
  </section>