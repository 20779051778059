import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { URL, environment } from '../../environments/environment';
import { LocaleService } from '../services/locale.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { delay, retryWhen, take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { trackPurchase } from '../services/analytics.service';
import { ApiService } from '../services/api.service';

@Component({
    selector: 'app-success',
    templateUrl: './success.component.html',
    styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
    phone = window.innerWidth < 768;

    public titleCard: string = 'Compra <b>Exitosa</b>';
    public showInfo: boolean = false;

    public URL = URL;
    locale;
    symbol = '';
    params;
    totalPrice = '0';
    saleTicket = '0';
    flag = false;
    buttonCalendly = false;
    currency:string = 'CLP';
    items:{ item_id: string; price: number; quantity: number }[];
    productsQty = [];
    email: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private localeService: LocaleService,
        private _http: HttpClient,
        @Inject(PLATFORM_ID) private platformId: any,
        private apiService: ApiService,
    ) {
        this.symbol = this.localeService.symbol;
        this.locale = this.localeService.locale;
        this.activatedRoute.queryParams.subscribe(params => {
            this.params = params;
        });
    }
    loadCalendly(): void {
        const node1 = document.createElement('script');
        node1.src = 'https://assets.calendly.com/assets/external/widget.js';
        node1.type="text/javascript"
        const style1 = document.createElement('link');
        style1.href ='https://calendly.com/assets/external/widget.css'
        style1.rel = 'stylesheet'
        document.getElementsByTagName('head')[0].appendChild(node1);
        document.getElementsByTagName('head')[0].appendChild(style1);
    }
    ngOnInit() {
        let total;
        let ticket;
        let validator;
        let flag2 = false;
        let productsIds;
        let productsBillcy;
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                this.loadCalendly();
            }, 2000);
        }
        if (this.params.hasOwnProperty('total')) {
            total = this.params['total'];
        }
        if (this.params.hasOwnProperty('ticket')) {
            ticket = this.params['ticket'];
        }
        if (this.params.hasOwnProperty('validator')) {
            validator = this.params['validator'];
        }
        if (this.params.hasOwnProperty('email')){
            this.email = this.params['email'];
        }
        if (this.params.hasOwnProperty('idp')){
            productsIds = this.params['idp'].split(',');
        }
        if (this.params.hasOwnProperty('qty')){
            this.productsQty = this.params['qty'].split(',');
        }
        if (this.params.hasOwnProperty('billcy')){
            productsBillcy = this.params['billcy'].split(',');
        }
        if (this.params.hasOwnProperty('dp') && this.params.dp === '1') {
            this.titleCard = 'Pago <b>Existoso</b>';
            this.showInfo = true;
        }
        if (this.params.hasOwnProperty('st') && this.params.st === '1') {
            this.buttonCalendly = true;
        } else {
            this.buttonCalendly = false;
        }
        if (total !== undefined && ticket !== undefined && validator !== undefined) {
            try {
                total = parseInt(total, 16);
                ticket = parseInt(ticket, 16);
                validator = parseInt(validator, 16);
                //productsIds = productsIds.map(id => String(parseInt(id, 16)));
                this.productsQty = this.productsQty.map(quantity => String(parseInt(quantity, 16)));
                flag2 = true;
            } catch (e) {
                console.error(e);
            }
            if (flag2) {
                try {
                    const totalN = Number(total);
                    const ticketN = Number(ticket);
                    validator = Number(validator);
                    const clientVal = Math.floor(Math.sqrt((totalN + ticketN) * 310));
                    if (validator === clientVal) {
                        this.flag = true;
                        this.totalPrice = total;
                        this.saleTicket = ticket;
                    }
                } catch (e) {
                    console.error(e);
                }
            }
            this.sendRequest(ticket, total, validator, this.params['validator']);
            if(this.localeService.getContact().country_code=="cl" && environment.production){
                this.getPlan(productsIds, productsBillcy,ticket)
            }
        }
    }

    sendRequest(ticket, total, validator, validatorEncr) {
        if (!this.flag) {
            const body = {
                'titulo': 'Bluehosting',
                'ticket': ticket,
                'total': total,
                'validator': validator,
                'validatorEncr': validatorEncr
            };
            const urlReq = 'https://contact-form-function.azurewebsites.net/api/HttpTrigger1?code=CjaP8djxs2iV3Y2vX73oqd3WcFikswMRxLs/2DiYdgECpfiyF/jf2w==';
            this._http.post(urlReq, body)
                .pipe(
                    retryWhen(err => err.pipe(
                        delay(3000),
                        take(0)
                    )
                    )
                )
                .subscribe(() => {
                    //console.log('Mensaje enviado');
                }, error => {
                    console.error(error);
                });
        }
    }

    public sendForm(transactionId: string, value: number, currency: string, items: { item_id: string; price: number; quantity: number }[]): void {
        const gSheetsBody = {
            email: this.email,
            triggeredBy: 'purchase_bh',
            country:this.localeService.getContact().country,
            ecommerce: {
                currency: currency,
                value: value,
                transaction_id: transactionId,
                items: items
            }
        };  

        this.apiService.sendContactFormBH(gSheetsBody).subscribe({
            error: (err) => {
                console.error(err);
            },
        });
    }

    getPlan(products: string[], billingcycles:string[],ticket: string) {
        const url = URL.PLAN_BLUEHOSTING;
        const requests = products.map((product, index) => {
            const body = {
              'product': product,
              'currency': this.currency,
              'billingcycle': billingcycles[index]
            };
            const urlReq = url;
            return this._http.post(urlReq, body).pipe(
              retryWhen(errors =>
                errors.pipe(
                  delay(3000),
                  take(0) // Número de reintentos
                )
              ));
          });

          forkJoin(requests).subscribe({
            next: (responses) => {
              this.items = responses.map((response, index) => ({
                item_id: products[index],
                item_name: response ? response['name'] : 'Desconocido',
                price: response ? Number(response['price']) : 0,
                quantity: Number(this.productsQty[index]) || 0,
                item_brand: 'BLUEHOSTING'
              }));
              const value = this.items.reduce((accumulator, item) => {
                return accumulator + (item.price * item.quantity);
              }, 0);

              trackPurchase(String(ticket), value, this.currency, this.items);
              this.sendForm(String(ticket), value, this.currency, this.items);
            },
            error: (error) => {
              console.error('Error al realizar las peticiones:', error);
            }
          });
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }
}
