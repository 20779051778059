import { Component, Input, ElementRef } from '@angular/core';
import { InfoSimple } from '../interfaces/block';
import { ScrollToClass } from '../../../../../common/scrollTo.class';

@Component({
    selector: 'app-block-info-simple',
    templateUrl: './block-info-simple.component.html'
})
export class BlockInfoSimpleComponent extends ScrollToClass{

    @Input() info:InfoSimple;

    constructor(
        private _el: ElementRef,
    ) {
        super(_el);
    }

    navigateToSection(className) {
        this.scrollToSection(className);
    }

}