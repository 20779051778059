import { Component, OnInit } from '@angular/core';
import { traceButton2 } from '../../../../../../common/services/button-trace';
import { urlMapping } from '../../route_ids';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dialog-custom-menu',
    templateUrl: './dialog-custom-menu.component.html'
})
export class DialogCustomMenuComponent implements OnInit {

    public url: string;

    constructor(
        private _router: Router,
    ) { }

    ngOnInit() {
        
        const urlTree = this._router.parseUrl(this._router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';
        this.url = urlMapping[urlSegment] || urlSegment;
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    track(event: Event){
        traceButton2(event, 'navigate');
    }

}
