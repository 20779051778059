import { AppType } from './../../../../../common/assets/website-data';
import { IDataModalTemplate } from './../../../../../common/interfaces/promotions.interface';
import { Component } from "@angular/core";

@Component({
    selector: 'app-dialog-promo-wrapper',
    templateUrl: './dialog-promo.component.html'
})

export class DialogPromoWrapperComponent {

    public dataModal: IDataModalTemplate = {
        title: {
            text: 'Cyber Bluehosting 25%OFF',
            style: ''
        },
        subtitle: {
            text: 'En planes Emprendedor: Power y Full',
            style: '-secondary bold',
        },
        promoCode: {
            code: 'CYBERDAY',
            style: '',
            borderStyle: '',
        },
        button: {
            buttonId: '',
            text: 'COMPRAR PLAN',
            style: 'btn btnWhite',

        },
        footer: {
            text: 'Válido desde el 2 al 8 de octubre',
        }
    }

    get appType() {
        return AppType.bluehosting;
    }
}