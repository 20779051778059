import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { LocaleService } from '../services/locale.service';
import { URL } from '../../environments/environment';
import { MetaService } from '@ngx-meta/core';
import { ScrollToClass } from '../../../../common/scrollTo.class';
import { InfoSimple, List } from '../shared/interfaces/block';


@Component({
    selector: 'app-certificado',
    templateUrl: './certificado.component.html'
})
export class CertificadoComponent extends ScrollToClass implements OnInit {
    // lazy loading images
    showImage1: boolean = false;
    //
    URL = URL;
    isShowPlans = true;
    ssl_certificates;
    symbol = '';
    links;
    locale;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isShowPlans = event.target.innerWidth < 768 ? false : true;
    }

    public info:InfoSimple = {
        title1: 'Protege tu sitio y el de tus',
        title2: 'clientes del Malware',
        section: 'pad',
        button: 'CONTRÁTALO AQUÍ'
    }

    public lists:List = {
        sup_title:'¿Aún hay más potencia?',
        title1:'¿Pensaste que',
        title2:'era todo?',
        list1:[
            'Protección ilimitada para múltiples páginas dentro de un mismo sitio',
            'Eliminamos y Controlamos la lista negra de Google',
            'Sello de sitio confiable',
            'Especialistas de control para problemas',
            'Vigilancia de reputación de marca',
            'Revisión avanzada de seguridad',
            'Eliminación y reparación ilimitada de malware',
            'Garantía de 30 días <a class="has-icon" aria-label="Más información" href="https://www.haulmer.com/legal/garantia-de-reembolso/" target="_blank" style="text-decoration: none;"></a>'
        ],
        list2:[
            'Dominio.cl gratis por 1 año',
            'Migración gratuita',
            'Uptime 99.9%'
        ]
    }

    constructor(
        private localeService: LocaleService,
        private readonly _meta: MetaService,
        private _el: ElementRef,
    ) {
        super(_el);
        this.symbol = this.localeService.symbol;
        this.links = this.localeService.getContent().links;
        this.ssl_certificates = this.localeService.getContent().ssl_certificates;
        this.locale = this.localeService.locale;
    }

    ngOnInit() {
        this.removePlansMobyle();

        //Set Meta Tags
        if (this.locale == "es-CO")
            this._setMetaCO();
        else if (this.locale == "es-MX")
            this._setMetaMX();
        else if (this.locale == "es-PE")
            this._setMetaPE();
        else if (this.locale == "es-AR")
            this._setMetaAR();
        else
            this._setMetaCL();
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    removePlansMobyle() {
        if (window.innerWidth < 768) {
            this.isShowPlans = false;
        }
    }

    navigateToSection(className) {
        this.scrollToSection(className);
    }

    private _setMetaCO() {
        this._meta.setTitle('Certificados SSL: Certificado SSL para sitio web seguro');
        this._meta.setTag('description', 'La Seguridad es un elemento esencial en todo sitio web, garantiza la protección de tus clientes y tus ventas con un Certificado SSL.');
        this._meta.setTag('keywords', 'certificado SSL, certificado SSL colombia, certificado SSL precio, certificado SSL barato, hosting, web hosting, hosting colombia, hosting ssl incluido, hosting ssl colombia');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/certificado.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Colombia');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
    }

    private _setMetaCL() {
        this._meta.setTitle('Certificados SSL: Certificado SSL para sitio web seguro');
        this._meta.setTag('description', 'La Seguridad es un elemento esencial en todo sitio web, garantiza la protección de tus clientes y tus ventas con un Certificado SSL.');
        this._meta.setTag('keywords', 'certificado SSL, certificado SSL chile, certificado SSL precio, certificado SSL barato, hosting, web hosting, hosting chile, hosting ssl incluido, hosting ssl chile');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/certificado.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Chile');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaPE() {
        this._meta.setTitle('Certificados SSL: Certificado SSL para sitio web seguro');
        this._meta.setTag('description', 'La Seguridad es un elemento esencial en todo sitio web, garantiza la protección de tus clientes y tus ventas con un Certificado SSL');
        this._meta.setTag('keywords', 'certificado SSL, certificado SSL perú, certificado SSL precio, certificado SSL barato, hosting, web hosting, hosting perú, hosting ssl incluido, hosting ssl perú.');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/certificado.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Perú');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaMX() {
        this._meta.setTitle('Certificados SSL: Certificado SSL para sitio web seguro');
        this._meta.setTag('description', 'La Seguridad es un elemento esencial en todo sitio web, garantiza la protección de tus clientes y tus ventas con un Certificado SSL');
        this._meta.setTag('keywords', 'certificado SSL, certificado SSL méxico, certificado SSL precio, certificado SSL barato, hosting, web hosting, hosting méxico, hosting ssl incluido, hosting ssl méxico.');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/certificado.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en México');
        this._meta.setTag('og:locale', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaAR() {
        this._meta.setTitle('Certificados SSL: Certificado SSL para sitio web seguro');
        this._meta.setTag('description', 'La Seguridad es un elemento esencial en todo sitio web, garantizá la protección de tus clientes y tus ventas con un Certificado SSL');
        this._meta.setTag('keywords', 'certificado SSL, certificado SSL argentina, certificado SSL precio, certificado SSL barato, hosting, web hosting, hosting argentina, hosting ssl incluido, hosting ssl argentina.');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/OG_certificadossl_ARG.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Argentina');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
}
