<div class="preloading-overlay" *ngIf="isLoading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>
<section class="sectionFirstScreen">
  <div class="container">
    <div class="sup_title center"><b i18n>RÁPIDO Y SEGURO</b></div>
    <h1 class="title1 center"><b i18n>PAGA TU DEUDA AL INSTANTE</b></h1>

    <form class="searchForm" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="searchForm__field">
        <input
          #searchInput
          [class.filled]="form.dirty"
          type="search"
          formControlName="search"
          maxlength="63"
          minlength="5"
          aria-label="busca tu deuda ingresando tu dominio, numero de orden o correo electrónico">
        <span class="custom-placeholder" i18n>Ingresa tu dominio, N° de Orden o correo electrónico</span>
        <button class="btn btnRed" mat-button [disabled]="(form.invalid || isLoading)" type="submit">
          <span i18n>CONSULTAR DEUDA</span>
        </button>
      </div>
    </form>
  </div>

</section>
