<div class="sectionNoResult">
  <div class="sectionNoResult__info">
    <div class="sectionNoResult__info-title">
      <svg-icon-sprite
        [src]="data.icon"
        [width]="'33px'"
        [ngClass]="!!data.state ? data.state : ''">
      </svg-icon-sprite>
      <b i18n>{{data.title}}</b>
    </div>
    <ng-template *ngIf="data.type=='notRegister'; then notRegister; else noResult"></ng-template>
    <ng-template #notRegister>
      <div class="sectionNoResult__info-text" [innerHTML]="data.text" i18n></div>
    </ng-template>
    <ng-template #noResult>
      <div class="sectionNoResult__info-text"i18n>
        {{data.text}}
        <a [routerLink]="['/contactar-con-soporte/.']">
          <b>Contactar con soporte</b>
        </a>
      </div>
    </ng-template>
  </div>
</div>
