import { Component } from '@angular/core';

@Component({
    selector: 'app-autoatencion-first-screen',
    templateUrl: './autoatencion-first-screen.component.html',
    styleUrls: ['./autoatencion-first-screen.component.scss']
})
export class AutoatencionFirstScreenComponent {

}
