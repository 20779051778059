import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, retryWhen, delay, take } from 'rxjs/operators';

import { SecurityResponse, SecurityCounters } from './SecurityResponse';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable()
export class SecurityService {
    apiURL = 'https://api.haulmer.com/v1/sdc/stats/bluehosting';
    data: SecurityResponse;
    fetchedInitData = false;

    constructor(
        private http: HttpClient,
    ) {
    }

    fetchData(): Observable<SecurityResponse> {
        return this.http.get(this.apiURL, httpOptions)
            .pipe(
                map(response => {
                    const securityCounters = new SecurityCounters(response['bloqueos'], response['desinfecciones']);
                    const bloquedIPs = response['ultimas_ip_bloqueadas'];
                    bloquedIPs.forEach(item => {
                        item.flag_url = this.flagUrl(item.country).toLowerCase();
                    });
                    this.data = new SecurityResponse(securityCounters, bloquedIPs);
                    this.fetchedInitData = true;
                    return this.data;
                })
            );
    }

    getMessage(): Observable<SecurityResponse> {
        return of(this.data);
    }

    flagUrl(countryCode: string): string {
        return 'http://www.geonames.org/flags/l/' + countryCode + '.gif';
    }
}
