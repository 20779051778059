<section class="sectionSearchResult">
  <div class="sectionSearchResult__container">

    <h2 id="resultTable" class="sectionSearchResult__title center"><b i18n>Resultados de tu búsqueda</b></h2>
    <!-- START searchResult -->
    <table class="searchResult" aria-describedby="resultTable" *ngIf="!showAlertMessage">

      <thead class="searchResult__thead">
        <tr>
          <ng-container *ngFor="let col of dataResult.header; let i = index">
            <th class="searchResult__th" colSpan="2" *ngIf="i === 0">
              <b i18n>{{col}}</b><span class="border"></span>
            </th>
            <th class="searchResult__th" *ngIf="i !== 0">
              <b i18n>{{col}}</b><span class="border"></span>
            </th>
          </ng-container>
        </tr>
      </thead>

      <tbody class="searchResult__tbody">
        <tr class="searchResult__tr" *ngFor="let row of dataResult.body">

          <td class="searchResult__td"></td>

          <td class="searchResult__td" *ngFor="let col of row.columns; let i = index">
            <b class="title" *ngIf="dataResult.header[i].length > 0" i18n>{{dataResult.header[i]}}</b>
            <ng-container *ngIf="col.text">
              <div class="text" i18n [innerHTML]="col.text"></div>
            </ng-container>
            <span *ngIf="col.code">#{{col.code}}</span>
            <span *ngIf="col.value">
              <b class="value">{{col.value}}</b> <br>
            </span>
            <span *ngIf="col.tax" i18n>{{col.tax}} incluido</span>
            <ng-container class="flex_column flex_end" [ngSwitch]="linkType">
                <div *ngSwitchCase="'both'">
                  <button mat-button class="btn btnBlue"
                  *ngIf="col.buttonDebit"
                  (click)="navigateTo(col.buttonDebit.href)" i18n>
                    DÉBITO O CRÉDITO</button>
                  <button 
                  mat-button class="btn btnBlue" 
                  *ngIf="col.buttonTransfer" 
                  (click)="navigateTo(col.buttonTransfer.href)" i18n>
                    TRANSFERENCIA</button>
                </div>
                <div *ngSwitchCase="'single'">
                  <button 
                    mat-button class="btn btnBlue" 
                    *ngIf="col.buttonPaymentOthers"
                    (click)="navigateTo(col.buttonPaymentOthers.href)"
                    i18n>
                    VER ORDEN
                  </button>
                </div>
                <div *ngSwitchCase="'none'">
                  <p *ngIf="col.buttonPaymentOthers || col.buttonDebit || col.buttonTransfer">Lo sentimos, no se ha encontrado un link de pago</p>
                </div>    
              </ng-container>  
          </td>
        </tr>
      </tbody>
    </table>
    <!-- END searchResult -->

    <!-- START notification -->
    <div class="notification error" *ngIf="showAlertMessage">
      <svg-icon-sprite
        [src]="'icon-sprite-comment-exclamation'"
        [width]="'42px'">
      </svg-icon-sprite>
      <p i18n>
        Lo sentimos! sólo encontramos ordenes impagas de servicios que ya están terminados, si gustas puedes contratar un nuevo producto o servicio.
      </p>
    </div>
    <!-- END notification -->

  </div>
</section>
