import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';


@Component({
    template: 'unsuscriber'
})
export class Unsubscriber implements OnDestroy {
    public ngUnsubscribe$: Subject<void> = new Subject<void>();

    constructor() { }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
