<section class="sendTicket">
  <div class="sendTicket__container">
    <div class="sendTicket__inner">
      <button class="btn" mat-button [routerLink]="['/web-hosting/.']"><b i18n>Productos</b></button>
      <button class="btn" mat-button [routerLink]="['/contactar-con-soporte/.']"><b i18n>Soporte</b></button>
    </div>
    <div class="sendTicket__inner">
      <h2 class="sendTicket__title" i18n><b>Enviar ticket</b> <br> de consulta</h2>
      <p i18n>
        Levanta un Ticket de Soporte para solicitar asistencia técnica y monitorear el estado de tu solicitud.
      </p>
    </div>
  </div>
</section>
