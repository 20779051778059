import { Component, OnInit, Output } from '@angular/core';
import { Meta } from '../../../../common/Meta';
import { URL } from '../../environments/environment';
import { LocaleService } from '../services/locale.service';

@Component({
    selector: 'app-payment-forms-wrapper',
    templateUrl: './payment-forms-wrapper.component.html'
})
export class PaymentFormsWrapperComponent implements OnInit {
    @Output() Meta: Meta;
    @Output() TLD;

    public URL = URL;
    //TODO Cinta promocional cambiar de 135 - 180
    public headerHeight = 135;
    locale;

    constructor(
        private localeService: LocaleService,
    ) { }

    ngOnInit() {
        this.locale = this.localeService.locale;

        this.localeService.getMessage().subscribe(contact => {
            this.TLD = (contact.country_code === 'me' ? 'mx' : contact.country_code);
        });

        this.Meta = new Meta(
            'Formas de Pago | BlueHosting',
            'Conoce las formas de pago que BlueHosting deja a tu disposición. Cancela tus planes y disfrútalos ¡Ahora!',
            'BlueHosting',
            this.URL.HAULMER_WEBSITE + 'assets/og/images/og-formas-de-pago.png',
            'png',
            644,
            374,
            'Formas de Pago | BlueHosting'
        );

        this.Meta.addKeyword('formas de pagos');
        this.Meta.addKeyword('métodos de pago');

        // setTimeout(() => {
        //     if (this._document.getElementById('appFavicon')) {
        //         this._document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');
        //     }
        // }, 100);
    }
}
