import { Component, OnInit } from '@angular/core';
import { URL } from '../../../environments/environment';
import { Router } from '@angular/router';
import { urlMapping } from '../route_ids';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
  selector: 'app-block-logos',
  templateUrl: './block-logos.component.html'
})
export class BlockLogosComponent implements OnInit {
    public URL = URL;
    public url:string;

    constructor(
      private _router: Router
    ) { 
      const urlTree = this._router.parseUrl(this._router.url);
      const primarySegment = urlTree.root.children['primary'];
      const urlSegment = primarySegment?.segments[0]?.path || 'home';;
      this.url = urlMapping[urlSegment] || urlSegment;
    }

    ngOnInit() {
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    public track(event:Event){
      traceButton2(event, 'navigate');
  }
}
