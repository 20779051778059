import { Component, OnInit, Input, Inject, HostListener, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-chart-productivity3',
    templateUrl: './chart-productivity3.component.html'
})
export class ChartProductivity3Component implements OnInit {
    @Input() endValue;
    @Input() maxValue = 100;
    @Input() serviceName = "Hosting.cl";

    chartsIsAnimated: boolean = false;

    ctx;
    x;
    y;
    radius;
    start;
    end;
    value;
    pi;
    canvas;
    curPerc = 0;
    curPerc2 = 0;
    circ;
    totalArcLength;
    valuePerc;

    @HostListener('window: scroll')
    onScroll() {
        this.playAnimateChart();
    }

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
    ) { }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.initCanvas();
        }
        if (!this.maxValue) this.maxValue = 100;
    }

    isInViewport(elem) {
        const viewportWindow = window.innerHeight || this._document.documentElement.clientHeight;
        const elemRect = elem.getBoundingClientRect();
        return (elemRect.top >= 104 && elemRect.bottom <= viewportWindow);
    }

    playAnimateChart() {
        if (window.innerWidth >= 768) {
            const sectionCanvas = this._document.getElementsByClassName('chartProductivity')[0] as HTMLElement;

            if (this.isInViewport(sectionCanvas) && !this.chartsIsAnimated) {
                this.animate();
                this.chartsIsAnimated = true;
            }
        }
    }

    initCanvas() {
        this.canvas = this._document.getElementById('canvasProductivity3') as HTMLCanvasElement;
        this.ctx = this.canvas.getContext('2d');
        this.pi = Math.PI;
        this.x = this.canvas.width / 2;
        this.y = this.canvas.height / 2;
        this.radius = this.canvas.width / 2 - 10;
        this.circ = this.pi * 2;
        this.start = this.pi * 1.5; // Start position
        this.end = this.pi * 3.5; // End position
        this.value = 0.000;
        this.totalArcLength = this.pi * 2;

        this.defCircle();
    }

    // START arc default
    defCircle() {
        this.ctx.beginPath();
        this.ctx.lineWidth = 20;
        this.ctx.strokeStyle = '#dddfe3';
        this.ctx.arc(this.x, this.y, this.radius, this.start, this.end, false);
        this.ctx.stroke();
    }

    // START supValueCircle
    supValueCircle() {
        this.ctx.beginPath();
        this.ctx.font = '14px CircularStd-Bold';
        this.ctx.fillStyle = '#031b4e';
        this.ctx.textAlign = 'center';
        this.ctx.textBaseline = 'middle';
        this.ctx.fillText(this.serviceName, this.x, this.canvas.width / 2 - 20);
    }

    // START subValueCircle
    subValueCircle() {
        this.ctx.beginPath();
        this.ctx.font = '10px CircularStd-Bold';
        this.ctx.fillStyle = '#031b4e';
        this.ctx.textAlign = 'center';
        this.ctx.textBaseline = 'middle';
        this.ctx.fillText('Análisis total', this.x, this.canvas.width / 2 + 20);
    }

    // START valueCircle
    valueCircle() {
        this.ctx.beginPath();
        this.ctx.font = '24px CircularStd-Bold';
        this.ctx.fillStyle = '#031b4e';
        this.ctx.textAlign = 'center';
        this.valuePerc = this.maxValue - this.curPerc;
        this.ctx.fillText('-' + this.valuePerc + '%', this.x, this.canvas.width / 2);
    }

    // START progress
    progress(current) {
        this.ctx.beginPath();
        this.ctx.lineWidth = 20;
        this.ctx.strokeStyle = '#033f85';
        this.ctx.arc(this.x, this.y, this.radius, this.start, ((this.totalArcLength * current) + this.start), false);
        this.ctx.stroke();
    }

    animate(current?) {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.beginPath();
        this.defCircle();
        this.subValueCircle();
        this.supValueCircle();
        this.valueCircle();
        this.progress(current);
        this.curPerc++;

        if (this.curPerc < this.endValue + 1) {
            requestAnimationFrame(() => {
                this.animate(this.curPerc / this.maxValue);
            });
        }
    }
}
