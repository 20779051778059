  <!-- START tapeBlock -->
  <section *ngIf="locale == 'es-CL'" class="tapeBlock" [class.show-bg]="showImage1">
    <div class="tapeBlock__container">
      <div class="tapeBlock__inner">
        <h2 class="ttl2 align-left accent-color">Ahora en bluehosting</h2>
        <div class="sub_text">Tu Hosting más tu dominio .CL <br>
          desde solo <b>$43.900 anuales</b>
        </div>
        <div class="note accent-color">*El registro de dominio es por un año</div>
      </div>
      <picture>
        <source srcset="/assets/images/iconcl-desktop@1x.png,
                        /assets/images/iconcl-desktop@2x.png 2x" media="(min-width: 992px)">
        <img  src="/assets/images/iconcl-tablet@1x.png"
              srcset="/assets/images/iconcl-tablet@2x.png 2x"
              width="100%"
              height="100%"
              alt="icon cl hosting">
      </picture>
    </div>
  </section>
  <!-- END tapeBlock -->