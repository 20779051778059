import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {

    duration = 0;
    panelClass = 'snackBar-btn';
    btnText = 'REINTENTAR';

    constructor(
        private snackBar: MatSnackBar,
    ) {}

    showMessage(text: string, reload: boolean, btnText?: string, duration?: number, panelClass?: string) {
        const snackRef = this.snackBar.open(
            text,
            (btnText != null ? btnText : this.btnText),
            {
                duration: (duration != null ? duration : this.duration),
                panelClass: (panelClass != null ? panelClass : this.panelClass)
            }
        );

        if (reload) {
            snackRef.afterDismissed().subscribe(() => location.reload());
        }
    }
}

