<section class="autoatencionSection" [class.showImg]="loadImages">
  <div class="autoatencionSection__container">
    <div class="autoatencionSection__info">
      <div class="autoatencionSection__info-suptitle">autoatención hosting</div>
      <h1 class="autoatencionSection__info-title">
        Administra tu hosting de forma sencilla
      </h1>
      <div class="">
        Accede rápidamente a toda la información que necesitas, como tus datos DNS y de acceso, sin complicaciones. Ya no
        tendrás que esperar por asistencia técnica; todo está al alcance de tu mano.
        <br>
        <br>
        <b>
          ¡Simplifica tu gestión de hosting y
          mantén el control total sobre tu cuenta!
        </b>
      </div>
      <button (click)="track($event)" id="bh-h-autoatencion" class="btn btnRed" mat-button [routerLink]="['/autoatencion/.']" i18n>
        <b>IR A AUTOATENCIÓN</b>
      </button>
    </div>
  
    <picture>
      <source srcset="/assets/images/home-page/autoatencion-section/autoatencion-preview@1x.avif,
                      /assets/images/home-page/autoatencion-section/autoatencion-preview@2x.avif 2x"
              type="image/avif" media="(min-width: 993px)">
      <source srcset="/assets/images/home-page/autoatencion-section/autoatencion-preview@1x.webp,
                      /assets/images/home-page/autoatencion-section/autoatencion-preview@2x.webp 2x"
              type="image/webp" media="(min-width: 993px)">
      <source srcset="/assets/images/home-page/autoatencion-section/autoatencion-preview@1x.png,
                      /assets/images/home-page/autoatencion-section/autoatencion-preview@2x.png 2x"
              type="image/png" media="(min-width: 993px)">
      <source srcset="/assets/images/home-page/autoatencion-section/autoatencion-preview-tablet@1x.avif,
                      /assets/images/home-page/autoatencion-section/autoatencion-preview-tablet@2x.avif 2x"
              type="image/avif" media="(min-width: 671px)">
      <source srcset="/assets/images/home-page/autoatencion-section/autoatencion-preview-tablet@1x.webp,
                      /assets/images/home-page/autoatencion-section/autoatencion-preview-tablet@2x.webp 2x"
              type="image/webp" media="(min-width: 671px)">
      <source srcset="/assets/images/home-page/autoatencion-section/autoatencion-preview-tablet@1x.png,
                      /assets/images/home-page/autoatencion-section/autoatencion-preview-tablet@2x.png 2x"
              type="image/png" media="(min-width: 671px)">
      <source srcset="/assets/images/home-page/autoatencion-section/autoatencion-preview-mobile@1x.avif,
                      /assets/images/home-page/autoatencion-section/autoatencion-preview-mobile@2x.avif 2x"
              type="image/avif">
      <source srcset="/assets/images/home-page/autoatencion-section/autoatencion-preview-mobile@1x.webp,
                      /assets/images/home-page/autoatencion-section/autoatencion-preview-mobile@2x.webp 2x"
              type="image/webp">
      <img  src="/assets/images/home-page/autoatencion-section/autoatencion-preview-mobile@1x.png"
            srcset="/assets/images/home-page/autoatencion-section/autoatencion-preview-mobile@2x.png 2x" alt="Autoatención" *ngIf="loadImages">
    </picture>
  </div>
</section>
