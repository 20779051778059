<!-- START Header -->
<header [ngClass]="promotionStrip ? 'wrapHeader_strip':'wrapHeader'">
  <div class="container">
    <div class="wrapHeader__top">
      <ul class="headerMenu">
        <li>
          <a id="bh-{{url}}-d-hea-emprendedores" [routerLink]="['/web-hosting/.']" [routerLinkActive]="['active']" (click)="scrollTop();track($event)">EMPRENDEDORES</a>
        </li>
        <li>
          <a id="bh-{{url}}-d-hea-negocios" [routerLink]="['/web-hosting-negocio/.']" [routerLinkActive]="['active']" (click)="scrollTop();track($event)">NEGOCIOS</a>
        </li>
        <li>
          <a id="bh-{{url}}-d-hea-empresas" [routerLink]="['/web-hosting-empresa/.']" [routerLinkActive]="['active']" (click)="scrollTop();track($event)">EMPRESAS</a>
        </li>
      </ul>

      <ul class="headerMenu">
        <li>
          <a (click)="track($event)" id="bh-{{url}}-nv-autoatencion" [routerLink]="['/autoatencion/.']" [routerLinkActive]="['active']">
            <svg-icon-sprite [src]="'icon-sprite-cursor-finger'" [width]="'16px'"></svg-icon-sprite>
            <span>Autoatención</span>
          </a>
        </li>
        <li>
          <a (click)="track($event)" id="bh-{{url}}-d-hea-pago" [routerLink]="['/pago-directo/.']" [routerLinkActive]="['active']">
            <svg-icon-sprite class="tarjeta" [src]="'icon-sprite-credit-card'" [width]="'16px'">
            </svg-icon-sprite>
            <span>Pago de cuentas</span>
          </a>
        </li>
        <li>
          <a role='button' id="bh-{{url}}-d-hea-soporte" #triggerDialog (click)="openDialogCustomMenu()">Soporte <mat-icon>keyboard_arrow_down</mat-icon></a>
        </li>
        <li class="loged_in">
          <a (click)="track($event)" role="button" id="bh-{{url}}-d-hea-login" (click)="goToUrl( URL.BLUEHOSTING_LOGIN )">Iniciar sesión</a>
        </li>
      </ul>

      <button mat-button [id]="isTablet ? idButtonContact : ''" class="btn btnTransp" [routerLink]="['/contactar-con-ventas/.']" [routerLinkActive]="['active']" (click)="scrollTop(); track($event)">
        Contactar con ventas
      </button>
    </div>

    <div class="wrapHeader__bottom">
      <a [routerLink]="['/']" (click)="scrollTop()" class="wrap_logo">
        <img  class="logo_white" 
              src="/assets/images/bluehosting.svg" 
              width="300px"
              height="54px"
              alt="bluehosting">
        <img  class="logo_blue" 
              src="/assets/images/bluehosting-blue.svg" 
              width="300px"
              height="54px"
              alt="bluehosting">
        <span>By Haulmer</span>
      </a>

      <!-- START headerMenu -->
      <ul class="headerMenu">
        <li class="dropdown" (mouseenter)="menuDropdownFadeIn($event)" (mouseleave)="closeMenuDropdown($event)">
          <a id="bh-{{url}}-d-hea-webhosting" [routerLink]="['/web-hosting/.']" [routerLinkActive]="['active']" (click)="scrollTop(); track($event)">
            Web Hosting
          </a>
          <!-- start dropdown menu -->
          <ul class="headerMenu__dropdown" (mouseleave)="closeMenuDropdown($event)">
            <li>
              <button id="bh-{{url}}-d-hea-emprendedores" class="headerMenu__dropdown-btn" mat-button [routerLink]="['/web-hosting/.']" (click)="closeMenuDropdown($event);scrollTop(); track($event)">
                <i class="icon person"></i>
                <span class="inner">
                  <span class="ttl">Emprendedores</span>
                  <span class="sub_ttl">Servicios pensados para ayudarte a alcanzar tus metas</span>
                </span>
              </button>
            </li>
            <li>
              <button id="bh-{{url}}-d-hea-negocios" class="headerMenu__dropdown-btn" mat-button [routerLink]="['/web-hosting-negocio/.']" (click)="closeMenuDropdown($event);scrollTop(); track($event)">
                <i class="icon pyme"></i>
                <span class="inner">
                  <span class="ttl">Negocios</span>
                  <span class="sub_ttl">Planes creados para impulsar tu desarrollo</span>
                </span>
              </button>
            </li>
            <li>
              <button id="bh-{{url}}-d-hea-wordpress" class="headerMenu__dropdown-btn" mat-button [routerLink]="['/hosting-negocio-wordpress/.']" (click)="closeMenuDropdown($event);scrollTop(); track($event)">
                <i class="icon wordpress"></i>
                <span class="inner">
                  <span class="ttl">Wordpress</span>
                  <span class="sub_ttl">Hosting potenciado para obtener lo mejor de Wordpress</span>
                </span>
              </button>
            </li>
            <li>
              <button id="bh-{{url}}-d-hea-empresas" class="headerMenu__dropdown-btn" mat-button [routerLink]="['/web-hosting-empresa/.']" (click)="closeMenuDropdown($event);scrollTop(); track($event)">
                <i class="icon empresa"></i>
                <span class="inner">
                  <span class="ttl">Empresas</span>
                  <span class="sub_ttl">Recursos dispuestos para potenciar tus inversiones</span>
                </span>
              </button>
            </li>
          </ul>
          <!-- end dropdown menu -->
        </li>

        <li>
          <a id="bh-{{url}}-d-hea-dominios" [routerLink]="['/dominios/.']" [routerLinkActive]="['active']" (click)="scrollTop(); track($event)">Dominios</a>
        </li>
        <li class="dropdown" (mouseenter)="menuDropdownFadeIn($event)" (mouseleave)="closeMenuDropdown($event)">
          <a id="bh-{{url}}-d-hea-vps" [routerLink]="['/vps/.']" [routerLinkActive]="['active']" (click)="scrollTop(); track($event)">Cloud Server</a>
          <ul class="headerMenu__dropdown" (mouseleave)="closeMenuDropdown($event)">
            <li>
              <button id="bh-{{url}}-d-hea-vps" class="headerMenu__dropdown-btn" mat-button [routerLink]="['/vps/.']" (click)="closeMenuDropdown($event);scrollTop(); track($event)">
                <i class="icon person"></i>
                <span class="inner">
                  <span class="ttl">VPS SSD</span>
                </span>
              </button>
            </li>
            <li>
              <button id="bh-{{url}}-d-hea-vps-back" class="headerMenu__dropdown-btn" mat-button [routerLink]="['/vps-con-backup/.']" (click)="closeMenuDropdown($event);scrollTop(); track($event)">
                <i class="icon pyme"></i>
                <span class="inner">
                  <span class="ttl">VPS SSD + Backup</span>
                </span>
              </button>
            </li>
          </ul>
        </li>
        <li class="dropdown">
          <a id="bh-{{url}}-d-hea-seguridad" [routerLink]="['/certificados-ssl/.']" [routerLinkActive]="['active']" (click)="scrollTop(); track($event)">
            Certificado SSL
          </a>
        </li>
        <li>
          <a id="bh-{{url}}-d-hea-reseller" [routerLink]="['/hosting-reseller/.']" [routerLinkActive]="['active']" (click)="scrollTop(); track($event)">Programa Reseller</a>
        </li>

        <li class="dropdownBackground">
          <span class="dropdownBackground__inner noTransition"></span>
        </li>
      </ul>
      <!-- END headerMenu -->

      <button mat-button class="btn btnTransp" [id]="isDesktop ? idButtonContact : ''" [routerLinkActive]="['active']" (click)="scrollTop(); track($event)" onclick="return gtag_report_conversion('/contactar-con-ventas/')">
        Contactar con ventas
      </button>
    </div>
  </div>

  <button id="bh-{{url}}-m-ham" mat-icon-button aria-label="Mostrar Opciones" class="humburger" (click)="onClickOpenMenu()">
    <span class="humburger_inner"></span>
  </button>

</header>
<!-- START mobyleMenu -->
<nav class="mobyleMenu">
  <a [routerLink]="['/web-hosting/.']" class="wrap_logo__grey wrap_logo__img" *ngIf="isActiveMobyleMenu1" (click)="onClickCloseMenu();scrollTop()">
    <img  class="logo_desk" 
          src="/assets/images/bluehosting-blue.svg" 
          width="300px"
          height="54px"
          alt="bluehosting">
  </a>

  <!-- <div class="mobyleMenu__ttl" *ngIf="isActiveMobyleMenu2">
    <button mat-icon-button
      (click)="closeDropdownMenu('seguridad')">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    Seguridad Web
  </div> -->

  <div class="mobyleMenu__ttl" *ngIf="isActiveMobyleMenu3">
    <button mat-icon-button (click)="closeDropdownMenu('web hosting')">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    Web Hosting
  </div>
  <div class="mobyleMenu__ttl" *ngIf="isActiveMenuVPS">
    <button mat-icon-button (click)="closeDropdownMenu('vps')">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    Cloud Server
  </div>

  <div class="mobyleMenu__ttl" *ngIf="isActiveDropdownSupport">
    <button mat-icon-button (click)="closeDropdownMenu('soporte')">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    Soporte
  </div>

  <button mat-icon-button aria-label="Ocultar Opciones" class="humburger" (click)="onClickCloseMenu()">
    <span class="humburger_inner"></span>
  </button>

  <div class="mobyleMenu__btn">
    <button [id]="isMobile ? 'bh-contactsales' : ''" mat-button class="btnRed" [routerLink]="['/contactar-con-ventas/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop(); track($event)">
      Contactar con ventas
    </button>
  </div>

  <div class="mobyleMenu__wrapper">
    <ul class="mobyleMenu__list" *ngIf="isActiveMobyleMenu1">
      <li>
        <a role="button" id="bh-{{url}}-m-ham-webhosting" [routerLinkActive]="['active']" (click)="scrollTop(); openDropdownMenu('web hosting')">
          <span>Web Hosting</span>
          <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
      </li>
      <li>
        <a id="bh-{{url}}-m-ham-dominios" [routerLink]="['/dominios/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop(); track($event)">Dominios</a>
      </li>
      <li>
        <a role="button" id="bh-{{url}}-m-ham-vps" [routerLinkActive]="['active']" (click)="scrollTop(); openDropdownMenu('vps')">
          <span>Cloud Server</span>
          <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
      </li>
      <li>
        <a id="bh-{{url}}-m-ham-seguridad" [routerLinkActive]="['active']" (click)="scrollTop(); onClickCloseMenu(); track($event)" [routerLink]="['/certificados-ssl/.']">
          <span>Certificado SSL</span>
        </a>
      </li>
      <li>
        <a id="bh-{{url}}-m-ham-reseller" [routerLink]="['/hosting-reseller/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop(); track($event)">Programa Reseller</a>
      </li>
    </ul>

    <ul class="mobyleMenu__list" *ngIf="isActiveMobyleMenu3">
      <li>
        <a id="bh-{{url}}-m-ham-emprendedores" [routerLink]="['/web-hosting/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop(); track($event)">Emprendedores</a>
      </li>
      <li>
        <a id="bh-{{url}}-m-ham-negocios" [routerLink]="['/web-hosting-negocio/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop(); track($event)">Negocios</a>
      </li>
      <li>
        <a id="bh-{{url}}-m-ham-wordpress" [routerLink]="['/hosting-negocio-wordpress/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop(); track($event)">Wordpress</a>
      </li>
      <li>
        <a id="bh-{{url}}-m-ham-empresas" [routerLink]="['/web-hosting-empresa/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop(); track($event)">Empresas</a>
      </li>
    </ul>

    <ul class="mobyleMenu__list" *ngIf="isActiveMenuVPS">
      <li>
        <a id="bh-{{url}}-m-ham-vps" [routerLink]="['/vps/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop(); track($event)">VPS SSD</a>
      </li>
      <li>
        <a id="bh-{{url}}-m-ham-vps-back" [routerLink]="['/vps-con-backup/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop(); track($event)">VPS SSD + Backup</a>
      </li>
    </ul>

    <div class="dropdownWrapper" *ngIf="isActiveDropdownSupport">
      <ul class="customDropdownMenu">
        <li class="customDropdownMenu__item">
          <a id="bh-{{url}}-support" (click)="goToUrl('contactar-con-soporte'); track($event)" target="_blank">
            <i class="icon icon_support"></i>
            <span class="title">Contactar Soporte</span>
          </a>
        </li>
        <li class="customDropdownMenu__item">
          <a (click)="track($event)" id="bh-{{url}}-support-doc" href="https://help.haulmer.com/hc/web-hosting" target="_blank">
            <i class="icon icon_doc"></i>
            <span class="title">Documentación</span>
          </a>
        </li>
        <li class="customDropdownMenu__item">
          <a (click)="track($event)" id="bh-{{url}}-m-ham-soporte-status" href="https://status.bluehosting.host/" target="_blank">
            <i class="icon icon_status_service"></i>
            <span class="title">Status de Servicios</span>
          </a>
        </li>
        <li class="customDropdownMenu__item">
          <a (click)="track($event)" href="https://survey.typeform.com/to/zQ7I32J9#empresa=Bluehosting&issuetype=11208" target="_blank">
            <i class="icon icon_suggestions"></i>
            <span class="title">Sugerencias o reclamos</span>
          </a>
        </li>
      </ul>
    </div>

    <ul class="mobyleMenu__list bg_grey" *ngIf="!isActiveDropdownSupport">
      <li>
        <a id="bh-{{url}}-m-autoatencion" [routerLink]="['/autoatencion/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu(); track($event)">
          <span>Autoatención</span>
        </a>
      </li>
      <li>
        <a id="bh-{{url}}-m-hea-pago" [routerLink]="['/pago-directo/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu(); track($event)">
          <span>Pago de cuentas</span>
        </a>
      </li>
      <li>
        <a role="button" id="bh-{{url}}-m-ham-soporte" (click)="openDropdownMenu('soporte')">
          <span>Soporte</span>
          <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
      </li>
      <li class="loged_in">
        <a role="button" id="bh-{{url}}-m-ham-login" (click)="goToUrl( URL.BLUEHOSTING_LOGIN ); track($event)" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop()">Iniciar sesión</a>
      </li>
    </ul>
  </div>
</nav>
<!-- END mobyleMenu -->
<!-- END Header -->

<div class="overlay" (click)="onClickCloseMenu()"></div>
