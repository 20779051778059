import { PipeTransform, Pipe } from '@angular/core';
import { formatDate } from '@angular/common';

import { LocaleService } from './services/locale.service';

@Pipe({ name: 'dateLocale' })
export class DateLocalePipe implements PipeTransform {

    constructor(private localeService: LocaleService) {
    }
    
    transform(value, args: string[]): string {
        let date_format = 'dd/MM/yyyy';
        
        if(this.localeService.locale === 'en-US')
        {
            date_format = 'MMMM dd, yyyy';
        }

        return formatDate(value, 
            date_format,
            this.localeService.locale).trim();  
    }
}