import { Component, Input } from '@angular/core';
import { ListExtra } from '../interfaces/block';

@Component({
    selector: 'app-block-list-extra',
    templateUrl: './block-list-extra.component.html'
})
export class BlockListExtraComponent{

    @Input() lists:ListExtra;

}