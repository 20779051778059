export class Plan {
	name: string;
	description: string;
	annually: number;
	biennially: number;
    triennially: number;
    
    constructor(name: string, description: string, annually: string, biennially: string, triennially: string)
    {
        this.name = name;
        this.description = description;
        this.annually = parseFloat(annually);
        this.biennially = parseFloat(biennially);
        this.triennially = parseFloat(triennially);
    }
}

export class PlanStructure {
    persona: Plan[];
    pyme: Plan[];
    empresa: Plan[];
}