import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { HostingManagementDialogComponent, HostingManagementType } from './hosting-management-dialog/hosting-management-dialog.component';
import { TypeformModalComponent } from './typeform-modal/typeform-modal.component';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-hosting-management',
    templateUrl: './hosting-management.component.html',
    styleUrls: ['./hosting-management.component.scss'],
})
export class HostingManagementComponent {

    cards = [
        {
            id: 'bh-aa-dcpanel',
            icon: 'icon-sprite-computer',
            text: 'Recuperar mis datos de acceso cPanel',
            type: HostingManagementType.cPanel,
        },
        {
            id: 'bh-aa-adcliente',
            icon: 'icon-sprite-database',
            text: 'Recuperar mis datos de área de cliente',
            type: HostingManagementType.clientArea,
        },
        {
            id: 'bh-aa-ddns',
            icon: 'icon-sprite-database',
            text: 'Recuperar mis datos DNS',
            type: HostingManagementType.dns,
        },
        {
            id: 'bh-aa-tuto-pagodirecto',
            icon: 'icon-sprite-credit-card',
            text: 'Cómo pagar mi servicio',
            type: HostingManagementType.payment,
        },
        {
            id: 'bh-aa-tutodns',
            icon: 'icon-sprite-settings-sliders',
            text: 'Configurar DNS en NIC o en otra plataforma',
            type: HostingManagementType.nic,
        },
        {
            id: 'bh-aa-tuto-conf-mail',
            icon: 'icon-sprite-envelope',
            text: 'Cómo configurar gestor de correo externo',
            type: HostingManagementType.configEmail,
        },
        {
            id: 'bh-aa-tuto-webmail',
            icon: 'icon-sprite-envelope',
            text: 'Cómo acceder a Webmail',
            type: HostingManagementType.webmail,
        },
    ];

    constructor(
        private readonly _dialog: MatDialog,
    ) { }

    openModal(type: HostingManagementType): void {
        switch (type) {
            case HostingManagementType.nic:
                this.openTypeform('https://form.typeform.com/to/P803upV5');
                break;
            case HostingManagementType.configEmail:
                this.openTypeform('https://form.typeform.com/to/jMLx9NhY');
                break;
            case HostingManagementType.webmail:
                this.openTypeform('https://form.typeform.com/to/XanJJPsa');
                break;
            case HostingManagementType.payment:
                this.openTypeform('https://form.typeform.com/to/xVEUG3JL');
                break;
            default:
                this._dialog.open(HostingManagementDialogComponent, {
                    width: '420px',
                    panelClass: 'dialogHostingManagement',
                    data: type,
                });
                break;
        }
    }

    private openTypeform(url){
        this._dialog.open(TypeformModalComponent, {
            panelClass: 'roundedDialog',
            maxWidth: '1080px',
            minWidth: '90vw',
            height: '90vh',
            data: { typeformUrl: url },
        });
    }

    track(event:Event){
        traceButton2(event, 'popup');
    }

}
