import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { getLocaleCurrencySymbol } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { CONTACTS } from '../../../../common/contacts.constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { Contact } from '../../../../common/Contact';
import { productivityContent_pe } from '../content.class';
import { content_pe } from '../content/content-pe';


@Injectable()
export class LocaleService {
    contactList: Contact[] = CONTACTS;

    locale: string;
    symbol: string;
    currencyName: string;

    currentContact = this.contactList[0];
    currentCountryCode = this.currentContact.country_code;

    contactSource: BehaviorSubject<Contact>;
    contactMessage: Observable<Contact>;

    // TLD = 'co'; // window.location.href.split('.')[2].split('/')[0].toLowerCase();
    TLD = 'pe';

    // Definition for Google Tag Manager
    selectorGTM = {
        GTMcl: 'GTM-KXVXLKJ',
        GTMco: 'GTM-W9M3XNJ',
        GTMpe: 'GTM-NV2HKWM'
    };

    selectorGtag = {
        clGtag: 'UA-10166659-1',
        coGtag: 'UA-10166659-2',
        peGtag: 'UA-12055722-1'
    };

    constructor(
        private http: HttpClient,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        this.locale = this.getLocale(this.TLD);
        this.symbol = getLocaleCurrencySymbol(this.locale);
        // this.currencyName = getLocaleCurrencyName(this.locale);
        this.currentCountryCode = (this.TLD === 'mx' ? 'me' : this.TLD);
        this.currentContact = this.getContact();
        this.contactSource = new BehaviorSubject<Contact>(this.currentContact);
        this.contactMessage = this.contactSource.asObservable();

        const contactChile = this.contactList.find(x => x.country === 'Chile');
        contactChile.main_phone = '+56 2 3210 9063';
        contactChile.main_phone_href = '+56232109063';
        const contactPeru = this.contactList.find(x => x.country === 'Perú');
        contactPeru.main_phone = '+51 1 1707 0316';
        contactPeru.main_phone_href = '+5117070316';
    }

    updateContact(contact): void {
        this.contactSource.next(contact);
    }

    getMessage(): Observable<Contact> {
        return this.contactMessage;
    }

    getContact(): Contact {
        return this.contactList.filter(c => c.country_code === this.currentCountryCode)[0];
    }

    getLocale(tld: string): string {
        const TLD = tld.toLowerCase();
        if (TLD === 'net') {
            return 'en-US';
        } else if (TLD === 'cl' || TLD === 'ar' || TLD === 'mx' ||
            TLD === 'ec' || TLD === 'co' || TLD === 'pe') {
            return 'es-' + (TLD.toUpperCase());
        } else {
            return 'es-CL';
        }
    }

    getContent() {
        return content_pe;
    }

    getProductivityContent() {

        return productivityContent_pe;
    }

    getGTM(): string {
        let element: string;
        switch (this.locale) {
            case 'es-CO':
                element = this.selectorGTM.GTMco;
                break;
            case 'es-PE':
                element = this.selectorGTM.GTMpe;
                break;
            // case 'es-MX':
            //     content = productivityContent_mx;
            //     break;
            // case 'es-AR':
            //     content = productivityContent_ar;
            //     break;
            default:
                element = this.selectorGTM.GTMcl;
        }
        return element;
    }


    // Selection Google Analytics ID
    getGtag(): string {
        let element: string;
        switch (this.locale) {
            case 'es-CO':
                element = this.selectorGtag.coGtag;
                break;
            case 'es-PE':
                element = this.selectorGtag.peGtag;
                break;
            // case 'es-MX':
            //     content = productivityContent_mx;
            //     break;
            // case 'es-AR':
            //     content = productivityContent_ar;
            //     break;
            default:
                element = this.selectorGtag.clGtag;
        }
        return element;
    }
}
