<!-- START firstScreen -->
<div [ngClass]="{'reseller': true}">
  <section class="firstScreen">
    <picture>
      <source srcset="/assets/images/first-screen/first_img_screen_reseller@1x.avif,
        /assets/images/first-screen/first_img_screen_reseller@2x.avif 2x" type="image/avif">
      <source srcset="/assets/images/first-screen/first_img_screen_reseller@1x.webp,
        /assets/images/first-screen/first_img_screen_reseller@2x.webp 2x" type="image/webp">
      <img src="/assets/images/first-screen/first_img_screen_reseller@1x.jpg" srcset="/assets/images/first-screen/first_img_screen_reseller@2x.jpg 2x" alt="First Screen Image">
    </picture>
    <div class="container">
      <div class="firstScreen__inner">
        <h1 class="firstScreen__ttl" i18n>¡GANA DINERO CON NUESTRO <br> HOSTING RESELLER! <br> </h1>
        <div class="firstScreen__sub_ttl" i18n>Y haz llegar lejos a tus clientes</div>
        <div class="wrapBtn">
          <button (click)="navigateToSection( 'pad_top' )" class="btn btnRed" mat-button i18n>Contrata aquí tu plan</button>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- END firstScreen -->

<!-- START -->
<section class="pad_top bg_blue3">
  <div class="container">
    <div class="sup_ttl2_white" i18n>NOSOTROS TE AYUDAMOS</div>
    <h2 class="ttl2_white mb" i18n>Planes Reseller</h2>

    <div class="wrapCardPrice col_4 pad_buttom">
        <div class="cardPrice b-dark" *ngFor="let plan of resellers">
            <div class="cardPrice__top">
              <div class="cardPrice_ttl"></div>
                <div class="cardPrice__ttl">{{ plan.name }}</div>
                <p class="cardPrice__sub_ttl">{{ plan.sub }}</p>
                <div *ngFor="let period of plan.periods; let i = index">
                  <p class="price price_ttal">
                      <span *ngIf="i > 0" class="price_pre">
                          <span class="currency valuta">{{ symbol }}</span>{{ plan.periods[i-1].price | currencyLocale }}</span>
                      <span class="price_actual">
                          <span class="currency valuta">{{ symbol }}</span>{{ period.price | currencyLocale }}</span>
                  </p>
                  <p class="price_sub" i18n *ngIf="i == 0">+ IVA. Valor mensual</p>
                  <p class="price_sub" i18n *ngIf="i > 0">+ IVA. Valor mensual al <br> contratar por {{ period.period }}</p>
                </div>
            </div>

            <button id="reseller-contratar-{{plan.name}}" class="btn btnRed" mat-button (click)="goToUrl( plan.url )" i18n>contratar</button>

            <ul class="card_list">
                <li *ngFor="let feature of plan.features">
                  <span [innerHTML]="feature.text"></span>
                  <img src="{{ feature.icon_url }}" alt="{{ feature.text }}">
                </li>
            </ul>
        </div>
    </div>
  </div>
</section>
<!-- END -->

<!-- START -->
<section class="pad bg_grey" [preRender]="false" (deferLoad)="showImage1 = true">
  <div class="container">
    <div class="sup_ttl2_black" i18n>NOSOTROS TE AYUDAMOS</div>
    <h2 class="ttl2_black mb" i18n>¿Pensaste que era todo?</h2>

    <div class="content">
      <div class="flex_wrapper flex_wrapper-r flex_wrapper-r2">
        <div class="wrap_img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img6_service.svg" alt="imagen de conversión">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>WHM y cPanel</h3>
          <p i18n>WHM o Web Host Manager (Gestor de Hospedaje en Web). Panel de administración y control que te permite crear, modificar y dar de baja cuentas independientes, además de administrar el sitio web de tu cliente.</p>
        </div>
      </div>

      <div class="flex_wrapper flex_wrapper-r flex_wrapper-r2">
        <div class="wrap_img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img7_service.svg" alt="imagen de tráfico">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>Ofrece seguridad</h3>
          <p i18n>WHM y cPanel incluyen la tecnología más actualizada y te ofrecen protección contra toda clase de virus, a su vez te da la capacidad de detectar los rootkits.</p>
        </div>
      </div>

      <div class="flex_wrapper flex_wrapper-r flex_wrapper-r2">
        <div class="wrap_img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img8_service.svg" alt="imagen de conversión">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>Construye tu marca como proveedor de web hosting</h3>
          <p i18n>Como es importante que tus clientes sepan que tú eres su proveedor de Web Hosting y no un Reseller, te proporcionamos un DNS anónimo que podrás personalizar de modo que aparezca tu nombre de dominio y no el de BlueHosting. Esto te permite ir construyendo tu propia marca mientras mantienes tu identidad ante tus clientes.</p>
        </div>
      </div>

      <div class="flex_wrapper flex_wrapper-r flex_wrapper-r2">
        <div class="wrap_img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img9_service.svg" alt="imagen de tráfico">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>Sin conocimientos técnicos ni de personal especializado</h3>
          <p i18n>Con Site Publisher puedes generar en minutos Sitios Web maravillosos y podrás proporcionar ese servicio a tus clientes o bien instalarles Site Publisher y que ellos mismos creen sus propios Sitios Web.</p>
        </div>
      </div>

      <div class="flex_wrapper flex_wrapper-r flex_wrapper-r2">
        <div class="wrap_img">
          <ng-container *ngIf="showImage1">
            <img  src="/assets/images/softaculous-desktop@1x.png"
                  srcset="/assets/images/softaculous-desktop@2x.png 2x" alt="imagen de conversión">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>Softaculous</h3>
          <p i18n>Instalador que trabaja de la mano con cPanel para poder instalar decenas de programas de código abierto y gratuitos para la administración de contenidos de Sitios Web. En cuestión de minutos instala scripts, asigna permisos a archivos, configura bases de datos y lo mejor, no requiere de conocimientos técnicos para utilizarlo.</p>
        </div>
      </div>

      <div class="flex_wrapper flex_wrapper-r flex_wrapper-r2">
        <div class="wrap_img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img11_service.svg" alt="imagen de tráfico">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>UpTime del 99.9%</h3>
          <p i18n>El UpTime es un valor que indica el porcentaje de tiempo que un servidor ha estado disponible ininterrumpidamente durante los últimos 30 días. Este valor nos da la idea acerca de la confiabilidad y estabilidad de los servidores.</p>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- END -->

<!-- START blockInfo -->
<section class="blockInfo bg_blue_dark_extra pad ng-star-inserted" [preRender]="false" (deferLoad)="showImage2 = true">
  <div class="container">
    <div class="blockInfo__wrap">
      <div class="blockInfo__txt w100 d-flex">
        <h2 class="ttl2" i18n>¡Gana dinero alojando a tus clientes en nuestro servidor!</h2>
        <p i18n>Adquirir un plan Reseller de BlueHosting es una excelente iniciativa de emprendimiento, incluso te puedes convertir en una gran empresa de alojamiento web a tiempo completo. ¡Captar a tus propios clientes de hosting es muy sencillo! A su vez, si eres un diseñador web puedes usar uno de nuestros planes para agregar valor a tus clientes actuales y futuros.</p>
      </div>
    </div>
  </div>
</section>
<!-- END blockInfo -->

<!-- START blockColumn -->
<section class="blockColumn">
  <div class="blockColumn__item bgBlueLight">
    <div class="blockColumn__item-inner flex_center_center">
      <ng-container *ngIf="showImage2">
        <img src="/assets/images/img_tools_block_column.svg" alt="Powerful tools">
      </ng-container>
    </div>
  </div>
  <div class="blockColumn__item bgMoreGrey">
    <div class="blockColumn__item-inner">
      <h3 class="ttl3 upper" i18n>¡Elige BlueHosting para comenzar tu propio negocio!</h3>
      <p i18n>WHM te permite crear cPanels para cada uno de tus clientes. WHMCS puede ayudarte a facilitar la facturación de tu negocio.</p>
      <h3 class="ttl3 upper" i18n>Herramientas poderosas</h3>
      <p i18n>Los planes de Reseller incluyen todo lo que necesitas para tener éxito, desde WHM hasta el software de facturación.</p>
      <h3 class="ttl3 upper" i18n>Flexibilidad</h3>
      <p i18n>Podrás controlar la asignación general de recursos para que se ajusten mejor a las necesidades de tus clientes.</p>
      <h3 class="ttl3 upper" i18n>Escalabilidad</h3>
      <p i18n>Siempre es gratis y fácil de actualizar; tu hosting crece a medida que tu negocio crece.</p>
    </div>
  </div>
</section>
<!-- END blockColumn -->

<!-- START -->
<section class="pad center">
  <div class="container">
    <h2 class="ttl2_black" i18n>Puedes ejecutar tu negocio <br> de la forma que desees</h2>
    <p class="center w90 m-auto" i18n>Un plan Reseller de BlueHosting te brinda el control total sobre cada uno de los aspectos de tu negocio de hosting, desde la asignación de recursos hasta los métodos de pago, además de los servicios adicionales que quieras proporcionarle a tus clientes (dominios, certificados SSL, etc.)</p>
    <button (click)="goToUrl( URL.CONTACT )" class="btn btnRedTransp" mat-button i18n>NOSOTROS TE AYUDAMOS</button>
  </div>
</section>
<!-- END -->

<!-- START blockInfo -->
<app-block-info-simple [info]="info"></app-block-info-simple>
<!-- START blockInfo -->

<!-- START -->
<section class="pad bg_grey remov_pad_bottom">
  <div class="container">
    <div class="sup_ttl2_black" i18n>Características de nuestro servicio</div>
    <h2 class="ttl2_black" i18n>Tu experiencia digital comienza aquí</h2>
    <p class="center" i18n>BlueHosting no solo protege, sino que acelera miles de sitios web y otras aplicaciones de internet</p>
    <!-- START wrapCard -->
    <div class="wrapCard">
      <div class="wrapCard__item bg_blue1">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img-card-item1.svg" alt="redimiento">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>RENDIMIENTO</h3>
          <p i18n>BlueHosting mejora el rendimiento de tu sitio con diversas tipologías de optimización.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue2">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img-card-item2.svg" alt="seguridad">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>SEGURIDAD</h3>
          <p i18n>Protege tu sitio y a quienes lo visitan de todo tipo de engaños on-line. BlueHosting utiliza WAF y SSL entre otras herramientas para defender tu sitio</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue3">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img-card-item3.svg" alt="confianza">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>CONFIANZA</h3>
          <p i18n>Administramos un servicio DNS con más de un 30% de cuota de mercado.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue4">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img-card-item4.svg" alt="visión">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>PREVENCIÓN</h3>
          <p i18n>Nuestra red es capaz de identificar visitantes y comportamientos de bots, que tecnologías de análisis convencionales
            no logran reconocer.</p>
        </div>
      </div>
    </div>
    <!-- END wrapCard -->
  </div>
</section>
<!-- END  -->

<!-- START block_txt_column -->
<section class="block_txt_column pad">
  <div class="container">
    <h2 class="ttl2_black" i18n>Preguntas frecuentes</h2>
    <div class="txt_column">

      <div class="txt_column__column">
        <h3 class="ttl3" i18n>¿Cómo inicio sesión en WHM?</h3>
        <p i18n>Al comenzar la utilización del servicio, recibirás un mail de bienvenida con tus credenciales de acceso a WHM.  A continuación, en un navegador, deberás escribir el nombre de tu dominio seguido de /whm (tudominio.cl/whm). Esto te redirigirá a un formulario de acceso en donde podrás iniciar sesión con tus credenciales de acceso: usuario y contraseña.</p>
        <h3 class="ttl3" i18n>¿Cómo personalizo mis DNS?</h3>
        <p i18n>Con nuestros planes Reseller, podrás otorgarle a tus clientes un nivel de exclusividad superior, ofreciéndoles la personalización de los DNS, los cuales podrás solicitar a nuestro equipo de soporte, contratándolos directamente desde tu área de cliente.</p>
        <h3 class="ttl3" i18n>¿Puedo conectarme vía SSH a mi cuenta Reseller?</h3>
        <p i18n>La seguridad es lo más importante para nosotros, por lo que no habilitamos puerto de conexión SSH. Si quieres administrar el servicio a través de esta conexión, te recomendamos evaluar nuestros planes de Cloud Server. </p>
      </div>

      <div class="txt_column__column">
        <h3 class="ttl3" i18n>¿Qué pasa si no estoy conforme con mi servicio?</h3>
        <p i18n>Te entregamos todas las herramientas y un completo equipo de soporte para asegurarte que tu servicio funcione como lo esperas. Si tienes requerimientos especiales, solicitudes o comentarios sobre el servicio, no dudes en contactarnos. Y si aún no estás satisfecho, te garantizamos la devolución de tu dinero.</p>
        <h3 class="ttl3" i18n>¿Mis clientes pueden solicitar soporte a BlueHosting?</h3>
        <p i18n>Solo entregamos soporte a los titulares de las cuenta Reseller, es decir, nuestros clientes. Tampoco tomamos acciones sobre las cuentas alojadas en estos servicios y solo en el caso de cometer algún abuso, suspendemos la cuenta de forma inmediata e  informamos al titular para corregir el problema. </p>
      </div>

    </div>
  </div>
</section>
<!-- END block_txt_column -->

<!-- START block_logo -->
<app-block-logos></app-block-logos>
<!-- END block_logo -->

<app-footer></app-footer>
