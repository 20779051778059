import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { SnackBarService } from '../../../services/snack.service';
import { map } from 'rxjs/operators';

interface IInfoAccept {
    suptext: string;
    title: string;
    subtext?: string;
    plans?: Array<{
        title: string;
        items: Array<{ name: string; value: string; }>
    }>;
}

export enum HostingManagementType {
    cPanel = 10,
    clientArea = 20,
    dns = 30,
    nic = 40,
    configEmail = 50,
    webmail = 60,
    payment = 70,
}

@Component({
    selector: 'app-hosting-management-dialog',
    templateUrl: './hosting-management-dialog.component.html',
    styleUrls: ['./hosting-management-dialog.component.scss']
})
export class HostingManagementDialogComponent implements OnInit {

    public form: FormGroup;
    private body = new URLSearchParams();

    private EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    private DOMAIN_REGEX = /^[aA-zZ0-9-]+[.]+[aA-zZ]{2,4}$|^[w]{3}[.]{1}[aA-zZ0-9-]+[.]+[aA-zZ]{2,4}$|[A-Za-z0-9]+[.]+[A-Za-z0-9]+[.]+[A-Za-z0-9]{2,4}/;

    isDataSent = false;

    subtitleForm: string = 'Para recuperar tus datos de acceso cPanel';

    infoAccept: IInfoAccept = {
        suptext: 'Datos de acceso cPanel',
        title: 'Datos enviados',
        subtext: 'Hemos enviado tus datos de acceso cPanel <b>a tu correo electrónico.</b>'
    };

    responseDNS: Array<any> = [
        {
            "domain": "hosting2rut.cl",
            "DNS1": "ns1.dnshosty.net",
            "DNS1_IP": "186.64.112.75",
            "DNS2": "ns2.dnshosty.net",
            "DNS2_IP": "45.79.214.155",
            "DNS3": "ns3.dnshosty.net",
            "DNS3_IP": "144.217.14.211",
            "plan": "Plan Hosting Mediano"
        }
    ]

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: number,
        private readonly formBuilder: FormBuilder,
        private readonly apiService: ApiService,
        private readonly dialog: MatDialog,
        private readonly snackbarService: SnackBarService
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(this.EMAIL_REGEX)]],
        });

        this.body.append('responsetype', 'json');
        if (this.data) {
            switch (this.data) {
                case HostingManagementType.cPanel: {
                    this.body.append('option', 'accesscpanel');
                    break;
                }
                case HostingManagementType.clientArea: {
                    this.body.append('option', 'accesswhmcs');
                    break;
                }
                case HostingManagementType.dns: {
                    this.body.append('option', 'getdns');
                    break;
                }
            }

            this.updateInfo();

            if (this.data !== HostingManagementType.clientArea) {
                this.form.addControl(
                    'domain',
                    new FormControl('', [Validators.required, Validators.pattern(this.DOMAIN_REGEX)]),
                );
            }
        }
    }

    send(): void {
        if (this.form.invalid) {
            return;
        }

        const values = this.form.value;
        const obKeys = Object.keys(values);
        obKeys.forEach(el => {
            this.body.append(el, values[el]);
        });
        this.apiService.recoveryAccess(this.body)
            .pipe(
                map((x) => {
                    if (x) {
                        let response = [];
                        x.forEach((plan: any) => {
                            response.push({
                                title: plan.plan,
                                items: [
                                    {
                                        name: plan.DNS1,
                                        value: plan.DNS1_IP,
                                    },
                                    {
                                        name: plan.DNS2,
                                        value: plan.DNS2_IP,
                                    },
                                    {
                                        name: plan.DNS3,
                                        value: plan.DNS3_IP,
                                    },
                                ]
                            })
                        })
                        return response;
                    } else {
                        return x;
                    }
                })
            )
            .subscribe({
                next: (res) => {
                    this.responseDNS = res;
                    this.updateInfo();
                    this.isDataSent = this.form.valid;
                },
                error: (err) => {
                    console.error(err);
                    this.snackbarService.showMessage(err, false, 'OK!');
                    this.dialog.closeAll();
                },
            });
    }

    updateInfo(): void {
        switch (this.data) {
            case HostingManagementType.clientArea:
                this.infoAccept = {
                    suptext: 'Para recuperar tus datos de acceso a su <b>área de cliente</b>',
                    title: 'Datos enviados',
                    subtext: 'Hemos enviado tus datos de acceso de Área de cliente <b>a tu correo electrónico.</b>',
                };
                this.subtitleForm = 'Para recuperar tus datos de acceso a su <b>área de cliente</b>';
                break;
            case HostingManagementType.dns:
                this.infoAccept = {
                    suptext: 'Estos son los datos DNS para el dominio:',
                    title: this.form.get('domain')?.value,
                    subtext: null,
                    plans: this.responseDNS,
                };
                this.subtitleForm = 'Para obtener tus datos DNS.';
                break;
            default:
                this.infoAccept = {
                    suptext: 'Datos de acceso cPanel',
                    title: 'Datos enviados',
                    subtext: 'Hemos enviado tus datos de acceso cPanel <b>a tu correo electrónico.</b>'
                };
                this.subtitleForm = 'Para recuperar tus datos de acceso cPanel';
                break;
        }
    }

}
