import { Component, Input } from '@angular/core';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-autoatencion-section',
    templateUrl: './autoatencion-section.component.html',
    styleUrls: ['./autoatencion-section.component.scss']
})
export class AutoatencionSectionComponent {
    @Input() public loadImages: boolean;

    track(event: Event){
        traceButton2(event, 'navigate');
    }

}
