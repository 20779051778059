import { Component, OnInit, Inject, Output } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { LocaleService } from '../services/locale.service';
import { Meta } from '../../../../common/Meta';
import { URL } from '../../environments/environment';

@Component({
    selector: 'app-contact-wrapper',
    templateUrl: './contact-wrapper.component.html',
})
export class ContactWrapperComponent implements OnInit {
    public URL = URL;
    playVideo = false;
    locale;

    @Output() TLD: string;
    @Output() Meta: Meta;
    @Output() MessageFrom = 'Bluehosting';

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private localeService: LocaleService,
    ) {
        this.locale = this.localeService.locale;

        this.Meta = new Meta(
            'Contactar con Ventas | Bluehosting',
            'Completa tus datos y en pocos segundos uno de nuestros ejecutivos de venta se comunicará contigo. Descubre todo lo que Bluehosting tiene para ofrecer.',
            'Bluehosting',
            this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og_haulmer.png',
            'png',
            644,
            374,
            'Contacto con ventas, Bluehosting'
        );

        this.Meta.addKeyword('contactar ventas');
        this.Meta.addKeyword('ventas');
        this.Meta.addKeyword('atención al cliente');
        this.Meta.addKeyword('centro de soporte');
    }

    ngOnInit() {
        this.localeService.getMessage().subscribe(contact => {
            this.TLD = (contact.country_code === 'me' ? 'mx' : contact.country_code);
        });
    }
}
