<!-- START firstScreen -->
<div [ngClass]="{'pymes': true}">
  <section class="firstScreen">
    <picture>
      <source srcset="/assets/images/first-screen/first_img_screen_pymes@1x.avif,
        /assets/images/first-screen/first_img_screen_pymes@2x.avif 2x" type="image/avif">
      <source srcset="/assets/images/first-screen/first_img_screen_pymes@1x.webp,
          /assets/images/first-screen/first_img_screen_pymes@2x.webp 2x" type="image/webp">
      <img src="/assets/images/first-screen/first_img_screen_pymes@1x.jpg" srcset="/assets/images/first-screen/first_img_screen_pymes@2x.jpg 2x" alt="First Screen Image">
    </picture>
    <div class="container">
      <div class="firstScreen__inner">
        <h1 class="firstScreen__ttl" i18n>¡EL HOSTING PARA NEGOCIOS <br>MÁS RÁPIDO!</h1>
        <div class="firstScreen__sub_ttl" i18n>Más potencia y mayor velocidad <br> desde
          <span>{{ symbol }}</span>{{ hosting[1].plans[0].periods[0].price | currencyLocale}} anuales
        </div>
        <div class="wrapBtn">
          <button (click)="navigateToSection( 'pad' )" class="btn btnRed" mat-button i18n>Contrata aquí tu plan</button>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- END firstScreen -->

<!-- START -->
<section class="pad bg_blue_grad pad_from_menu">
  <div class="container">
    <div class="sup_ttl2_white" i18n>Planes</div>
    <h2 class="ttl2_white mb" i18n>Menor precio, mayor rapidez.</h2>

    <div class="wrapCardPrice pad_buttom">
      <div class="cardPrice" *ngFor="let plan of hosting[1].plans">
        <div class="cardPrice__top">
          <div class="cardPrice_ttl"></div>
          <div class="cardPrice__ttl">{{ plan.name }}</div>
          <p class="cardPrice__sub_ttl">{{ plan.sub }}</p>
          <div *ngFor="let period of plan.periods; let i = index">
            <p class="price price_ttal">
              <span *ngIf="i > 0" class="price_pre">
                <span class="currency valuta">{{ symbol }}</span>{{ plan.periods[i-1].price | currencyLocale }}</span>
              <span class="price_actual">
                <span class="currency valuta">{{ symbol }}</span>{{ period.price | currencyLocale }}</span>
            </p>
            <p class="price_sub" i18n *ngIf="i == 0">Anual + IVA</p>
            <p class="price_sub" i18n *ngIf="i > 0">Anual + IVA, al contratar por {{ period.period }}</p>
          </div>
        </div>

        <button id="pymes-contratar-{{plan.name}}" class="btn btnRed" mat-button (click)="goToUrl( plan.url )" i18n>contratar</button>

        <ul class="card_list">
          <li *ngFor="let feature of plan.features">
            <span [innerHTML]="feature.text"></span>
            <img src="{{ feature.icon_url }}" alt="{{ feature.text }}">
          </li>
        </ul>
      </div>
    </div>

  </div>

  <!-- START tapeBlock -->
  <app-block-domain [locale]="locale"></app-block-domain>
  <!-- END tapeBlock -->
</section>
<!-- END -->

<!-- START menuPlans-->
<app-menu-plans *ngIf="isShowPlans"></app-menu-plans>
<!-- END menuPlans-->

<!-- START blockList -->
<app-block-list [lists]="lists" [preRender]="false" (deferLoad)="showImage1 = true; addVideoControlsForMobyle()"></app-block-list>
<!-- END blockList -->

<!-- START videoCompany -->
<app-video-company [preRender]="false" (deferLoad)="showImage2 = true" [showImage]="showImage1"></app-video-company>
<!-- END videoCompany -->

<!-- START -->
<section class="pad" [preRender]="false" (deferLoad)="showSectionSlider = true">
  <div class="container">
    <div class="sup_ttl2_black" i18n>El hosting para tu Empresa</div>
    <h2 class="ttl2_black mb" i18n>¿Por qué un plan Negocios?</h2>

    <div class="content">
      <div class="flex_wrapper">
        <div class="wrap_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img1_service.svg" alt="imagen de conversión">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>Convierte cada visita en un cliente</h3>
          <h4 class="ttl4" i18n>Comercio electrónico</h4>
          <p i18n>No permitas que tus visitas se aburran de esperar que tu sitio cargue y finalmente solo se lleven un
            ingrato y largo momento de espera. Con este plan, la velocidad de la luz será tu velocidad de carga y cada
            visita será un cliente más para tu lista.</p>
        </div>
      </div>

      <div class="flex_wrapper">
        <div class="wrap_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img2_service.svg" alt="imagen de tráfico">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>Sin tacos</h3>
          <h4 class="ttl4" i18n>Alto tráfico</h4>
          <p i18n>¿Muchas visitas y tu sitio colapsa? Lo ideal es que nuestro sitio reciba un gran flujo de visitas y
            que no se inunde en este mar de clientes. Nuestro plan tiene los recursos y el respaldo para que administrar
            un contingente de visitas sea una grata noticia, no un problema.</p>
        </div>
      </div>

      <div class="flex_wrapper">
        <div class="wrap_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img3_service.svg" alt="imagen de recursos">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>Potencia individual</h3>
          <h4 class="ttl4" i18n>Altos recursos</h4>
          <p i18n>No compartas ningún recurso (CPU o RAM). Al tener recursos de uso exclusivo y una potencia individual,
            tu hosting podrá administrar de manera óptima una multiplicidad de elementos tales como fotos, videos y/o
            descargas, que solo enriquecerán tu sitio y la experiencia de cada visitante.</p>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- END -->

<!-- START -->
<section class="pad_top bg_grey">
  <div class="container">
    <div class="sup_ttl2_black" i18n>El Hosting para tu Negocio</div>
    <h2 class="ttl2_black mb" i18n>Impulsamos tus sitios a otro nivel</h2>

    <div class="wrapCollumns">
      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img1-column-1.svg" alt="Más rapidez">
          </ng-container>
        </div>
        <h3 class="ttl3 upper ttl_height" i18n>La Velocidad de la luz, es tu velocidad</h3>
        <p i18n>Acelerador de contenido estático que brinda una mayor velocidad de carga a tu página en comparación con
          hostings que albergan más de un sitio web.</p>
      </div>

      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img3-column.svg" alt="Más facilidad">
          </ng-container>
        </div>
        <h3 class="ttl3 upper ttl_height" i18n>Facilidad e intuitividad</h3>
        <p i18n>Tendrás un acceso completo a través de un panel de control de fácil uso y altamente intuitivo que no
          requiere de conocimientos técnicos.</p>
      </div>

      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img2-column.svg" alt="Más seguridad">
          </ng-container>
        </div>
        <h3 class="ttl3 upper ttl_height" i18n>Seguridad irrompible</h3>
        <p i18n>Obtén un Certificado SSL Let’s Encrypt para hacer más segura la experiencia de navegación de tus
          clientes, además de los múltiples niveles de seguridad del servidor.</p>
      </div>
    </div>

  </div>
</section>
<!-- END -->

<!-- START blockClients -->
<ng-container *ngIf="showSectionSlider">
  <app-block-clients></app-block-clients>
</ng-container>
<!-- END blockClients -->

<!-- SRTART  -->
<section class="pad_top" [preRender]="false" (deferLoad)="showImage3 = true">
  <div class="container">
    <h2 class="ttl2_black" i18n>Elige tu plan</h2>
    <h3 class="ttl3 center" i18n>Selecciona el plan óptimo para tus necesidades comerciales</h3>
  </div>
  <app-tab-plans [hosting]="hosting"></app-tab-plans>
</section>
<!-- END  -->

<!-- START blockInfo -->
<app-block-info [info]="info"></app-block-info>
<!-- END blockInfo -->

<!-- START -->
<section class="pad bg_grey">
  <div class="container">
    <div class="sup_ttl2_black" i18n>Características de nuestro servicio</div>
    <h2 class="ttl2_black" i18n>Tu experiencia digital comienza aquí</h2>
    <p class="center" i18n>BlueHosting no solo protege, sino que acelera miles de sitios web y otras aplicaciones de
      internet</p>
    <!-- START wrapCard -->
    <div class="wrapCard">
      <div class="wrapCard__item bg_blue1">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-card-item1.svg" alt="redimiento">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>RENDIMIENTO</h3>
          <p i18n>BlueHosting mejora el rendimiento de tu sitio con diversas tipologías de optimización.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue2">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-card-item2.svg" alt="seguridad">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>SEGURIDAD</h3>
          <p i18n>Protege tu sitio y a quienes lo visitan de todo tipo de engaños on-line. BlueHosting utiliza WAF y
            SSL entre otras herramientas para defender tu sitio.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue3">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-card-item3.svg" alt="confianza">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>CONFIANZA</h3>
          <p i18n>Administramos un servicio DNS con más de un 30% de cuota de mercado.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue4">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-card-item4.svg" alt="visión">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>PREVENCIÓN</h3>
          <p i18n>Nuestra red es capaz de identificar visitantes y comportamientos de bots que tecnologías de análisis
            convencionales no logran reconocer.</p>
        </div>
      </div>
    </div>
    <!-- END wrapCard -->
  </div>
</section>
<!-- END  -->

<!-- START block_txt_column -->
<section class="block_txt_column pad" [preRender]="false" (deferLoad)="showFooter = true">
  <div class="container">
    <h2 class="ttl2_black" i18n>Preguntas frecuentes</h2>
    <div class="txt_column">

      <div class="txt_column__column">
        <h3 class="ttl3" i18n>¿Cómo inicio sesión en cPanel?</h3>
        <p i18n>Al comenzar la utilización del servicio, recibirás un mail de bienvenida con tus credenciales de acceso
          a cPanel. A continuación, en un navegador deberás escribir el nombre de tu dominio seguido de /cpanel. Esto te
          redirigirá a un formulario de acceso en donde podrás iniciar sesión con tus credenciales de acceso.</p>
        <h3 class="ttl3" i18n>¿Puedo instalar Wordpress en mi plan NEGOCIOS?</h3>
        <p i18n>Sí, nuestros planes NEGOCIOS están diseñados para obtener el mejor rendimiento de Wordpress. Puedes
          instalarlo en tu cuenta utilizando las herramientas de "Softaculous", la cual ponemos a tu disposición para
          que puedas instalar de forma sencilla más de 400 apps y scripts.</p>
        <h3 class="ttl3" i18n>¿Cómo configuro mis DNS?</h3>
        <p i18n>Configurar los registros DNS es necesario para poder enlazar tu dominio con nuestro servidor; de esta
          manera, al escribir el nombre de dominio en un navegador, este se traducirá en una dirección, la cual
          pertenece a nuestro servidor. Los registros DNS para los planes WebHosting negocio son los siguientes:
        </p>
        <ol>
          <li>ns1.pymedns.net</li>
          <li>ns2.pymedns.net</li>
          <li>ns3.pymedns.net</li>
        </ol>
      </div>

      <div class="txt_column__column">
        <h3 class="ttl3" i18n>¿Puedo integrar Transbank en mi plan?</h3>
        <p i18n>Sí, nuestros planes están pensados para integrar pagos asociados a Transbank. Si quieres comenzar tu
          tienda on-line, nuestros planes NEGOCIOS son la mejor opción.</p>
        <h3 class="ttl3" i18n>¿Qué es un Inodo?</h3>
        <p i18n>Un Inodo representa un archivo. De esta manera, es importante resaltar que nuestra gama de planes
          NEGOCIOS soportan un total de 400.000 inodos sin importar su peso.</p>
        <h3 class="ttl3" i18n>¿Cómo recupero la contraseña de cPanel?</h3>
        <p i18n>Es muy fácil, dentro de la misma ventana de login, encontrarás un link que dice “restablecer contraseña”
          este link enviará un mail con una nueva contraseña al mail asociado, la que deberás ingresar en el login. De
          todas formas, siempre podrás contactar nuestro servicio de soporte para que te ayuden.</p>

        <h3 class="ttl3" i18n>¿Puedo conectarme a mi base de datos de manera remota?</h3>
        <p i18n>No, en nuestra gama de planes Negocios no es posible conectarse de manera remota a tu base de datos. Si
          requieres esta característica revisa nuestros planes de la gama Empresa.</p>
      </div>
    </div>
  </div>
</section>
<!-- END block_txt_column -->

<ng-container *ngIf="showFooter">
  <app-block-logos></app-block-logos>

  <app-footer></app-footer>
</ng-container>