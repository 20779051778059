<!-- START Footer -->
<footer class="bg_grey">
    <div class="container">
        <div class="footer_top">
            <div class="wrap_logo">
                <a id="bh-{{url}}-footer-logo" [routerLink]="['/']" (click)="scrollTop(); track($event)">
                    <img src="/assets/images/logo-bluehosting-footer.svg" 
                        width="269px"
                        height="48px"
                        alt="logo bluehosting">
                    <img src="/assets/images/giphy-haulmer-footer-slogan.gif" 
                        width="210px"
                        height="24px"
                        alt="slogan bluehosting">
                </a>
            </div>
            <div class="wrap_footer_menu">
                <h4 class="footer_menu__ttl"><a id="bh-{{url}}-footer-emprende" [routerLink]="['/web-hosting/.']" [routerLinkActive]="['active']" (click)="scrollTop(); track($event)">Emprendedores</a></h4>
                <h4 class="footer_menu__ttl"><a id="bh-{{url}}-footer-pymes" [routerLink]="['/web-hosting-negocio/.']" [routerLinkActive]="['active']" (click)="scrollTop(); track($event)">Negocios</a></h4>
                <h4 class="footer_menu__ttl"><a id="bh-{{url}}-footer-wordpress" [routerLink]="['/hosting-negocio-wordpress/.']" [routerLinkActive]="['active']" (click)="scrollTop(); track($event)">Wordpress</a></h4>
                <h4 class="footer_menu__ttl"><a id="bh-{{url}}-footer-empresa" [routerLink]="['/web-hosting-empresa/.']" [routerLinkActive]="['active']" (click)="scrollTop(); track($event)">Empresas</a></h4>
                <ul class="footer_menu">
                    <li>
                        <a id="bh-{{url}}-footer-webhosting" [routerLink]="['/web-hosting/.']" [routerLinkActive]="['active']" (click)="scrollTop(); track($event)">Web Hosting</a>
                    </li>
                    <li>
                        <a id="bh-{{url}}-footer-dominios" [routerLink]="['/dominios/.']" [routerLinkActive]="['active']" (click)="scrollTop(); track($event)">Dominios</a>
                    </li>
                    <li>
                        <a id="bh-{{url}}-footer-vps" [routerLink]="['/vps/.']" [routerLinkActive]="['active']" (click)="scrollTop(); track($event)">Cloud Server</a>
                    </li>
                    <li>
                        <a id="bh-{{url}}-footer-ssl" [routerLink]="['/certificados-ssl/.']" [routerLinkActive]="['active']" (click)="scrollTop(); track($event)">Certificado SSL</a>
                    </li>
                    <li>
                        <a id="bh-{{url}}-footer-reseller" [routerLink]="['/hosting-reseller/.']" [routerLinkActive]="['active']" (click)="scrollTop(); track($event)">Programa Reseller</a>
                    </li>
                    <li *ngIf="isShowItemMenuFooter">
                        <a id="bh-{{url}}-footer-docs" href="https://help.haulmer.com/hc/web-hosting/" target="_blank" rel="noreferrer" (click)="track($event)">Docs</a>
                    </li>
                    <li *ngIf="isShowItemMenuFooter">
                        <a id="bh-{{url}}-footer-blog" href="https://www.bluehosting.cl/articulos/" target="_blank" rel="noreferrer" (click)="track($event)">Blog Bluehosting</a>
                    </li>
                    <li *ngIf="isShowItemMenuFooter">
                        <a id="bh-{{url}}-footer-formas" [routerLink]="['/formas-de-pago/.']" target="_blank" rel="noreferrer" (click)="track($event)">Formas de Pago</a>
                    </li>
                    <li *ngIf="isShowItemMenuFooter">
                        <a id="bh-{{url}}-footer-status" href="https://status.bluehosting.host/" target="_blank" (click)="track($event)">Status Uptime</a>
                    </li>
                </ul>
            </div>
            <div class="wrap_footer_menu">
                <ul class="footer_menu" *ngIf="!isShowItemMenuFooter">
                    <li>
                        <a id="bh-{{url}}-footer-docs" href="https://help.haulmer.com/hc/web-hosting/" target="_blank" rel="noreferrer" (click)="track($event)">Docs</a>
                    </li>
                    <li>
                        <a id="bh-{{url}}-footer-blog" href="https://www.bluehosting.cl/articulos/" target="_blank" rel="noreferrer" (click)="track($event)">Blog Bluehosting</a>
                    </li>
                    <li>
                        <a id="bh-{{url}}-footer-formas" [routerLink]="['/formas-de-pago/.']" target="_blank" rel="noreferrer" (click)="track($event)">Formas de Pago</a>
                    </li>
                    <li>
                        <a id="bh-{{url}}-footer-status" href="https://status.bluehosting.host/" target="_blank" (click)="track($event)">Status Uptime</a>
                    </li>
                </ul>
                <h4 class="footer_menu__ttl">Haulmer</h4>
                <ul class="footer_menu">
                    <li>
                        <a id="bh-{{url}}-f-haulmer-empresa" [href]="URL.HAULMER_WEBSITE" target="_blank" (click)="track($event)">Empresa</a>
                    </li>
                    <li>
                        <a id="bh-{{url}}-f-haulmer-trabaja" [href]="URL.HAULMER_WORK_WITH_US" (click)="track($event)">Trabaja con nosotros</a>
                    </li>
                    <li>
                        <a id="bh-{{url}}-f-haulmer-blog" [href]="URL.HAULMER_BLOG" target="_blank" (click)="track($event)">Blog</a>
                    </li>
                    <li>
                        <a id="bh-{{url}}-f-haulmer-noticias" [href]="URL.HAULMER_NEWS" target="_blank" (click)="track($event)">Noticias</a>
                    </li>
                </ul>
            </div>
            <div class="footer_contacts">
                <div class="soc">
                    <a id="bh-{{url}}-footer-fb" class="fb" [href]="URL.BLUEHOSTING_FACEBOOK" target="_blank" rel="noreferrer" (click)="track($event)">fb</a>
                    <a id="bh-{{url}}-footer-tw" class="tw" [href]="URL.BLUEHOSTING_TWITTER" target="_blank" rel="noreferrer" (click)="track($event)">tw</a>
                    <a id="bh-{{url}}-footer-yt" class="youtube" [href]="URL.BLUEHOSTING_YOUTUBE" target="_blank" rel="noreferrer" (click)="track($event)">youtube</a>
                    <a id="bh-{{url}}-footer-li" class="linkedin" [href]="URL.BLUEHOSTING_LINKEDIN" target="_blank" rel="noreferrer" (click)="track($event)">linkedin</a>
                </div>
                <p class="footer_contacts__ttl">¡Contáctanos!</p>
                <a id="bh-{{url}}-footer-numero" href="tel:{{ contact.main_phone_href }}" class="tel" (click)="track($event)">{{ contact.main_phone }}</a>
                <div class="box_country_list">
                    <span class="flag">
                        <img [src]="contact.flag_url" 
                            width="28px"
                            height="20px"
                            [alt]="contact.country">
                    </span>

                    <mat-form-field>
                        <mat-select [(value)]="contact">
                            <mat-option *ngFor="let contact of contactList" [value]="contact">
                                <span class="flag"><img src="{{contact.flag_url}}" alt="Bandera país"></span>
                                <span class="city">{{contact.city}}, </span>
                                <span class="country">{{contact.country}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="copiright">
            <img src="/assets/images/img-copiright.svg" 
                width="87px"
                height="14px"
                alt="Haulmer">
            <a id="bh-{{url}}-footer-terminos" target="_blank" [href]="URL.BLUEHOSTING_TERMS_CONDITIONS" (click)="scrollTop(); track($event,'phone')">Términos y condiciones</a>
            <!--
            <a (click)="scrollTop()">Política de privacidad</a>
            -->
            <span>Copyright Haulmer, Inc. © 2023. Todos los derechos reservados</span>
        </div>
    </div>

</footer>
<!-- END Footer -->