<app-direct-payment-first-screen
  (loading)="getLoadingStatus($event)"
  (showNoResult)="getNoResultVisibility($event)"
  (complete)="getCompleteStatus($event)"
  >
</app-direct-payment-first-screen>

<app-search-result [data]="dataResult" *ngIf="isComplete && !isLoading"></app-search-result>

<app-no-search-result [data]="dataNoResult" *ngIf="isNoResult && !isLoading"></app-no-search-result>

<app-send-ticket *ngIf="isComplete && !isLoading"></app-send-ticket>

<app-footer></app-footer>
