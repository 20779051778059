<div class="chartSecurity">
  <div class="chartSecurity__info">
    <div class="chartSecurity__info-value">0</div>
    Análisis total histórico
  </div>
  <canvas id="canvasSecurity" class="canvasSecurity"></canvas>
</div>
<div class="chartSecurity__labels">
  <span>
    Archivos desinfectados <br>
    <small class="center">{{pseudoOuterValue | number:'1.0-0'}} ({{outerValue_percent | number:'1.0-2'}}%)</small>
  </span>
  <span>
    Ataques bloqueados <br>
    <small class="center">{{pseudoInnerValue | number:'1.0-0'}} ({{innerValue_percent | number:'1.0-2'}}%)</small>
  </span>
</div>