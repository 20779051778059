import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { URL } from '../../environments/environment';
import { LocaleService } from '../services/locale.service';

@Component({
    selector: 'app-pago',
    templateUrl: './pago.component.html'
})
export class PagoComponent implements OnInit {

    URL = URL;
    localeCode;
    items;

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private localeService: LocaleService,
    ) { 
        this.localeCode = this.localeService.locale;
        this.items = this.localeService.getContent().payment;
    }

    ngOnInit() {
        this.removeBlueBackground();
        this.removeBgPreviousPage();
        this.addClassPagoPage();
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    // remove background pf the previous pages
    removeBgPreviousPage() {
        const body = this._document.getElementsByTagName('body')[0] as HTMLElement;

        if (body.classList.contains('pymes')) {
            body.classList.remove('pymes');
        }
        if (body.classList.contains('seguridad')) {
            body.classList.remove('seguridad');
        }
        if (body.classList.contains('certificado')) {
            body.classList.remove('certificado');
        }
        if (body.classList.contains('dominio')) {
            body.classList.remove('dominio');
        }
        if (body.classList.contains('wordpress')) {
            body.classList.remove('wordpress');
        }
        if (body.classList.contains('empresa')) {
            body.classList.remove('empresa');
        }
        if (body.classList.contains('reseller')) {
            body.classList.remove('reseller');
        }
        if (body.classList.contains('condiciones')) {
            body.classList.remove('condiciones');
        }
        if (body.classList.contains('cloud')) {
            body.classList.remove('cloud');
        }
    }

    removeBlueBackground() {
        const headerEl = this._document.getElementsByClassName('wrapHeader')[0] as HTMLElement;
        headerEl.classList.remove('bg_blue');
    }

    addClassPagoPage() {
        const body = this._document.getElementsByTagName('body')[0] as HTMLElement;
        body.classList.add('pago');
    }

    gotoPlan(url: string): void {
        window.open(url, '_blank');
    }
}
