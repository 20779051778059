import { IDataStrip } from './../../../../../common/interfaces/promotions.interface';
import { Component } from "@angular/core";
import { AppType } from '../../../../../common/assets/website-data';

@Component({
    selector: 'app-strip-wrapper',
    templateUrl: './strip.component.html'
})

export class StripWrapperComponent {
    dataStrip: IDataStrip = {
        text: `
    <div>
        <span>Cyber Bluehosting
            <img class="strip-img" src="/assets/images/icons/icon-star-eyes.svg" alt="icon star eyes">
            25%OFF en planes&nbsp;</span>
    </div>
    <div>
        <span>Power y Full
            <img class="strip-img" src="/assets/images/icons/icon-fire.svg" alt="icon fire">
            Usa el código: CYBERDAY
        </span>
    </div>`,
        style: 'bold'
    };

    get appType() {
        return AppType.bluehosting
    }
}