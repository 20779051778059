<ng-container [formGroup]="form" *ngIf="!isDataSent">
  <button mat-icon-button mat-dialog-close>
    <svg-icon-sprite [src]="'icon-sprite-close'" width="32px"></svg-icon-sprite>
  </button>
  
  <h2 class="dialog__title" i18n>Ingresa tus datos</h2>
  
  <div class="dialog__subtitle" *ngIf="subtitleForm" [innerHtml]="subtitleForm" i18n></div>

  <mat-form-field
    appearance="fill"
    [class.margin-bottom]="
      form.get('email')?.hasError('pattern') && form.get('email').touched ||
      form.get('email')?.hasError('required') && form.get('email').touched">
    <mat-label i18n>Email</mat-label>
    <input matInput formControlName="email">
    <mat-error i18n>Por favor ingrese un email válido</mat-error>
  </mat-form-field>
  
  <ng-container *ngIf="data !== 20">
    <mat-form-field
      appearance="fill"
      [class.margin-bottom]="
        form.get('domain')?.hasError('pattern') && form.get('domain').touched ||
        form.get('domain')?.hasError('required') && form.get('domain').touched">
      <mat-label i18n>Dominio</mat-label>
      <input matInput formControlName="domain">
      <mat-error i18n>El dominio ingresado no se encuentra registrado</mat-error>
    </mat-form-field>
  </ng-container>
  
  <button mat-button class="btn btnRed" [disabled]="form.invalid" (click)="send()" i18n>ENVIAR</button>
</ng-container>

<ng-container *ngIf="isDataSent">
  <svg-icon-sprite
    [src]="'icon-sprite-check-circle'"
    [width]="'32px'"
    class="icon">
  </svg-icon-sprite>

  <div class="dialog__suptitle" *ngIf="infoAccept?.suptext" [innerHtml]="infoAccept.suptext" i18n></div>
  
  <h2 class="dialog__title" i18n>{{infoAccept.title}}</h2>
  
  <div class="dialog__subtitle" *ngIf="infoAccept?.subtext" [innerHtml]="infoAccept.subtext" i18n></div>

  <ng-container *ngIf="infoAccept.plans">
    <ng-container *ngFor="let plan of infoAccept.plans">
      <div class="plan__title" i18n>{{plan.title}}</div>
      <ul class="plan__list">
        <li *ngFor="let item of plan.items">
          <a [href]="'http://' + item.name" target="_blank" i18n>{{item.name}} {{item.value}}</a>
        </li>
      </ul>
    </ng-container>
  </ng-container>
  
  <button mat-button class="btn btnRed" mat-dialog-close i18n>ACEPTAR</button>
</ng-container>
