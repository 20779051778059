import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { LocaleService } from '../services/locale.service';
import { URL } from '../../environments/environment';
import { MetaService } from '@ngx-meta/core';
import { Location, DOCUMENT } from '@angular/common';
import { ScrollToClass } from '../../../../common/scrollTo.class';
import { InfoSimple, ListExtra } from '../shared/interfaces/block';

@Component({
    selector: 'app-cloud-with-backup',
    templateUrl: './cloud-with-backup.component.html'
})
export class CloudWithBackupComponent extends ScrollToClass implements OnInit {
    showImage1: boolean = false;    //Lazy load
    showImage2: boolean = false;
    URL = URL;
    public tablePlanesTitle: string[] = ['memory', 'vcpus', 'ssd', 'traffic', 'backup', 'price'];
    public symbol = '';
    public clouds_with_backup: { id: number; cloud: string; description: string; types: { memory: string; vcpus: string; ssd: string; traffic: string; backup?: string; price: string; url: string; popular: boolean; }[]; }[];
    public links: { personas: { banner: string; dialogs: { html: string; ecommerce: string; blog: string; app: string; }; }; pymes: { banner: string; }; wordpress: { banner: string; }; empresas: { banner: string; }; cloud: { banner: string; }; ssl: { banner: string; }; reseller: { banner: string; }; };
    private locale: string;

    public info:InfoSimple = {
        'title1': '¿Quieres aumentar la velocidad',
        'title2': 'y llevar tu empresa a otro nivel?',
        'section': 'pad2',
        'button': 'CONTRATAR AQUÍ'
    }

    public lists:ListExtra = {
        list1:[
            { part1: 'Discos sólidos full velocidad', part2:'mayor velocidad y estabilidad' },
            { part1: 'Procesador última generación', part2:'mayor rendimiento y eficiencia en los procesos' },
            { part1: 'Red privada', part2:'de uso exclusivo para tu servicio entregando mayor seguridad' },
            { part1: 'Panel de control', part2:'maneja de forma rápida y facilmente tu cloud server' },
            { part1: 'Uptime 99,9%', part2:'servicios, ping, SMTP, HTTP y red' },
            { part1: 'Backup incluido', part2:''}
        ]
    }

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private localeService: LocaleService,
        private readonly _meta: MetaService,
        private location: Location,
        private _el: ElementRef,
    ) {
        super(_el);
        this.symbol = this.localeService.symbol;
        this.links = this.localeService.getContent().links;
        this.clouds_with_backup = this.localeService.getContent().clouds_with_backup;
        this.locale = this.localeService.locale;
    }

    ngOnInit() {
        this.addVideoControlsForMobyle();
        //Set Meta Tags
        if (this.locale == "es-CO")
            this._setMetaCO();
        else if (this.locale == "es-MX")
            this._setMetaMX();
        else if (this.locale == "es-PE")
            this._setMetaPE();
        else if (this.locale == "es-AR")
            this._setMetaAR();
        else
            this._setMetaCL();
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    addVideoControlsForMobyle() {
        setTimeout(_ => {
            const video1 = this._document.getElementsByClassName('video1')[0] as HTMLVideoElement;
            if (window.innerWidth < 670) {
                if (video1) video1.setAttribute('controls', '');
            }
        }, 1500); //Lucky number
    }

    toggleVideoCompany(el) {
        const element = this._findAncestorFromEl(el.target, 'videoCompany__video');
        const playEl = element.classList.contains('play');
        const video = element.getElementsByClassName('video')[0] as HTMLVideoElement;

        if (window.innerWidth > 670) {
            if (playEl) {
                element.classList.remove('play');
                video.pause();
            } else {
                element.classList.add('play');
                video.play();
            }
        }
    }

    navigateToSection(className) {
        this.scrollToSection(className);
    }

    private _findAncestorFromEl(el, cls) {
        if (el.classList.contains(cls)) {
            return el;
        }
        return this._findAncestor(el, cls);
    }

    private _findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls)) { }
        return el;
    }

    private _setMetaCO() {
        this._meta.setTitle('SSD VPS CLOUD SERVER | SSD VPS Cloud Server 300% + Rápidez');
        this._meta.setTag('description', 'SSD VPS Cloud Server con potencia de Dell Poweredge R920 con discos solidos SSD y un 99,9% Uptime.');
        this._meta.setTag('keywords', 'VPS, VPS colombia, VPS colombia barato, VPS comprar, mejor VPS colombia, VPS SSD, SSD VPS Cloud Server, servidor virtual privado, cloud server, cloud server colombia, cloud server hosting, hosting servidor, discos ssd, cloud server, cloud server colombia, cloud server hosting, hosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/cloud.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Colombia');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
    }

    private _setMetaCL() {
        this._meta.setTitle('SSD VPS CLOUD SERVER | SSD VPS Cloud Server 300% + Rápidez');
        this._meta.setTag('description', 'SSD VPS Cloud Server con potencia de Dell Poweredge R920 con discos solidos SSD y un 99,9% Uptime.');
        this._meta.setTag('keywords', 'VPS, VPS chile, VPS chile barato, VPS comprar, mejor VPS chile, VPS SSD, SSD VPS Cloud Server, servidor virtual privado, cloud server, cloud server chile, cloud server hosting, hosting servidor, discos ssd, cloud server, cloud server chile, cloud server hosting, hosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/cloud.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Chile');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }

    private _setMetaPE() {
        this._meta.setTitle('SSD VPS CLOUD SERVER | SSD VPS Cloud Server 300% + Rápidez');
        this._meta.setTag('description', 'SSD VPS Cloud Server con potencia de Dell Poweredge R920 con discos solidos SSD y un 99,9% Uptime.');
        this._meta.setTag('keywords', 'VPS, VPS perú, VPS perú barato, VPS comprar, mejor VPS perú, VPS SSD, SSD VPS Cloud Server, servidor virtual privado, cloud server, cloud server perú, cloud server hosting, hosting servidor, discos ssd, cloud server, cloud server perú, cloud server hosting, hosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/cloud.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Perú');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }

    private _setMetaMX() {
        this._meta.setTitle('SSD VPS CLOUD SERVER | SSD VPS Cloud Server 300% + Rapidez');
        this._meta.setTag('description', 'SSD VPS Cloud Server con potencia de Dell Poweredge R920 con discos solidos SSD y un 99,9% Uptime.');
        this._meta.setTag('keywords', 'VPS, VPS méxico, VPS méxico barato, VPS comprar, mejor VPS méxico, VPS SSD, SSD VPS Cloud Server, servidor virtual privado, cloud server, cloud server méxico, cloud server hosting, hosting servidor, discos ssd, cloud server, cloud server méxico, cloud server hosting, hosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/cloud.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en México');
        this._meta.setTag('og:locale', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaAR() {
        this._meta.setTitle('SSD VPS CLOUD SERVER | SSD VPS Cloud Server 300% + Rápidez');
        this._meta.setTag('description', 'SSD VPS Cloud Server con potencia de Dell Poweredge R920 con discos solidos SSD y un 99,9% Uptime.');
        this._meta.setTag('keywords', 'VPS, VPS argentina, VPS argentina barato, VPS comprar, mejor VPS argentina, VPS SSD, SSD VPS Cloud Server, servidor virtual privado, cloud server, cloud server argentina, cloud server hosting, hosting servidor, discos ssd, cloud server, cloud server argentina, cloud server hosting, hosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/OG_cloud_ARG.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Argentina');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
}
