import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { MetaModule, MetaService } from '@ngx-meta/core';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { DeferLoadModule } from '@trademe/ng-defer-load';

import { BlockLogosComponent } from './shared/block-logos/block-logos.component';
import { SnackBarService } from './services/snack.service';
import { routes } from './app.routing';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { KeysPipe } from './keys.pipe';
import { DialogSyteHtmlComponent } from './shared/dialogs/dialog-syte-html/dialog-syte-html.component';
import { DialogSyteEcommerceComponent } from './shared/dialogs/dialog-syte-ecommerce/dialog-syte-ecommerce.component';
import { DialogSyteBlogComponent } from './shared/dialogs/dialog-syte-blog/dialog-syte-blog.component';
import { DialogSyteApplicationComponent } from './shared/dialogs/dialog-syte-application/dialog-syte-application.component';
import { DialogCustomMenuComponent } from './shared/dialogs/dialog-custom-menu/dialog-custom-menu.component';
import { TabPlansComponent } from './shared/tab-plans/tab-plans.component';
import { BlockClientsComponent } from './shared/block-clients/block-clients.component';
import { MenuPlansComponent } from './shared/menu-plans/menu-plans.component';
import { ChartSecurityComponent } from './shared/arc-charts/chart-security/chart-security.component';
import { ChartProductivity1Component } from './shared/arc-charts/chart-productivity1/chart-productivity1.component';
import { ChartProductivity2Component } from './shared/arc-charts/chart-productivity2/chart-productivity2.component';
import { ChartProductivity3Component } from './shared/arc-charts/chart-productivity3/chart-productivity3.component';
import { ChartProductivity4Component } from './shared/arc-charts/chart-productivity4/chart-productivity4.component';
import { ChartProductivity5Component } from './shared/arc-charts/chart-productivity5/chart-productivity5.component';
import { PymesComponent } from './pymes/pymes.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { ContactsComponent } from '../../../common/contacts/contacts.component';
import { CertificadoComponent } from './certificado/certificado.component';
import { DominioComponent } from './dominio/dominio.component';
import { ResellerComponent } from './reseller/reseller.component';
import { PagoComponent } from './pago/pago.component';
import { CloudComponent } from './cloud/cloud.component';
import { CloudWithBackupComponent } from './cloud-with-backup/cloud-with-backup.component';
import { EmprendedorComponent } from './emprendedor/emprendedor.component';
import { BlockPersonComponent } from '../../../common/block-person/block-person.component';
import { SuccessComponent } from './success/success.component';
import { CurrencyLocalePipe } from './currency.pipe';
import { SecurityService } from './services/security.service';
import { LocaleService } from './services/locale.service';
import { PlansService } from './services/plans.service';
import { DataPage } from './services/data-page';
import { ContactWrapperComponent } from './contact/contact-wrapper.component';
import { ContactSupportComponent } from '../../../common/contact-support/contact-support.component';
import { ContactSupportWrapperComponent } from './contact-support-wrapper/contact-support-wrapper.component';
import { PaymentFormsWrapperComponent } from './payment-forms-wrapper/payment-forms-wrapper.component';
import { AppComponent } from './app.component';
import { DomainService } from './services/domain.service';
import { DateLocalePipe } from './date.pipe';
import { WordpressComponent } from './wordpress/wordpress.component';
import { BlockDomainComponent } from './shared/block-domain/block-domain.component';
import { SuccessGuard } from './success/success.guard';

// DirectPayment page
import { DirectPaymentComponent } from './direct-payment/direct-payment.component';
import { DirectPaymentFirstScreenComponent } from './direct-payment/direct-payment-first-screen/direct-payment-first-screen.component';
import { NoSearchResultComponent } from './direct-payment/no-search-result/no-search-result.component';
import { SearchResultComponent } from './direct-payment/search-result/search-result.component';
import { SendTicketComponent } from './direct-payment/send-ticket/send-ticket.component';
import { NgxCaptchaModule } from 'ngx-captcha';

//Promos flash
import { StripWrapperComponent } from './promotions/strip/strip.component';
import { DialogPromoWrapperComponent } from './promotions/dialog-promo/dialog-promo.component';
import { WebsiteCommonModule } from '../../../common/common.module';

// Autoatencion page
import { AutoatencionComponent } from './autoatencion/autoatencion.component';
import { AutoatencionFirstScreenComponent } from './autoatencion/autoatencion-first-screen/autoatencion-first-screen.component';
import { HostingManagementComponent } from './autoatencion/hosting-management/hosting-management.component';
import { HostingManagementDialogComponent } from './autoatencion/hosting-management/hosting-management-dialog/hosting-management-dialog.component';
import { TypeformModalComponent } from './autoatencion/hosting-management/typeform-modal/typeform-modal.component';
import { AutoatencionSectionComponent } from './home/autoatencion-section/autoatencion-section.component';

//Video Company
import { VideoComponent } from './shared/video-company/video-company.component';

//Block info
import { BlockInfoComponent } from './shared/block-info/block-info.component';
import { BlockInfoSimpleComponent } from './shared/block-info-simple/block-info-simple.component';

//Block list
import { BlockListComponent } from './shared/block-list/block-list.component';
import { BlockListExtraComponent } from './shared/block-list-extra/block-list-extra.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        KeysPipe,
        DialogSyteHtmlComponent,
        DialogSyteEcommerceComponent,
        DialogSyteBlogComponent,
        DialogSyteApplicationComponent,
        DialogCustomMenuComponent,
        TabPlansComponent,
        BlockClientsComponent,
        BlockLogosComponent,
        MenuPlansComponent,
        ChartSecurityComponent,
        ChartProductivity1Component,
        ChartProductivity2Component,
        ChartProductivity3Component,
        ChartProductivity4Component,
        ChartProductivity5Component,
        PymesComponent,
        EmpresaComponent,
        ContactsComponent,
        CertificadoComponent,
        CurrencyLocalePipe,
        ContactWrapperComponent,
        ContactSupportComponent,
        ContactSupportWrapperComponent,
        DominioComponent,
        ResellerComponent,
        PagoComponent,
        CloudComponent,
        CloudWithBackupComponent,
        DateLocalePipe,
        WordpressComponent,
        EmprendedorComponent,
        BlockPersonComponent,
        PaymentFormsWrapperComponent,
        BlockDomainComponent,
        SuccessComponent,
        // DirectPayment page
        DirectPaymentComponent,
        DirectPaymentFirstScreenComponent,
        NoSearchResultComponent,
        SearchResultComponent,
        SendTicketComponent,
        StripWrapperComponent,
        DialogPromoWrapperComponent,
        VideoComponent,
        BlockInfoComponent,
        BlockInfoSimpleComponent,
        BlockListComponent,
        BlockListExtraComponent,
        // Autoatencion page
        AutoatencionComponent,
        AutoatencionFirstScreenComponent,
        HostingManagementComponent,
        HostingManagementDialogComponent,
        TypeformModalComponent,
        // home page section
        AutoatencionSectionComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'bluehosting' }),
        BrowserAnimationsModule,
        HttpClientModule,
        NgxCaptchaModule,
        MetaModule.forRoot(),
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        TransferHttpCacheModule,
        DeferLoadModule,
        IconSpriteModule.forRoot({
            path: '/assets/images/sprites/sprite.svg'
        }),
        WebsiteCommonModule
    ],
    entryComponents: [
        DialogSyteHtmlComponent,
        DialogSyteEcommerceComponent,
        DialogSyteBlogComponent,
        DialogSyteApplicationComponent,
        DialogCustomMenuComponent
    ],
    providers: [
        SecurityService,
        LocaleService,
        PlansService,
        MetaService,
        DomainService,
        CurrencyLocalePipe,
        SnackBarService,
        DataPage,
        SuccessGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
