import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Info } from '../interfaces/block';
import { Router } from '@angular/router';
import { urlMapping } from '../route_ids';
import { traceButton2 } from '../../../../../common/services/button-trace';

@Component({
    selector: 'app-block-info',
    templateUrl: './block-info.component.html'
})
export class BlockInfoComponent{

    @Input() info:Info;
    public url:string;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private _router: Router
    ){
        const urlTree = this._router.parseUrl(this._router.url);
        const primarySegment = urlTree.root.children['primary'];
        const urlSegment = primarySegment?.segments[0]?.path || 'home';;
        this.url = urlMapping[urlSegment] || urlSegment;
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    public track(event:Event){
        traceButton2(event, 'navigate');
    }
}