import { Component, OnInit, Inject, Output, PLATFORM_ID, ElementRef, HostListener } from '@angular/core';

import { LocaleService } from '../services/locale.service';
import { URL } from '../../environments/environment';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { MetaService } from '@ngx-meta/core';
import { ScrollToClass } from '../../../../common/scrollTo.class';
import { Info, List } from '../shared/interfaces/block';

@Component({
    selector: 'app-wordpress',
    templateUrl: './wordpress.component.html'
})
export class WordpressComponent extends ScrollToClass implements OnInit {
    // lazy loading images
    showSectionSlider: boolean = false;
    showFooter: boolean = false;
    showImage1: boolean = false;
    showImage2: boolean = false;
    showImage3: boolean = false;

    isShowPlans = true;

    @Output() hosting;
    links;
    symbol = '';
    category;
    locale;

    URL = URL;

    public info:Info = {
        title:'¿Quieres más potencial? Conoce nuestros planes Empresa',
        symbol: '',
        price: 0,
        url: '/web-hosting-empresa/.'
    }

    public lists:List = {
        sup_title:'¿Aún hay más potencia?',
        title1:'El plan Wordpress',
        title2:'también incluye',
        list1:[
            'Encriptación SHA-2 y de 2048 bits',
            'Compatible con todos los navegadores',
            'Protección ilimitada de servidores',
            'Mayor posicionamiento en Google',
            'Sello de seguridad a la vista',
            'Renovación gratuita ilimitada del Certificado SSL',
            'Protección de responsabilidad',
            'Garantía de 30 días'
        ],
        list2:[
            "SSL Gratuito Let's Encrypt",
            'Dominio.cl gratis por 1 año',
            'Migración gratuita',
            'Uptime 99.9%'
        ]
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isShowPlans = event.target.innerWidth < 768 ? false : true;
    }

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        private localeService: LocaleService,
        private readonly _meta: MetaService,
        private _el: ElementRef,
    ) {
        super(_el);
        this.symbol = this.localeService.symbol;
        this.links = this.localeService.getContent().links;
        this.category = this.localeService.getContent().hosting[3];
        this.hosting = this.localeService.getContent().hosting.filter(category => category.show === true);
        this.locale = this.localeService.locale;
        this.info.price = this.hosting[2].plans[0].periods[0].price
        this.info.symbol = this.localeService.symbol;
    }

    ngOnInit() {
        this.addVideoControlsForMobyle();
        this.removePlansMobyle();

        //Set Meta Tags
        if (this.locale == "es-CO")
            this._setMetaCO();
        else if (this.locale == "es-MX")
            this._setMetaMX();
        else if (this.locale == "es-PE")
            this._setMetaPE();
        else if (this.locale == "es-AR")
            this._setMetaAR();
        else
            this._setMetaCL();
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    addVideoControlsForMobyle() {
        setTimeout(_ => {
            const video1 = this._document.getElementsByClassName('video1')[0] as HTMLVideoElement;
            if (window.innerWidth < 670) {
                if (video1) video1.setAttribute('controls', '');
            }
        }, 1500); //Lucky number
    }

    toggleVideoCompany(el) {
        const element = this._findAncestorFromEl(el.target, 'videoCompany__video');
        const playEl = element.classList.contains('play');
        const video = element.getElementsByClassName('video')[0] as HTMLVideoElement;

        if (window.innerWidth > 670) {
            if (playEl) {
                element.classList.remove('play');
                video.pause();
            } else {
                element.classList.add('play');
                video.play();
            }
        }
    }

    removePlansMobyle() {
        if (window.innerWidth < 768) {
            this.isShowPlans = false;
        }
    }

    navigateToSection(className) {
        this.scrollToSection(className);
    }

    private _findAncestorFromEl(el, cls) {
        if (el.classList.contains(cls)) {
            return el;
        }
        return this._findAncestor(el, cls);
    }

    private _findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls)) { }
        return el;
    }

    private _setMetaCO() {
        this._meta.setTitle('HOSTING WORDPRESS | Web Hosting 100% Compatible con Wordpress');
        this._meta.setTag('description', 'Hosting Wordpress, la mejor opción en Colombia para el funcionamiento rápido y eficiente de tu Wordpress, accede a un web hosting 100% compatible.');
        this._meta.setTag('keywords', 'hosting wordpress, hosting wordpress colombia, hosting wordpress economico, hosting wordpress barato, wordpress colombia, hosting, hosting colombia, alojamiento web');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/wordpress.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Colombia');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
    }

    private _setMetaCL() {
        this._meta.setTitle('HOSTING WORDPRESS | Web Hosting 100% Compatible con Wordpress');
        this._meta.setTag('description', 'Hosting Wordpress, la mejor opción en Chile para el funcionamiento rápido y eficiente de tu Wordpress, accede a un web hosting 100% compatible.');
        this._meta.setTag('keywords', 'hosting wordpress, hosting wordpress chile, hosting wordpress economico, hosting wordpress barato, wordpress chile, hosting, hosting chile, alojamiento web');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/wordpress.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Chile');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }

    private _setMetaPE() {
        this._meta.setTitle('HOSTING WORDPRESS | Web Hosting 100% Compatible con Wordpress');
        this._meta.setTag('description', 'Hosting Wordpress, la mejor opción en Perú para el funcionamiento rápido y eficiente de tu Wordpress, accede a un web hosting 100% compatible.');
        this._meta.setTag('keywords', 'hosting wordpress, hosting wordpress perú, hosting wordpress economico, hosting wordpress barato, wordpress perú, hosting, hosting perú, alojamiento web');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/wordpress.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Perú');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaMX() {
        this._meta.setTitle('HOSTING WORDPRESS | Web Hosting 100% Compatible con Wordpress');
        this._meta.setTag('description', 'Hosting Wordpress, la mejor opción en México para el funcionamiento rápido y eficiente de tu Wordpress, accede a un web hosting 100% compatible.');
        this._meta.setTag('keywords', 'hosting wordpress, hosting wordpress méxico, hosting wordpress economico, hosting wordpress barato, wordpress méxico, hosting, hosting méxico, alojamiento web');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/wordpress.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en México');
        this._meta.setTag('og:locale', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaAR() {
        this._meta.setTitle('HOSTING WORDPRESS | Web Hosting 100% Compatible con Wordpress');
        this._meta.setTag('description', 'Hosting Wordpress, la mejor opción en Argentina para el funcionamiento rápido y eficiente de tu Wordpress, accedé a un web hosting 100% compatible.');
        this._meta.setTag('keywords', 'hosting wordpress, hosting wordpress argentina, hosting wordpress economico, hosting wordpress barato, wordpress argentina, hosting, hosting argentina, alojamiento web');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/OG_wordpress_ARG.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Argentina');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
}
