import { Subject, Observable, from } from 'rxjs';
import { Injectable } from '@angular/core';

const data$ = new Subject<any>();

@Injectable()
export class DataPage {

    getData$(): Observable<any> {
        return from(data$);
    }

    setData(data: string) {
        data$.next(data);
    }
}
