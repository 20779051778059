<!-- START firstScreen -->
<section class="firstScreen">
  <picture>
    <source srcset="/assets/images/first-screen/first_img_screen_pago@1x.avif,
      /assets/images/first-screen/first_img_screen_pago@2x.avif 2x" type="image/avif">
    <source srcset="/assets/images/first-screen/first_img_screen_pago@1x.webp,
      /assets/images/first-screen/first_img_screen_pago@2x.webp 2x" type="image/webp">
    <img src="/assets/images/first-screen/first_img_screen_pago@1x.jpg" srcset="/assets/images/first-screen/first_img_screen_pago@2x.jpg 2x" alt="First Screen Image">
  </picture>
  <div class="container">
    <div class="firstScreen__ttl" i18n>FORMAS DE PAGO</div>
  </div>
</section>
<!-- END firstScreen -->

<!-- START -->
<section class="paimentItem__wrapper pad">
  <div class="container">
    <div class="paimentItem" *ngFor="let item of items">
      <div class="paimentItem__info">
        <h3 class="ttl3">{{item.title}}</h3>
        <p class="sub" [innerHtml]="item.subtitle"></p>
        <a [innerHtml]="item.anchor"></a>
      </div>
      <div class="paimentItem__img">
        <img *ngFor="let method of item.methods" src="{{method.img_url}}" alt="{{method.alt}}">
      </div>
    </div>
  </div>
</section>
<!-- END -->

<!-- START block_txt_column -->
<section class="block_txt_column pad bg_grey">
    <div class="container">
      <h2 class="ttl2_black" i18n>Preguntas frecuentes</h2>
      <div class="txt_column">
  
        <div class="txt_column__column">
          <h3 class="ttl3" i18n>Ya pague mi servicio, ¿Qué paso sigue?</h3>
          <p i18n>Enviaremos un email de bienvenida a tu correo asociado, dentro encontrarás toda la información referente a tu plan y los datos de acceso a tu cuenta para que puedas comenzar. Solo si tu pago fue realizado mediante transferencia bancaria, deberás notificarlo adjuntando el comprobante de transacción al mail pago@bluehosting.host.</p>
          <h3 class="ttl3" i18n>¿Cómo notifico el pago de mi servicio?</h3>
          <p i18n>Si el pago de tu servicio se realizó mediante transferencia o depósito bancario, deberás notificarlo enviando un email a pago@bluehosting.host, adjuntando el comprobante en formato JPG o PNG y procesaremos tu pago en menos de 2 horas hábiles.</p>
          <h3 class="ttl3" i18n>¿Puedo cambiar la fecha de pago de mi servicio?</h3>
          <p i18n>Si, envíanos una solicitud formal por medio de Ticket. Esta solicitud debe ser enviada desde el área de cliente vinculada al servicio, indicando la nueva fecha que deseas para tus pagos.</p>
        </div>
  
        <div class="txt_column__column">
          <h3 class="ttl3" i18n>No tengo acceso a mi área de cliente, ¿Cómo puedo pagar la renovación de mi servicio?</h3>
          <p i18n>Como recomendación general, mantén tus datos de contacto del área de cliente actualizados siempre, ya que a través de ese medio te haremos llegar toda la información referente a tu servicio. De no tener tu acceso y querer pagar la renovación, puedes realizar una transferencia bancaria, nuestros agentes de soporte enviarán la orden de renovación con el fin de agilizar la regularización del pago.</p>
          <h3 class="ttl3" i18n>¿Cómo solicito mi factura?</h3>
          <p i18n>Al procesarse el pago de tu servicio, recibirás un email solicitando los datos necesarios para procesar tu factura electrónica. Con el formulario completo, enviaremos tu documento en menos de 24hrs hábiles. </p>
        </div>
  
      </div>
    </div>
  </section>
  <!-- END block_txt_column -->

<!-- START block_logo -->
<app-block-logos></app-block-logos>
<!-- END block_logo -->
<app-footer></app-footer>
