<!-- START blockClients -->
<section class="blockClients">
  <div class="blockClients__wrapper">
    <div class="blockClients__item" [class.active]="client.isActiveItem" *ngFor="let client of clients">
      <div class="filter"></div>
        <div class="container">
          <h2 class="ttl2">
            <span>{{client.ttl}}</span>
          </h2>
          <div class="blockClients__blockquote">
            <p class="blockquote">“{{client.comment}}”.</p>
            <p class="name">{{client.name}}</p>
            <p class="prof">{{client.position}}</p>
          </div>
        </div>
    </div>
  </div>

  <div class="blockClients__nav">
    <div class="blockClients__nav-item">
      <div class="blockClients__nav-btn active" (click)="selectedItem('1'); selectedBtn($event)">
        <img src="/assets/images/img-avatar-1.png" alt="Melhuish">
      </div>
      <img src="/assets/images/img-logo-clients-1.svg" alt="logo">
    </div>
    <div class="blockClients__nav-item">
      <div class="blockClients__nav-btn" (click)="selectedItem('2'); selectedBtn($event)">
        <img src="/assets/images/img-avatar-2.png" alt="Chile Camiones">
      </div>
      <img src="/assets/images/img-logo-clients-2.svg" alt="logo">
    </div>
    <div class="blockClients__nav-item">
      <div class="blockClients__nav-btn" (click)="selectedItem('3'); selectedBtn($event)">
        <img src="/assets/images/img-avatar-3.png" alt="Wordpress medellín">
      </div>
      <img src="/assets/images/img-logo-clients-3.png" alt="logo">
    </div>
    <div class="blockClients__nav-item">
      <div class="blockClients__nav-btn" (click)="selectedItem('4'); selectedBtn($event)">
        <img src="/assets/images/img-avatar-4.png" alt="ArmCreative">
      </div>
      <img src="/assets/images/img-logo-clients-4.png" alt="logo">
    </div>
  </div>
</section>
<!-- END blockClients -->