import { Component, OnInit, Inject, Output, ElementRef, HostListener } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { LocaleService } from '../services/locale.service';
import { URL } from '../../environments/environment';
import { MetaService } from '@ngx-meta/core';
import { ScrollToClass } from '../../../../common/scrollTo.class';
import { List } from '../shared/interfaces/block';

@Component({
    selector: 'app-empresa',
    templateUrl: './empresa.component.html'
})
export class EmpresaComponent extends ScrollToClass implements OnInit {
    // lazy loading images
    showSectionSlider: boolean = false;
    showFooter: boolean = false;
    showImage1: boolean = true;
    showImage2: boolean = false;
    showImage3: boolean = false;
    showImage4: boolean = false;

    URL = URL;

    playVideo = false;
    isShowPlans = true;

    @Output() hosting;
    links;
    symbol = '';
    locale;

    public urlFoto:string = '/assets/images/img-general.png'
    public urlVideo:string= '/assets/video/bluehosting-general.mp4'

    public lists:List = {
        sup_title:'¿Aún hay más potencia?',
        title1:'El plan Empresa',
        title2:'también incluye',
        list1:[
            'Compatibilidad con todos los navegadores',
            'Protección ilimitada de servidores',
            'Mayor posicionamiento en Google',
            'Renovación gratuita ilimitada del Certificado SSL',
            'Protección de responsabilidad',
            'Garantía de 30 días'
        ],
        list2:[
            "SSL Gratuito Let's Encrypt",
            'Dominio.cl gratis por 1 año',
            'Migración gratuita',
            'Uptime 99.9%'
        ]
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isShowPlans = event.target.innerWidth < 768 ? false : true;
    }

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private localeService: LocaleService,
        private readonly _meta: MetaService,
        private _el: ElementRef,
    ) {
        super(_el);
        this.symbol = this.localeService.symbol;
        this.links = this.localeService.getContent().links;
        this.hosting = this.localeService.getContent().hosting.filter(category => category.show === true);
        this.locale = this.localeService.locale;
    }

    ngOnInit() {
        this.removePlansMobyle();
        this.addVideoControlsForMobyle();

        //Set Meta Tags
        if(this.locale == "es-CO")
            this._setMetaCO();
        else if (this.locale == "es-MX")
            this._setMetaMX();
        else if (this.locale == "es-PE")
            this._setMetaPE();
        else if (this.locale == "es-AR")
            this._setMetaAR();
        else
            this._setMetaCL();
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    toggleVideoCompany(el) {
        const element = this._findAncestorFromEl(el.target, 'videoCompany__video');
        const playEl = element.classList.contains('play');
        const video = element.getElementsByClassName('video')[0] as HTMLVideoElement;

        if (window.innerWidth > 670) {
            if (playEl) {
                element.classList.remove('play');
                video.pause();
            } else {
                element.classList.add('play');
                video.play();
            }
        }
    }

    removePlansMobyle() {
        if (window.innerWidth < 768) {
            this.isShowPlans = false;
        }
    }

    navigateToSection(className) {
        this.scrollToSection(className);
    }

    private _findAncestorFromEl(el, cls) {
        if (el.classList.contains(cls)) {
            return el;
        }
        return this._findAncestor(el, cls);
    }

    private _findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls)) {}
        return el;
    }

    private _setMetaCO(){
        this._meta.setTitle('HOSTING EMPRESAS | Web Hosting Empresa Incrementa 80% tu Potencia');
        this._meta.setTag('description', 'Encuentra tu plan Web Hosting para empresas de alto rendimiento en BlueHosting y disfruta de + potencia. Sube tu sitio de nivel.');
        this._meta.setTag('keywords', 'hosting empresas, hosting para empresas, hosting para empresas en colombia, hosting para empresas barato, servicio de hosting para empresas, hosting colombia barato, web hosting colombia, web hosting, web hosting barato, alojamiento web');
        this._meta.setTag('author', 'Bluehosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/empresa.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Colombia');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
    }

    private _setMetaCL(){
        this._meta.setTitle('HOSTING EMPRESAS | Web Hosting Empresa Incrementa 80% tu Potencia');
        this._meta.setTag('description', 'Encuentra tu plan Web Hosting para empresas de alto rendimiento en BlueHosting y disfruta de + potencia. Sube tu sitio de nivel.');
        this._meta.setTag('keywords', 'hosting empresas, hosting para empresas, hosting para empresas en chile, hosting para empresas barato, servicio de hosting para empresas, hosting chile barato, web hosting chile, web hosting, web hosting barato, alojamiento web');
        this._meta.setTag('author', 'Bluehosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/empresa.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Chile');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaPE(){
        this._meta.setTitle('HOSTING EMPRESAS | Web Hosting Empresa Incrementa 80% tu Potencia');
        this._meta.setTag('description', 'Encuentra tu plan Web Hosting para empresas de alto rendimiento en BlueHosting y disfruta de + potencia. Sube tu sitio de nivel');
        this._meta.setTag('keywords', 'hosting empresas, hosting para empresas, hosting para empresas en perú, hosting para empresas barato, servicio de hosting para empresas, hosting perú barato, web hosting perú, web hosting, web hosting barato, alojamiento web');
        this._meta.setTag('author', 'Bluehosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/empresa.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Perú');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaMX(){
        this._meta.setTitle('HOSTING EMPRESAS | Web Hosting Empresa Incrementa 80% tu Potencia');
        this._meta.setTag('description', 'Encuentra tu plan Web Hosting para empresas de alto rendimiento en BlueHosting y disfruta de + potencia. Sube tu sitio de nivel');
        this._meta.setTag('keywords', 'hosting empresas, hosting para empresas, hosting para empresas en méxico, hosting para empresas barato, servicio de hosting para empresas, hosting méxico barato, web hosting méxico, web hosting, web hosting barato, alojamiento web');
        this._meta.setTag('author', 'Bluehosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/empresa.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en México');
        this._meta.setTag('og:locale', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaAR(){
        this._meta.setTitle('HOSTING EMPRESAS | Web Hosting Empresa Incrementa 80% tu Potencia');
        this._meta.setTag('description', 'Encontrá tu plan Web Hosting para empresas de alto rendimiento en BlueHosting y disfruta de + potencia. Subí tu sitio de nivel');
        this._meta.setTag('keywords', 'hosting empresas, hosting para empresas, hosting para empresas en argentina, hosting para empresas barato, servicio de hosting para empresas, hosting argentina barato, web hosting argentina, web hosting, web hosting barato, alojamiento web');
        this._meta.setTag('author', 'Bluehosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/OG_empresas_ARG.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Argentina');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }

    addVideoControlsForMobyle() {
        setTimeout(_ => {
            const video1 = this._document.getElementsByClassName('video1')[0] as HTMLVideoElement;
            if (window.innerWidth < 670) {
                if (video1) video1.setAttribute('controls', '');
            }
        }, 1500); // Lucky number
    }
}
