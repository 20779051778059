import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";

@Component({
  selector: 'app-block-clients',
  templateUrl: './block-clients.component.html'
})
export class BlockClientsComponent implements OnInit {
    activeBtn = 1;

    clients = [
        {
            ttl: 'Más de 50.000 clientes nos avalan',
            // tslint:disable-next-line:max-line-length
            comment: 'Es un agrado contar con sus servicios y con el soporte que ofrecen, adquirí sus planes en el 2014 y desde entonces, al contratar un nuevo hosting para nuestros clientes no dudo en que sea a través de Bluehosting, cuando tengo una emergencia me comunico con su departamento de soporte y me han respondido al 100%, agradecido de su colaboración, espero que continúen de la misma manera, muchas bendiciones y un agrado trabajar con ustedes',
            name: 'Luis Pérez',
            position: 'Automotora Melhuish.',
            isActiveItem: true,
            id: 1
        },
        {
            ttl: 'Más de 50.000 clientes nos avalan',
            // tslint:disable-next-line:max-line-length
            comment: 'Comenzamos a trabajar con BlueHosting en el año 2015 y desde el primer momento nos prestaron una excelente asistencia, constantemente están preocupados del correcto funcionamiento del servicio. Cada vez que nos ponemos en contacto con el área de soporte hemos tenido una respuesta satisfactoria. Jamás hemos tenido problemas con el uptime, además se agradece que frente a cualquier emergencia estas sean informadas al cliente de forma oportuna. Estoy feliz de trabajar con BlueHosting',
            name: 'Mario Arancibia',
            position: 'Chile Camiones Ltda.',
            isActiveItem: false,
            id: 2
        },
        {
            ttl: 'Más de 50.000 clientes nos avalan',
            // tslint:disable-next-line:max-line-length
            comment: 'Gracias al patrocinio y apoyo que BlueHosting brinda a nuestra comunidad local (Wordpress Medellín), hemos podido tener visibilidad web y como tribu, hemos crecido considerablemente gracias a los obsequios y descuentos que nos otorgan en sus planes de “Web Hosting Pyme” a los miembros de nuestro meetup. Adicional, queremos resaltar la buena atención del staff de BlueHosting, el precio, el soporte y la calidad de los servicios que brindan"',
            name: 'Catalina Zapata',
            position: 'Organizadora de Wordpress Medellín.',
            isActiveItem: false,
            id: 3
        },
        {
            ttl: 'Más de 50.000 clientes nos avalan',
            // tslint:disable-next-line:max-line-length
            comment: 'Confío ampliamente en BlueHosting, llevo algunos años trabajando con ellos y hasta el día de hoy, todo ha funcionado como debe ser. Agradezco su atención al cliente y espero que sigan mejorando con el pasar del tiempo',
            name: 'Jorge Huidobro Cárdenas',
            position: 'Armetelec Perú.',
            isActiveItem: false,
            id: 4
        },
    ];

    constructor(
        @Inject(DOCUMENT) private _document: Document
    ) { }

    ngOnInit() {
    }

    selectedItem(itemId: string) {
        this.activeBtn = parseInt(itemId, 10);
        const item1 = this.clients.filter(client => client.id === 1);
        const item2 = this.clients.filter(client => client.id === 2);
        const item3 = this.clients.filter(client => client.id === 3);
        const item4 = this.clients.filter(client => client.id === 4);


        if (this.activeBtn === 1) {
            item1[0].isActiveItem = true;
            item2[0].isActiveItem = false;
            item3[0].isActiveItem = false;
            item4[0].isActiveItem = false;
        } else if (this.activeBtn === 2) {
            item1[0].isActiveItem = false;
            item2[0].isActiveItem = true;
            item3[0].isActiveItem = false;
            item4[0].isActiveItem = false;
        } else if (this.activeBtn === 3) {
            item1[0].isActiveItem = false;
            item2[0].isActiveItem = false;
            item3[0].isActiveItem = true;
            item4[0].isActiveItem = false;
        } else if (this.activeBtn === 4) {
            item1[0].isActiveItem = false;
            item2[0].isActiveItem = false;
            item3[0].isActiveItem = false;
            item4[0].isActiveItem = true;
        }
    }

    selectedBtn(el) {
        const container = <HTMLElement>this._document.getElementsByClassName('blockClients')[0];
        const btn = this._findAncestorFromEl(el.target, 'blockClients__nav-btn');

        if (!!btn) {
            const btnList = <NodeListOf <HTMLElement>><unknown>container.getElementsByClassName('blockClients__nav-btn');
            const isActive = btn.classList.contains('active');

            if (!isActive) {
                [].forEach.call(btnList, (el) => {
                    el.classList.remove('active');
                });
                btn.classList.add('active');
            }
        }
    }

    private _findAncestorFromEl(el, cls) {
        if (el.classList.contains(cls)) {
            return el;
        }
        return this._findAncestor(el, cls);
    }

    private _findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls)) {}
        return el;
    }

}
