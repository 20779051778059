export class SecurityResponse {
    counters: SecurityCounters;
    bloquedIPs: IPList;

    constructor(counters, bloquedIP)
    {
        this.counters = counters;
        this.bloquedIPs = bloquedIP;
    }
}

export class SecurityCounters {
    blockages: number
    disinfections: number
    total: number

    constructor(blockages, disinfections)
    {
        this.blockages = blockages;
        this.disinfections = disinfections;
        this.total = blockages + disinfections;
    }
}

export class IPList {
    ip: string
    country: string
    date: string
    flag_url: string
}