<!-- START firstScreen -->
<section [ngClass]="{'firstScreen': true}">
  <picture>
    <source srcset="/assets/images/first-screen/first_img_screen_home@1x.avif,
      /assets/images/first-screen/first_img_screen_home@2x.avif 2x" type="image/avif">
    <source srcset="/assets/images/first-screen/first_img_screen_home@1x.webp,
        /assets/images/first-screen/first_img_screen_home@2x.webp 2x" type="image/webp">
    <img src="/assets/images/first-screen/first_img_screen_home@1x.jpg" srcset="/assets/images/first-screen/first_img_screen_home@2x.jpg 2x" alt="First Screen Image">
  </picture>
  <div class="container">
    <div class="firstScreen__inner">
      <h1 class="firstScreen__ttl" i18n>¡EL HOSTING #1
        <br> EN LA WEB!
      </h1>
      <div class="firstScreen__sub_ttl" i18n>Atrévete a alcanzar un nivel superior
        <br> desde <span>{{ symbol }}</span>{{ hosting[0].plans[0].periods[0].price | currencyLocale}} anuales
      </div>
      <div class="wrapBtn">
        <button id="bh-home-contrata" (click)="navigateToSection('pad'); track($event,'scroll')" class="btn btnRed" mat-button i18n>Contrata aquí tu plan</button>
      </div>
    </div>
  </div>
</section>
<!-- END firstScreen -->

<!-- <app-blackfriday2021-banner></app-blackfriday2021-banner> -->

<!-- START -->
<section class="pad bg_blue_light pad_from_menu">
  <div class="container">
    <div class="sup_ttl2_black" i18n>Planes</div>
    <h2 class="ttl2_black mb" i18n>Menor precio, mayor rendimiento</h2>

    <div class="wrapCardPrice pad_buttom">

      <div class="cardPrice" *ngFor="let plan of hosting[0].plans">
        <div class="cardPrice__top">
          <div class="cardPrice_ttl"></div>
          <div class="cardPrice__ttl">{{ plan.name }}</div>
          <p class="cardPrice__sub_ttl">{{ plan.sub }}</p>
          <div *ngFor="let period of plan.periods; let i = index">
            <p class="price price_ttal">
              <span *ngIf="i > 0" class="price_pre">
                <span class="currency valuta">{{ symbol }}</span>{{ plan.periods[i-1].price | currencyLocale }}</span>
              <span class="price_actual">
                <span class="currency valuta">{{ symbol }}</span>{{ period.price | currencyLocale }}</span>
            </p>
            <p class="price_sub" i18n *ngIf="i == 0">Anual + IVA</p>
            <p class="price_sub" i18n *ngIf="i > 0">Anual + IVA, al contratar por {{ period.period }}</p>
          </div>
        </div>

        <button id="bh-home-contratar-{{plan.name | lowercase }}" class="btn btnRed" mat-button (click)="goToUrl( plan.url ); track($event)" i18n>contratar</button>

        <ul class="card_list" [preRender]="false" (deferLoad)="showImage1 = true; addVideoControlsForMobyle()">
          <li *ngFor="let feature of plan.features">
            <span [innerHTML]="feature.text"></span>
            <img src="{{ feature.icon_url }}" alt="{{ feature.text }}">
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- START tapeBlock -->
  <app-block-domain [locale]="locale"></app-block-domain>
  <!-- END tapeBlock -->
</section>
<!-- START menuPlans-->
<ng-container *ngIf="showImage1">
  <div [hidden]="removePlansMobyle()">
    <app-menu-plans *ngIf="isShowPlans"></app-menu-plans>
  </div>
</ng-container>

<!-- END menuPlans-->

<!-- START blockList -->
<app-block-list [lists]="lists"></app-block-list>
<!-- END blockList -->

<!-- START videoCompany -->
<app-video-company [text]="text" [showImage]="showImage1"></app-video-company>
<!-- END videoCompany -->

<!-- START -->
<section class="pad_top" [preRender]="false" (deferLoad)="showImage2 = true; addVideoControlsForMobyle()">
  <div class="container">
    <div class="sup_ttl2_black" i18n>Aún tienes más</div>
    <h2 class="ttl2_black mb" i18n>Llega a un nivel superior</h2>

    <div class="wrapCollumns">
      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img1-column-1.svg" width="131px" height="134px" alt="Más rapidez">
          </ng-container>
        </div>
        <h3 class="ttl3 upper" i18n>Más rapidez</h3>
        <h4 class="ttl4" i18n>Instalador Apps</h4>
        <p i18n>Con un solo clic podrás instalar más de 400 apps entre las cuales encontrarás Wordpress, Joomla y Opencart. Y otra decena como OpenSource para E-Commerce, Blogs, Wikis y Frameworks.</p>
        <h4 class="ttl4" i18n>Todo en SSD</h4>
        <p i18n>Tenemos tecnología de discos sólidos en todos nuestros servicios, tus datos y bases de datos tendrán un rendimiento más rápido y confiable. Di adiós a los discos HDD y a sus partes movibles. </p>
      </div>

      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img2-column.svg" width="98px" height="132px" alt="Más seguridad">
          </ng-container>
        </div>
        <h3 class="ttl3 upper" i18n>Más seguridad</h3>
        <h4 class="ttl4" i18n>Protección Malware y Ataques</h4>
        <p i18n>Nuestro Firewall de Protección Web (WAF), Network Intrusion Detection System (NIDS) trabajan de manera proactiva para bloquear malware y evitar que vuelvan a infectar tu sitio.</p>
        <h4 class="ttl4" i18n>Backups</h4>
        <p i18n>No dejes que un pequeño problema se convierta en el fin del mundo. Las opciones de copia de seguridad, restauración fácil y automática garantizan que cualquier error sea fácil de resolver.</p>
      </div>

      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img3-column.svg" width="267px" height="57px" alt="Más facilidad">
          </ng-container>
        </div>
        <h3 class="ttl3 upper" i18n>Más facilidad</h3>
        <h4 class="ttl4" i18n>Panel de control cPanel</h4>
        <p i18n>Administra, configura y gestiona tu servicio de web hosting de una forma más sencilla y rápida con el panel de control más utilizado en el mundo, cPanel.</p>
        <h4 class="ttl4" i18n>Migración gratuita</h4>
        <p i18n>Migramos de cualquier proveedor todos tu datos, de manera gratuita y sin complicaciones.</p>
      </div>
    </div>

  </div>
</section>
<!-- END -->

<!-- START wrap_videoCompany -->
<app-autoatencion-section [preRender]="false" (deferLoad)="showCharts = true" [loadImages]="showImage2">
</app-autoatencion-section>
<!-- END wrap_videoCompany -->

<!-- START section chartProductivity -->
<section class="pad center">
  <div class="container">
    <div class="sup_ttl2_black" i18n>Rendimiento</div>
    <h2 class="ttl2_black" i18n>El Web Hosting más rápido de Chile</h2>
    <p class="center" i18n>Tiempos más rápidos de carga y mayor rendimiento en todos nuestros planes web hosting.</p>
  </div>

  <ng-container *ngIf="showCharts">
    <div class="chartProductivity" *ngIf="isActiveChartsForDesktop">
      <div *ngIf="productivity.chart5" class="chartProductivity__item">
        <app-chart-productivity5 class="chartProductivity__canvas" [serviceName]="productivity.chart5.serviceName" [endValue]="productivity.chart5.endValue" [maxValue]="productivity.chart5.maxValue">
        </app-chart-productivity5>
        <div class="info">
          <div class="info_ttl" i18n>Rank #5</div>
          <p class="info_amount"><span i18n>Carga</span><ng-container i18n>3,6 segundos</ng-container></p>
          <p *ngIf="locale != 'es-CO' && locale != 'es-PE' && locale != 'es-CL' && locale != 'es-MX'" class="info_amount"><span i18n>Visitas</span><ng-container i18n>200 por segundo</ng-container></p>
        </div>
      </div>

      <div *ngIf="productivity.chart4" class="chartProductivity__item">
        <app-chart-productivity4 class="chartProductivity__canvas" [serviceName]="productivity.chart4.serviceName" [endValue]="productivity.chart4.endValue" [maxValue]="productivity.chart4.maxValue">
        </app-chart-productivity4>
        <div class="info">
          <div class="info_ttl" i18n>Rank #4</div>
          <p class="info_amount"><span i18n>Carga</span><ng-container i18n>4,73 segundos</ng-container></p>
          <p *ngIf="locale != 'es-CO' && locale != 'es-PE' && locale != 'es-CL' && locale != 'es-MX'" class="info_amount">
            <span i18n>Visitas</span><ng-container i18n>200 por segundo</ng-container>
          </p>
        </div>
      </div>

      <div *ngIf="productivity.chart3" class="chartProductivity__item">
        <app-chart-productivity3 class="chartProductivity__canvas" [serviceName]="productivity.chart3.serviceName" [endValue]="productivity.chart3.endValue" [maxValue]="productivity.chart3.maxValue">
        </app-chart-productivity3>
        <div class="info">
          <div class="info_ttl" i18n>Rank #3</div>
          <p class="info_amount"><span i18n>Carga</span><ng-container i18n>3,6 segundos</ng-container></p>
          <p *ngIf="locale != 'es-CO' && locale != 'es-PE' && locale != 'es-CL' && locale != 'es-MX'" class="info_amount"><span i18n>Visitas</span><ng-container i18n>200 por segundo</ng-container></p>
        </div>
      </div>

      <div *ngIf="productivity.chart2" class="chartProductivity__item">
        <app-chart-productivity2 class="chartProductivity__canvas" [serviceName]="productivity.chart2.serviceName" [endValue]="productivity.chart2.endValue" [maxValue]="productivity.chart2.maxValue">
        </app-chart-productivity2>
        <div class="info">
          <div class="info_ttl" i18n>Rank #2</div>
          <p class="info_amount"><span i18n>Carga</span><ng-container i18n>3,0 segundos</ng-container></p>
          <p *ngIf="locale != 'es-CO' && locale != 'es-PE' && locale != 'es-CL' && locale != 'es-MX'" class="info_amount"><span i18n>Visitas</span><ng-container i18n>200 por segundo</ng-container></p>
        </div>
      </div>

      <div class="chartProductivity__item">
        <app-chart-productivity1 class="chartProductivity__canvas" [endValue]="productivity.chart1.endValue">
        </app-chart-productivity1>
        <div class="info">
          <div class="info_ttl" i18n>Rank #1</div>
          <p class="info_amount"><span i18n>Carga</span><ng-container i18n>1,6 segundos</ng-container></p>
          <p *ngIf="locale != 'es-CO' && locale != 'es-PE' && locale != 'es-CL' && locale != 'es-MX'" class="info_amount"><span i18n>Visitas</span><ng-container i18n>200 por segundo</ng-container></p>
        </div>
      </div>
    </div>

    <div class="progressProductivity" *ngIf="isActiveProgressForMobyle">
      <div class="progressProductivity__item">
        <div class="progressProductivity__label">
          <span class="ttl">Bluehosting</span>
          <span class="percent"><span>2x</span>Velocidad</span>
        </div>
        <div class="progressProductivity__item-line">
          <span class="progress"></span>
        </div>
      </div>

      <div *ngIf="productivity.chart2" class="progressProductivity__item">
        <div class="progressProductivity__label">
          <span class="ttl">{{productivity.chart2.serviceName}}</span>
          <span class="percent"><span>{{productivity.chart2.maxValue ?
              productivity.chart2.endValue - productivity.chart2.maxValue :
              productivity.chart2.endValue - 100}}</span>% Velocidad</span>
        </div>
        <div class="progressProductivity__item-line">
          <span class="progress"></span>
        </div>
      </div>

      <div *ngIf="productivity.chart3" class="progressProductivity__item">
        <div class="progressProductivity__label">
          <span class="ttl">{{productivity.chart3.serviceName}}</span>
          <span class="percent"><span>{{productivity.chart3.maxValue ?
              productivity.chart3.endValue - productivity.chart3.maxValue :
              productivity.chart3.endValue - 100}}</span>% Velocidad</span>
        </div>
        <div class="progressProductivity__item-line">
          <span class="progress"></span>
        </div>
      </div>

      <div *ngIf="productivity.chart4" class="progressProductivity__item">
        <div class="progressProductivity__label">
          <span class="ttl">{{productivity.chart4.serviceName}}</span>
          <span class="percent"><span>{{productivity.chart4.maxValue ?
              productivity.chart4.endValue - productivity.chart4.maxValue :
              productivity.chart4.endValue - 100}}</span>% Velocidad</span>
        </div>
        <div class="progressProductivity__item-line">
          <span class="progress"></span>
        </div>
      </div>

      <div *ngIf="productivity.chart5" class="progressProductivity__item">
        <div class="progressProductivity__label">
          <span class="ttl">{{productivity.chart5.serviceName}}</span>
          <span class="percent"><span>{{productivity.chart5.maxValue ?
              productivity.chart5.endValue - productivity.chart5.maxValue :
              productivity.chart5.endValue - 100}}</span>% Velocidad</span>
        </div>
        <div class="progressProductivity__item-line">
          <span class="progress"></span>
        </div>
      </div>
    </div>
  </ng-container>

  <button id="bh-home-rendimiento-test" (click)="goToUrl( URL.BLUEHOSTING_SPEEDTEST ); track($event)" class="btn btnRedTransp" mat-button i18n>VER TEST AQUÍ</button>
</section>
<!-- END section chartProductivity -->

<!-- START blockClients -->
<ng-container *ngIf="showSectionSlider">
  <app-block-clients></app-block-clients>
</ng-container>
<!-- END blockClients -->

<!-- SRTART  -->
<section class="pad_top" [preRender]="false" (deferLoad)="showImage3 = true">
  <div class="container">
    <h2 class="ttl2_black" i18n>Elige tu plan</h2>
    <h3 class="ttl3 center" i18n>Selecciona el plan óptimo para tus necesidades comerciales</h3>
  </div>
  <app-tab-plans [hosting]="hosting"></app-tab-plans>
</section>
<!-- END  -->

<!-- START blockSyteType -->
<section class="wrap_blockSyteType pad bg_blue3">
  <div class="container">
    <div class="sup_ttl2_white" i18n>Elige un plan acorde a tus necesidades</div>
    <h2 class="ttl2" i18n>¿Qué quieres hacer?</h2>
    <div class="blockSyteType">
      <div class="blockSyteType__item" (click)="openDialogSyteHtml()">
        <div class="blockSyteType__img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-html.svg" alt="sitio html">
          </ng-container>
        </div>
        <div class="blockSyteType__ttl" i18n>SITIO HTML</div>
      </div>

      <div class="blockSyteType__item" (click)="openDialogSyteBlog()">
        <div class="blockSyteType__img">
          <ng-container>
            <img src="/assets/images/img-blog.svg" width="188px" height="147px" alt="blog">
          </ng-container>
        </div>
        <div class="blockSyteType__ttl" i18n>BLOG</div>
      </div>

      <div class="blockSyteType__item" (click)="openDialogSyteECommerce()">
        <div class="blockSyteType__img">
          <ng-container>
            <img src="/assets/images/img-e-commerce.svg" width="166px" height="149px" alt="e-commerce">
          </ng-container>
        </div>
        <div class="blockSyteType__ttl" i18n>E-COMMERCE</div>
      </div>

      <div class="blockSyteType__item" (click)="openDialogSyteApplication()">
        <div class="blockSyteType__img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-settings.svg" alt="aplicación">
          </ng-container>
        </div>
        <div class="blockSyteType__ttl" i18n>APLICACIÓN</div>
      </div>
    </div>
  </div>
</section>
<!-- END blockSyteType -->

<!-- START  -->
<section class="blockSoftware pad" [preRender]="false" (deferLoad)="showImage4 = true">
  <div class="container">
    <div class="blockSoftware__wrapper">

      <div class="blockSoftware__info">
        <div class="sup_ttl2_black" i18n>Simple y flexible</div>
        <h2 class="ttl2_black" i18n>Atributos del servidor</h2>

        <div class="blockSoftware__img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-software.svg" alt="software">
          </ng-container>
        </div>

        <p i18n>BlueHosting mantiene una amplia batería de softwares, para que puedas concentrarte en la construcción de tu sitio de manera sencilla y óptima. Su cuadrícula pone a tu servicio:</p>
        <ul class="list_check">
          <li i18n>PHP 5.4 - 5.5 - 5.6 - 7.0 - 7.1 - 7.4 - 8.0 - 8.1 y 8.2 </li>
          <li i18n>Apache 2.4</li>
          <li i18n>MySQL 10.6</li>
          <li i18n>MariaDB 10.6</li>
          <li i18n>cPanel V110</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!-- END  -->

<!-- START blockInfo -->
<app-block-info [info]="info"></app-block-info>
<!-- END blockInfo -->

<!-- START -->
<section class="pad bg_grey remov_pad_bottom" [preRender]="false" (deferLoad)="showImage5 = true; showFooter = true">
  <div class="container">
    <div class="sup_ttl2_black" i18n>Características de nuestro sistema</div>
    <h2 class="ttl2_black" i18n>Tu experiencia digital comienza aquí</h2>
    <p class="center" i18n>BlueHosting no solo protege, sino que acelera miles de sitios web y otras aplicaciones de internet</p>
    <!-- START wrapCard -->
    <div class="wrapCard">
      <div class="wrapCard__item bg_blue1">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage4">
            <img src="/assets/images/img-card-item1.svg" alt="redimiento">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>RENDIMIENTO</h3>
          <p i18n>BlueHosting mejora el rendimiento de tu sitio con diversas tipologías de optimización.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue2">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage4">
            <img src="/assets/images/img-card-item2.svg" alt="seguridad">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>SEGURIDAD</h3>
          <p i18n>Protege tu sitio y a quienes lo visitan de todo tipo de engaños on-line. BlueHosting utiliza WAF y SSL entre otras herramientas para defender tu sitio.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue3">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage4">
            <img src="/assets/images/img-card-item3.svg" alt="confianza">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>CONFIANZA</h3>
          <p i18n>Administramos un servicio DNS con más de un 30% de cuota de mercado.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue4">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage4">
            <img src="/assets/images/img-card-item4.svg" alt="visión">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>PREVENCIÓN</h3>
          <p i18n>Nuestra red es capaz de identificar visitantes y comportamientos de bots que tecnologías de análisis convencionales no logran reconocer.</p>
        </div>
      </div>
    </div>
    <!-- END wrapCard -->
  </div>
</section>
<!-- END  -->

<!-- START block_txt_column -->
<section class="block_txt_column pad">
  <div class="container">
    <h2 class="ttl2_black" i18n>Preguntas frecuentes</h2>
    <div class="txt_column">

      <div class="txt_column__column">
        <h3 class="ttl3" i18n>¿Cómo me registro en BlueHosting?</h3>
        <p i18n>Solo debes hacer clic en Iniciar Sesión, allí encontrarás el link de Registro, en donde deberás completar un formulario con tus datos personales y un correo válido que no haya sido asociado a tu plan de hosting.</p>

        <h3 class="ttl3" i18n>Perdí mi clave de acceso al área de cliente, ¿Cómo la recupero?</h3>
        <p i18n>Solicitando un cambio de clave a tu cuenta de correo asociada. En minutos recibirás un email en donde podrás actualizarla. Si no recuerdas tu mail, contacta telefónicamente a nuestra área de soporte para consultarlo.</p>

        <h3 class="ttl3" i18n>¿Cómo elegir el plan que más me conviene? </h3>
        <p i18n>Todo dependerá del tipo de proyecto y del momento en el que te encuentres. Si recién comienzas con tu idea, los planes emprendedores son la opción más segura para una inversión inicial. Pero si tienes una empresa o un emprendimiento que está en proceso de consolidarse, las mejores
          opciones serán los planes Negocios o Empresas, pensados para destinar mayor cantidad de recursos.</p>
      </div>

      <div class="txt_column__column">
        <h3 class="ttl3" i18n>¿Cuáles son los métodos de pago?</h3>
        <p i18n>Contamos con un amplio sistema de pago disponible. Aceptamos tarjetas de crédito o débito, transferencias bancarias o depósito, servipag on-line o caja, Kiphu y Paypal o PayU.</p>

        <h3 class="ttl3" i18n>Ya tengo un sitio web en otra empresa, ¿Cómo me cambio a BlueHosting?</h3>
        <p i18n>¡Migramos todo por ti GRATIS! Solo necesitamos los accesos de tu cuenta actual o una copia de seguridad y en 72 horas podrás disfrutar de toda la potencia de nuestros planes sin complicaciones, ni cobros extras.</p>
      </div>

    </div>
  </div>
</section>
<!-- END block_txt_column -->


<ng-container *ngIf="showFooter">
  <app-block-logos></app-block-logos>

  <app-footer></app-footer>
</ng-container>