import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { LocaleService } from '../services/locale.service';
import { URL } from '../../environments/environment';
import { MetaService } from '@ngx-meta/core';
import { ScrollToClass } from '../../../../common/scrollTo.class';
import { InfoSimple } from '../shared/interfaces/block';

@Component({
    selector: 'app-reseller',
    templateUrl: './reseller.component.html'
})
export class ResellerComponent extends ScrollToClass implements OnInit {
    // lazy loading images
    showImage1: boolean = false;
    showImage2: boolean = false;

    URL = URL;

    resellers;
    links;
    symbol = '';
    locale;

    public info:InfoSimple = {
        'title1': '¿Quieres comenzar tu negocio ahora y',
        'title2': 'llevar a tus clientes a otro nivel?',
        'section': 'pad_top',
        'button': 'COMIENZA AQUÍ'
    }

    constructor(
        private localeService: LocaleService,
        private readonly _meta: MetaService,
        private _el: ElementRef,
    ) {
        super(_el);
        this.symbol = this.localeService.symbol;
        this.links = this.localeService.getContent().links;
        this.resellers = this.localeService.getContent().resellers;
        this.locale = this.localeService.locale;
    }

    ngOnInit() {
        //Set Meta Tags
        if(this.locale == "es-CO")
            this._setMetaCO();
        else if (this.locale == "es-MX")
            this._setMetaMX();
        else if (this.locale == "es-PE")
            this._setMetaPE();
        else if (this.locale == "es-AR")
            this._setMetaAR();
        else
            this._setMetaCL();
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    toggleVideoCompany(el) {
        const element = this._findAncestorFromEl(el.target, 'videoCompany__video');
        const playEl = element.classList.contains('play');
        const video = element.getElementsByClassName('video')[0] as HTMLVideoElement;

        if (window.innerWidth > 670) {
            if (playEl) {
                element.classList.remove('play');
                video.pause();
            } else {
                element.classList.add('play');
                video.play();
            }
        }
    }

    navigateToSection(className) {
        this.scrollToSection(className);
    }

    private _findAncestorFromEl(el, cls) {
        if (el.classList.contains(cls)) {
            return el;
        }
        return this._findAncestor(el, cls);
    }

    private _findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls)) {}
        return el;
    }

    private _setMetaCO() {
        this._meta.setTitle('HOSTING RESELLER | Hosting Reseller Dispara tus ganancias HOY');
        this._meta.setTag('description', 'Hosting Reseller comience su propia empresa con nuestros planes Revendedor Hosting, Hosting Multidominios con cPanel WHM.');
        this._meta.setTag('keywords', 'hosting reseller, hosting reseller colombia, hosting reseller ilimitado, hosting reseller ilimitado barato, hosting reseller economico, hosting reseller barato, hosting para revendedores, reventa de hosting, hosting buenos en colombia');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/reseller.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Colombia');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
    }

    private _setMetaCL() {
        this._meta.setTitle('HOSTING RESELLER | Hosting Reseller Dispara tus ganancias HOY');
        this._meta.setTag('description', 'Hosting Reseller comience su propia empresa con nuestros planes Revendedor Hosting, Hosting Multidominios con cPanel WHM.');
        this._meta.setTag('keywords', 'hosting reseller, hosting reseller chile, hosting reseller ilimitado, hosting reseller ilimitado barato, hosting reseller economico, hosting reseller barato, hosting para revendedores, reventa de hosting, hosting buenos en chile');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/reseller.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Chile');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaPE() {
        this._meta.setTitle('HOSTING RESELLER | Hosting Reseller Dispara tus ganancias HOY ');
        this._meta.setTag('description', 'Hosting Reseller; comience su propia empresa con nuestros planes Revendedor Hosting, Hosting Multidominios con cPanel WHM');
        this._meta.setTag('keywords', 'hosting reseller, hosting reseller perú, hosting reseller ilimitado, hosting reseller ilimitado barato, hosting reseller economico, hosting reseller barato, hosting para revendedores, reventa de hosting, hosting buenos en perú');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/reseller.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Perú');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaMX() {
        this._meta.setTitle('HOSTING RESELLER | Hosting Reseller Dispara tus ganancias HOY ');
        this._meta.setTag('description', 'Hosting Reseller; comience su propia empresa con nuestros planes Revendedor Hosting, Hosting Multidominios con cPanel WHM');
        this._meta.setTag('keywords', 'hosting reseller, hosting reseller méxico, hosting reseller ilimitado, hosting reseller ilimitado barato, hosting reseller economico, hosting reseller barato, hosting para revendedores, reventa de hosting, hosting buenos en méxico');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/reseller.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en México');
        this._meta.setTag('og:locale', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaAR() {
        this._meta.setTitle('HOSTING RESELLER | Hosting Reseller Dispará tus ganancias HOY ');
        this._meta.setTag('description', 'Hosting Reseller; comenzá tu propia empresa con nuestros planes Revendedor Hosting, Hosting Multidominios con cPanel WHM');
        this._meta.setTag('keywords', 'hosting reseller, hosting reseller perú, hosting reseller ilimitado, hosting reseller ilimitado barato, hosting reseller economico, hosting reseller barato, hosting para revendedores, reventa de hosting, hosting buenos en argentina');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/OG_reseller_ARG.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Argentina');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
}
