import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, retryWhen, delay, take } from 'rxjs/operators';
import { Plan, PlanStructure } from './Plan';

@Injectable()
export class PlansService {

	apiURL: string = '/assets/dummy-plans.json';
	planCategories: PlanStructure;

	constructor(
		private http: HttpClient,
	) {
	}

	ngOnInit() {
		
	}

	prices(): Observable<PlanStructure> {
		this.planCategories = new PlanStructure();

		return this.http.get<Array<Array<{}>>>(this.apiURL)
			.pipe(
				map(response => {
					response.forEach(category => {
						let plans: Plan[] = [];

						category['plans'].forEach(plan => {
							let auxPlan = new Plan(
								plan['name'],
								plan['description'],
								plan['annually'],
								plan['biennially'],
								plan['triennially']
							);
							plans.push(auxPlan);
						})

						if(category['name'] === 'persona')
						{
							this.planCategories.persona = plans;
						}
						else if(category['name'] === 'pyme')
						{
							this.planCategories.pyme = plans;
						}
						else if(category['name'] === 'empresa')
						{
							this.planCategories.empresa = plans;
						}
					});
					return this.planCategories;
				}),
				retryWhen(errors => {
					return errors.pipe(delay(1000*2), take(5));
				})
			);
	}
}

