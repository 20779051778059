import { AfterContentInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-typeform-modal',
    templateUrl: './typeform-modal.component.html',
    styleUrls: ['./typeform-modal.component.scss'],
})

export class TypeformModalComponent implements AfterContentInit {
    @ViewChild('iframe') iframe: ElementRef;

    iframeSrc: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { typeformUrl: string },
    ) { }

    ngAfterContentInit(): void {
        this.iframeSrc = this.data.typeformUrl;
        setTimeout(() => {
            this.iframe.nativeElement.src = this.iframeSrc;
        }, 0);
    }
}
